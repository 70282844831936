import React from "react";
import { Grid, Typography, Box, Tabs, Tab } from "@mui/material";

const TabsLoginUser = ({ value, handleChangeTab, loginLabel }) => {
  return (
    <>
      <Box sx={{ marginBottom: "1rem" }}>
        <Tabs
          value={value}
          onChange={handleChangeTab}
          variant="fullWidth"
          textColor="white"
          TabIndicatorProps={{
            display: "none",
            hidden: true,
          }}
          sx={{
            "& button.Mui-selected": {
              backgroundColor: "#D14F1E",
              color: "white",
            },
          }}
        >
          {loginLabel?.map((item) => (
            <Tab
              label={item?.label}
              value={item?.label}
              sx={{ textTransform: "none", color: "#D14F1E", height: "36px" }}
            /> // tab needs value
          ))}
        </Tabs>
      </Box>
    </>
  );
};

export default TabsLoginUser;
