import React from "react";
import Filter from "../../components/filterEx";
import { Typography, Grid, Box } from "@mui/material";
import MainButton from "../../components/Elements/MainButton";
import CardExhibition from "../../components/Exhibitions/Card";

import Event1 from "../../assets/rename1.png";
import Event2 from "../../assets/rename2.png";
import Event3 from "../../assets/rename3.png";

const index = () => {
  return (
    <>
      <Grid container wrap="noWrap" padding="64px 200px 0 200px" gap={2}>
        <Filter />
        <Box>
          <Typography fontWeight={"500"} fontSize={"24px"}>
            Date: 12 Februari 2023 - 28 Februari 2023
          </Typography>
          {/* component */}
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={2}
          >
            <Typography fontWeight={"500"} fontSize={"20px"}>
              Exhibitions(24)
            </Typography>
            <MainButton disableElevation>View all {">"}</MainButton>
          </Box>

          <Box display={"flex"} flexDirection={"row"} gap={2}>
            <CardExhibition photo={Event1} />
            <CardExhibition photo={Event2} />
            <CardExhibition photo={Event3} />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={2}
          >
            <Typography fontWeight={"500"} fontSize={"20px"}>
              Tours(24)
            </Typography>
            <MainButton disableElevation>View all {">"}</MainButton>
          </Box>

          <Box display={"flex"} flexDirection={"row"} gap={2}>
            <CardExhibition photo={Event1} />
            <CardExhibition photo={Event2} />
            <CardExhibition photo={Event3} />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={2}
          >
            <Typography fontWeight={"500"} fontSize={"20px"}>
              Social(2)
            </Typography>
            <MainButton disableElevation>View all {">"}</MainButton>
          </Box>

          <Box display={"flex"} flexDirection={"row"} gap={2}>
            <CardExhibition photo={Event1} />
            <CardExhibition photo={Event2} />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={2}
          >
            <Typography fontWeight={"500"} fontSize={"20px"}>
              Perfomances(1)
            </Typography>
            <MainButton disableElevation>View all {">"}</MainButton>
          </Box>

          <Box display={"flex"} flexDirection={"row"} gap={2}>
            <CardExhibition photo={Event1} />
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default index;
