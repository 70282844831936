import React, { useState } from "react";
import { Grid, Button, TextField, Typography } from "@mui/material";
import Headline from "../../components/headline";
import Phone from "../../assets/phone-solid.svg";

import { Icon } from "@iconify/react";
import MainButton from "../../components/Elements/MainButton";
import List from "../../components/AdminAdmission/List";
import RequiredLabel from "../../components/Elements/RequiredLabel";
import AdminInput from "../../components/Elements/AdminInput";
import AdminInputX from "../../components/Elements/AdminInputX";

const PurchaseOrder = ({ title, setSection }) => {
  const [admission, setAdmission] = useState([0]);
  const [supportFile, setSupportFile] = useState([0]);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const titleHeadline = (
    <Grid container alignItems={"center"} gap={2}>
      <Icon icon="tabler:receipt" width="40" height="40" />
      <Typography fontSize={"32px"} fontWeight={"700"}>
        Info
      </Typography>
    </Grid>
  );

  const editHeadline = (
    <Grid container alignItems={"center"} gap={1}>
      <Icon
        icon="material-symbols:edit-outline"
        color="white"
        width="20"
        height="20"
      />
      <Typography>Edit</Typography>
    </Grid>
  );
  return (
    <>
      <Grid container backgroundColor="white" padding="1.5rem" gap={2}>
        <Headline title={titleHeadline} content={editHeadline} />
        <Grid item xs={4}>
          <Grid item marginTop={"20px"}>
            <RequiredLabel label={"Input Alamat"} />
          </Grid>
          <Grid item marginTop={"20px"}>
            <AdminInput
              required
              name="phone"
              size="small"
              rows={1}
              multiline
              fullWidth
              placeholder="Info alamat"
            />
          </Grid>
          <Grid item marginTop={"20px"}>
            <RequiredLabel label={"Link Contact Us"} />
          </Grid>
          <Grid item marginTop={"20px"}>
            <AdminInput
              required
              name="phone"
              size="small"
              rows={1}
              multiline
              fullWidth
              placeholder="Info Contact"
            />
          </Grid>
          <Grid item margin={"20px 0"}>
            <Grid container alignItems={"center"} gap={2}>
              <Icon icon="tabler:receipt" width="40" height="40" />
              <Typography fontSize={"32px"} fontWeight={"700"}>
                Admission
              </Typography>
            </Grid>
          </Grid>
          <List attachment={admission} />

          <Grid item marginTop={"20px"}>
            <Grid container alignItems={"center"} gap={1}>
              <Icon icon="tabler:receipt" width="40" height="40" />
              <Typography fontSize={"32px"} fontWeight={"700"}>
                Berkas Pendukung
              </Typography>
            </Grid>
            <List supportFile={supportFile} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PurchaseOrder;
