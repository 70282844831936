import { FormControl, MenuItem, Select, FormLabel } from "@mui/material";
import React from "react";

function MainSelect({ value, onChange, label, options, sx }) {
  return (
    <FormControl fullWidth>
      {label && (
        <FormLabel
          sx={{
            marginBottom: "0.5rem",
            color: "neutral.800",
            fontSize: "0.875rem",
            "&:focus": {
              color: "neutral.800",
            },
          }}
        >
          {label}
        </FormLabel>
      )}
      <Select
        value={value}
        onChange={onChange}
        defaultValue={options && options[0].value}
        displayEmpty
        style={{
          height: "3rem",
          fontSize: "0.875rem",
          borderRadius: 0,
        }}
        sx={sx}
      >
        {options?.map((item) => (
          <MenuItem value={item.value}>{item.title}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MainSelect;
