import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import MainButton from "../../components/Elements/MainButton";
import { Icon } from "@iconify/react";

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <>
      <Grid item>
        <MainButton
          sx={{ margin: "50px" }}
          variant={"outlined"}
          onClick={() => navigate("/login")}
        >
          <Icon
            icon="ph:arrow-up-bold"
            width="16"
            height="16"
            rotate={3}
            style={{ marginRight: "0.5rem" }}
          />
          Kembali
        </MainButton>
      </Grid>
    </>
  );
};

export default BackButton;
