import { Avatar, Box, Divider, Typography } from "@mui/material";
import React from "react";

// asset
import Anim from "../../assets/event3.jpg";

// component
import FieldData from "../Elements/FieldData";

function DetailTicket({ total }) {
  return (
    <>
      <Box
        padding="1rem"
        boxSizing="border-box"
        width="100%"
        border="1px solid"
        borderColor="neutral.400"
        marginTop="1rem"
      >
        <Typography fontSize="1.5rem" fontWeight="700">
          Detail Tiket
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "start",
            marginTop: "1rem",
          }}
        >
          <Avatar
            alt="profile-picture"
            src={Anim}
            sx={{
              width: "4.5rem",
              height: "4.5rem",
              fontSize: "9.375rem",
              marginBottom: "1rem",
            }}
            variant="square"
          />
          <Typography sx={{ fontSize: "1rem", fontWeight: 700 }}>
            Animakini X Ciffest 2023
          </Typography>
        </Box>
        <Typography variant="body2" fontWeight="600">
          Order ID : 12651257126
        </Typography>
        <Divider
          sx={{
            width: "100%",
            bgcolor: "neutral.400",
            marginY: "1rem",
          }}
          orientation="horizontal"
        />
        <Typography sx={{ fontSize: "1.25rem", fontWeight: 700 }}>
          VIP
        </Typography>
        <Box sx={{ display: "flex", marginTop: "0.5rem" }}>
          <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
            1 Tiket
          </Typography>
          &nbsp;
          <Typography
            sx={{
              fontSize: "0.875rem",
              fontWeight: 700,
              color: "neutral.600",
            }}
          >
            - 1 Pax
          </Typography>
        </Box>
        <Divider
          sx={{
            width: "100%",
            bgcolor: "neutral.400",
            marginY: "1rem",
          }}
          orientation="horizontal"
        />
        <FieldData title="Tanggal Dipilih" content="Senin, 20 Februari 2023" />
        <Divider
          sx={{
            width: "100%",
            bgcolor: "neutral.400",
            marginY: "1rem",
          }}
          orientation="horizontal"
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body2">Total Pembayaran</Typography>
          <Typography fontSize="1.25rem" fontWeight="700" color="primary.600">
            IDR {total}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default DetailTicket;
