import React from "react";
import { Grid } from "@mui/material";
import Upload from "./Upload";
import MainButton from "../Elements/MainButton";

const List = ({ attachment }) => {
  const results = attachment?.map((_data, index) => <Upload />);
  return (
    <>
      {results}
      <Grid item marginLeft={"463px"}>
        <MainButton variant={"contained"} sx={{ width: "173px" }}>
          + Tambah Gambar
        </MainButton>
      </Grid>
    </>
  );
};

export default List;
