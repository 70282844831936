import React, { useState } from "react";

import { Grid, Typography, InputAdornment, Box } from "@mui/material";
import Logo from "../../assets/logotim.png";
import { Icon } from "@iconify/react";
import AdminInput from "../../components/Elements/AdminInput";
import MainButton from "../../components/Elements/MainButton";

import { height } from "@mui/system";
import { useNavigate } from "react-router-dom";

const About = ({ setSection, setLoginTitle }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleForget = () => {
    setSection("");
    setLoginTitle("Welcome Back Login to Access with your existing account");
  };
  return (
    <>
      <Grid container direction={"column"} padding={"0 3rem"}>
        <Grid item align={"center"} marginBottom={"3rem"}>
          <img src={Logo} alt="" />
        </Grid>

        <Typography
          sx={{
            fontWeight: "700",
            width: "232px",
            fontSize: "48px",
            marginBottom: "3rem",
          }}
        >
          Forgot Password
        </Typography>

        <AdminInput
          label="Email"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon icon="mdi:user" color="#828282" width="18" height="18" />
              </InputAdornment>
            ),
          }}
          placeholder={"Masukkan Email"}
        />

        <MainButton sx={{ marginTop: "1rem" }} variant={"contained"}>
          Send Authentication
        </MainButton>
        <MainButton
          sx={{ marginTop: "1rem" }}
          variant={"outlined"}
          onClick={handleForget}
        >
          Kembali
        </MainButton>
      </Grid>
    </>
  );
};

export default About;
