import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Breadcrumbs,
  ButtonBase,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

// asset
import Mandiri from "../assets/Mandiri.png";

// component
import { Icon } from "@iconify/react";
import DetailTicket from "../components/Checkout/DetailTicket";
import MainButton from "../components/Elements/MainButton";
import MainNavbar from "../components/MainNavbar";
import UserNotice from "../components/UserNotice";

const DetailBill = () => {
  const navigate = useNavigate();

  const breadcrumbs = [
    <Typography
      key="1"
      color="text.primary"
      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
    >
      1. Booking
    </Typography>,
    <Typography
      key="2"
      color="text.primary"
      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
    >
      2. Pesan
    </Typography>,
    <Typography
      key="3"
      color="text.primary"
      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
    >
      3. Bayar
    </Typography>,
    <Typography
      key="4"
      color="inherit"
      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
    >
      4. Selesai
    </Typography>,
  ];

  const handCopyAccountNumber = () => {
    let textCopy = document.getElementById("account-number")?.textContent;
    navigator.clipboard.writeText(textCopy);
    alert("tersalin");
  };

  const handCopyTotalBill = () => {
    let textCopy = document.getElementById("total-bill")?.textContent;
    navigator.clipboard.writeText(textCopy);
    alert("tersalin");
  };

  return (
    <Box bgcolor="background.paper" minHeight="100vh" paddingBottom="4rem">
      <MainNavbar />
      <UserNotice />
      <Grid container>
        <Grid
          item
          xs={11}
          sm={10}
          md={9}
          sx={{
            margin: "auto",
            marginTop: "4rem",
          }}
        >
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "5rem",
            }}
          >
            {breadcrumbs}
          </Breadcrumbs>
          <Typography fontSize="2rem" fontWeight="700">
            Bank Mandiri
          </Typography>
          <Divider
            sx={{
              width: "100%",
              bgcolor: "neutral.400",
              marginTop: "1rem",
              marginBottom: "2rem",
            }}
            orientation="horizontal"
          />
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <Box
                padding="1rem"
                boxSizing="border-box"
                width="100%"
                border="1px solid"
                borderColor="neutral.400"
                marginTop="1rem"
              >
                <Typography sx={{ fontSize: "1.5rem", fontWeight: 700 }}>
                  Silahkan Transfer ke
                </Typography>
                <Box
                  padding="1rem"
                  boxSizing="border-box"
                  width="100%"
                  border="1px solid"
                  borderColor="neutral.400"
                  marginY="1rem"
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                    }}
                  >
                    <Box>
                      <Typography variant="body2" sx={{ color: "neutral.600" }}>
                        Transfer
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "1.25rem",
                          fontWeight: 700,
                          marginTop: "0.5rem",
                        }}
                      >
                        Mandiri
                      </Typography>
                    </Box>
                    <img src={Mandiri} alt="" />
                  </Box>
                  <Divider
                    sx={{
                      width: "100%",
                      bgcolor: "neutral.400",
                      marginTop: "1rem",
                      marginBottom: "2rem",
                    }}
                    orientation="horizontal"
                  />
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                    }}
                  >
                    <Box>
                      <Typography variant="body2" sx={{ color: "neutral.600" }}>
                        Nomor Rekening
                      </Typography>
                      <Typography
                        id="account-number"
                        sx={{
                          fontSize: "1.25rem",
                          fontWeight: 700,
                          marginTop: "0.5rem",
                        }}
                      >
                        11 7000 808 9989
                      </Typography>
                    </Box>
                    <ButtonBase
                      sx={{
                        fontSize: "1.25rem",
                        fontWeight: 700,
                        color: "primary.600",
                      }}
                      onClick={handCopyAccountNumber}
                    >
                      SALIN
                    </ButtonBase>
                  </Box>
                  <Divider
                    sx={{
                      width: "100%",
                      bgcolor: "neutral.400",
                      marginTop: "1rem",
                      marginBottom: "2rem",
                    }}
                    orientation="horizontal"
                  />
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                    }}
                  >
                    <Box>
                      <Typography variant="body2" sx={{ color: "neutral.600" }}>
                        Pemilik Rekening
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "1.25rem",
                          fontWeight: 700,
                          marginTop: "0.5rem",
                        }}
                      >
                        PT. Taman Ismail Marzuki
                      </Typography>
                    </Box>
                  </Box>
                  <Divider
                    sx={{
                      width: "100%",
                      bgcolor: "neutral.400",
                      marginTop: "1rem",
                      marginBottom: "2rem",
                    }}
                    orientation="horizontal"
                  />
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                      marginBottom: "1rem",
                    }}
                  >
                    <Box>
                      <Typography variant="body2" sx={{ color: "neutral.600" }}>
                        Total Pembayaran
                      </Typography>
                      <Box sx={{ position: "relative" }}>
                        <Typography
                          id="total-bill"
                          sx={{
                            fontSize: "1.25rem",
                            fontWeight: 700,
                            marginTop: "0.5rem",
                            color: "primary.600",
                            zIndex: 10,
                            position: "relative",
                          }}
                        >
                          IDR 499.563
                        </Typography>
                        <Box
                          sx={{
                            bgcolor: "tertiary.200",
                            height: "100%",
                            width: "2.375rem",
                            position: "absolute",
                            top: 0,
                            right: -2,
                            zIndex: 0,
                          }}
                        ></Box>
                      </Box>
                    </Box>
                    <ButtonBase
                      sx={{
                        fontSize: "1.25rem",
                        fontWeight: 700,
                        color: "primary.600",
                      }}
                      onClick={handCopyTotalBill}
                    >
                      SALIN
                    </ButtonBase>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "1rem",
                      bgcolor: "neutral.200",
                    }}
                  >
                    <Typography variant="body2" fontWeight="700">
                      Informasi Penting
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingX: "1rem",
                      paddingY: "0.5rem",
                      bgcolor: "neutral.200",
                      gap: "0.625rem",
                      bgcolor: "primary.100",
                      minHeight: "3.5rem",
                    }}
                  >
                    <Icon
                      icon="material-symbols:info-rounded"
                      height="1.25rem"
                      width="1.25rem"
                      color="#D14F1E"
                    />
                    <Typography variant="caption">
                      Transfer{" "}
                      <strong>
                        sesuai dengan jumlah angka yang tertera pada total
                        pembayaran
                      </strong>{" "}
                      atau pesanan anda tidak akan dikonfirmasi.
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: "end",
                    gap: { xs: "0.5rem", sm: "1.5rem" },
                  }}
                >
                  <MainButton
                    variant="outlined"
                    sx={{ marginTop: "1rem" }}
                    onClick={() => navigate("/detail-pembayaran")}
                  >
                    Ganti Metode Pembayaran
                  </MainButton>
                  <MainButton
                    variant="contained"
                    sx={{ marginTop: "1rem" }}
                    onClick={() => navigate("/mybooking")}
                  >
                    Lihat Daftar Pesanan
                  </MainButton>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <DetailTicket total="499.563" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailBill;
