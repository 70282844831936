import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Button } from "@mui/material";
import dayjs from "dayjs";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";

// components
import CustomModal from "../components/CustomModal/FilterDate";

const FilterDate = ({
  isShowing,
  toggle,
  state,
  setState,
  setSelectedDates,
  lossing,
  menuTab,
  posts,
  selectedDates,
  setSearchResults,
  resetPage,
}) => {
  const [value, setValue] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // useEffect(() => {
  //   setSelectedDates({
  //     // startDate: dayjs(value[0].startDate).format('YYYY-MM-DD'),
  //     // endDate: dayjs(value[0].endDate).format('YYYY-MM-DD')
  //   });
  // }, [setSelectedDates]);

  // const onBtnSubmitNonLossing = () => {
  //   toggle();
  //   resetPage();
  //   setSelectedDates({
  //     startDate: dayjs(state[0].startDate).format("YYYY-MM-DD"),
  //     endDate: dayjs(state[0].endDate).format("YYYY-MM-DD"),
  //   });
  // };

  // const onBtnSubmitLossing = () => {
  //   toggle();
  //   setSelectedDates({
  //     startDate: dayjs(state[0].startDate).format("YYYY-MM-DD"),
  //     endDate: dayjs(state[0].endDate).format("YYYY-MM-DD"),
  //   });
  // };

  return (
    <>
      {/* {menuTab !== 'eksternal' ? (
        <> */}
      <CustomModal isShowing={isShowing} toggle={toggle} width={"61%"}>
        <DateRangePicker
          onChange={(item) => setValue([item.selection])}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={value}
          direction="horizontal"
          rangeColors={["#D12C24", "#D12C24", "#D12C24"]}
          color={"#D12C24"}
        />
        <Grid
          container
          direction="row"
          alignItem="center"
          justifyContent="flex-end"
          columnSpacing={3}
          sx={{ pb: 3, pr: 3 }}
        >
          <Grid item>
            <Button
              style={{
                color: "#A81811",
                borderColor: "#A81811",
                borderRadius: "8px",
                textTransform: "none",
              }}
              onClick={toggle}
              variant="outlined"
            >
              Batalkan
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{
                textTransform: "none",
                borderColor: "#D12C24",
                color: "white",
                backgroundColor: "#D12C24",
                borderRadius: "8px",
              }}
              disableElevation
              variant="outlined"
              onClick={() => {
                toggle();
                setSelectedDates({
                  startDate: new Date(value[0].startDate).getTime(),
                  endDate: new Date(value[0].endDate).getTime(),
                });
                const filter = posts?.filter((d) => {
                  var time = new Date(d.updated_at).getTime();
                  return (
                    selectedDates?.startDate < time &&
                    time < selectedDates?.endDate
                  );
                });
                console.log(selectedDates?.startDate);
                // setSearchResults(filter);
              }}
              // onClick={lossing ? onBtnSubmitLossing : onBtnSubmitNonLossing}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
      {/* </>
      ) : (
        <CustomModal isShowing={isShowing} toggle={toggle} width={'61%'}>
          <DateRangePicker
            onChange={(item) => setValue([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={value}
            direction="horizontal"
          />
          <Grid
            container
            direction="row"
            alignItem="center"
            justifyContent="flex-end"
            columnSpacing={3}
            sx={{ pb: 3, pr: 3 }}
          >
            <Grid item>
              <Button sx={{ fontSize: '1rem' }} onClick={toggle} variant="outlined">
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{ fontSize: '1rem' }}
                variant="contained"
                onClick={() => {
                  toggle();
                  setSelectedDates({
                    startDate: new Date(value[0].startDate).getTime(),
                    endDate: new Date(value[0].endDate).getTime()
                  });
                  const filter = posts?.filter((d) => {
                    var time = new Date(d.updated_at).getTime();
                    return selectedDates?.startDate < time && time < selectedDates?.endDate;
                  });
                  console.log(selectedDates?.startDate);
                  setSearchResults(filter);
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </CustomModal>
      )} */}
    </>
  );
};

FilterDate.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default FilterDate;
