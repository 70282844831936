import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box } from "@mui/material";

const Card = ({ photo }) => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        width="30%"
        sx={{ cursor: "pointer" }}
        onClick={() => navigate("/plan-booking")}
      >
        <Box
          sx={{
            height: "28vh",
            backgroundImage: `url(${photo})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></Box>
        <Grid
          item
          width="90%"
          display={"flex"}
          direction="row"
          border="1"
          backgroundColor="white"
          // zIndex={"900"}
          position="relative"
          top="-80px"
          margin="auto"
          sx={{ boxShadow: 1 }}
          padding="15px"
          justifyContent={"space-between"}
        >
          <Grid
            item
            display={"flex"}
            direction="column"
            margin="auto 0"
            gap={1.5}
          >
            <Grid item fontWeight="700" fontSize="16px">
              Pameran Akhir Tahun
            </Grid>
            <Grid item color="#D14F1E" fontWeight="400" fontSize="12px">
              17 Agustus 2024
            </Grid>
            <Grid item color="#828282" fontWeight="400" fontSize="10px">
              Experience Sir Isaac Julien’s video installation exploring the
              life and legacy of architect Lina Bo Bardi.
            </Grid>
            <Grid container>
              <Box
                sx={{
                  width: "50px",
                  height: "50px",
                  backgroundColor: "#FFF1EB",
                  color: "#FF723D",
                  borderRadius: "50%",
                  // padding: "17.5444px 17px",
                  paddingTop: "16.5px",
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "12px",
                  marginTop: "10px",
                }}
              >
                FREE
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Card;
