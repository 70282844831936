import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Breadcrumbs,
  ButtonBase,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

// asset
import Back from "../assets/Back.png";
import Next from "../assets/Selanjutnya.png";

// component
import DetailTicket from "../components/Checkout/DetailTicket";
import MainNavbar from "../components/MainNavbar";
import UserNotice from "../components/UserNotice";
import { bankList } from "../utils/constant";

const DetailPembayaran = () => {
  const navigate = useNavigate();

  const breadcrumbs = [
    <Typography
      key="1"
      color="text.primary"
      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
    >
      1. Booking
    </Typography>,
    <Typography
      key="2"
      color="text.primary"
      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
    >
      2. Pesan
    </Typography>,
    <Typography
      key="3"
      color="text.primary"
      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
    >
      3. Bayar
    </Typography>,
    <Typography
      key="4"
      color="inherit"
      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
    >
      4. Selesai
    </Typography>,
  ];

  return (
    <Box bgcolor="background.paper" minHeight="100vh" paddingBottom="4rem">
      <MainNavbar />
      <UserNotice />
      <Grid container>
        <Grid
          item
          xs={11}
          sm={10}
          md={9}
          sx={{
            margin: "auto",
            marginTop: "4rem",
          }}
        >
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "5rem",
            }}
          >
            {breadcrumbs}
          </Breadcrumbs>
          <ButtonBase
            sx={{ display: "flex", gap: "0.875rem " }}
            onClick={() => navigate(-1)}
          >
            <div>
              <img src={Back} alt="" />
            </div>
            <Typography fontSize="2rem" fontWeight="700">
              Metode Pembayaran
            </Typography>
          </ButtonBase>
          <Divider
            sx={{
              width: "100%",
              bgcolor: "neutral.400",
              marginTop: "1rem",
              marginBottom: "2rem",
            }}
            orientation="horizontal"
          />
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <Box
                padding="1rem"
                paddingBottom="0"
                boxSizing="border-box"
                width="100%"
                border="1px solid"
                borderColor="neutral.400"
                marginTop="1rem"
              >
                <Typography sx={{ fontSize: "1.5rem", fontWeight: 700 }}>
                  Transfer
                </Typography>
                <Typography variant="body1">
                  Anda bisa membayar dengan transfer melalui ATM, Internet
                  Banking & Mobile Banking
                </Typography>
                {bankList.map((item) => (
                  <ButtonBase
                    sx={{
                      padding: "1rem",
                      border: "1px solid",
                      borderColor: "neutral.400",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                    onClick={() => navigate("/metode-pembayaran")}
                  >
                    <Typography>{item.title}</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1.375rem",
                      }}
                    >
                      <img src={item.image} alt="" />
                      <img src={Next} alt="" />
                    </Box>
                  </ButtonBase>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <DetailTicket total="500.000" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailPembayaran;
