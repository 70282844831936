import { configureStore } from "@reduxjs/toolkit";
import modal from "../feature/modalSlice";
import user from "../feature/userSlice";
import formEvent from "../feature/formEventSlice";
import loginAlert from "../feature/loginAlertSlice";

const store = configureStore({
  reducer: {
    modal,
    user,
    formEvent,
    loginAlert,
  },
});

export default store;
