import React, { useState } from "react";

import { Button, Grid, Box, TextField, Radio, FormControlLabel } from "@mui/material";
import { Icon } from "@iconify/react";
import Image from "../assets/img.png";
import Goggle from "../assets/Google.png";

import TabsEvent from "../components/tabsLogin";
import TabPanel from "../components/Tabs";
import { height } from "@mui/system";
import { useNavigate } from "react-router-dom";




const About = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Grid container>
                <Grid item  >
                    <Box
                        sx={{
                            height: "795px",
                            width: "706px",
                            backgroundImage: `url(${Image})`,
                            backgroundSize: "cover",
                            backgroundBlendMode: "hard-light, normal"
                        }}
                    ></Box>

                </Grid>
                <Grid item marginTop={"100px"} marginLeft="80px">
                    <Grid item fontWeight="500" marginLeft="80px" fontFamily={'Plus Jakarta Sans'} lineHeight="160%" fontStyle={"normal"} fontSize="16px" color="##2D3748" width={"110px"} height={"22px"}>

                        Selamat Datang

                    </Grid>



                    <Grid item fontWeight="700" marginLeft="80px" fontFamily={'Plus Jakarta Sans'} lineHeight="150%" fontStyle={"normal"} fontSize="24px" color="#1A202C" width={"236px"} height={"36px"}  >
                    Public Visitor
                    </Grid>
                    <Grid item marginTop={"15px"} >

                        <Grid item marginLeft="80px" >Nama</Grid>
                        <Grid item xs={10} marginLeft="80px" marginTop={"10px"}>
                            <TextField
                                required
                                name="phone" size="small"
                                rows={1}
                                multiline
                                fullWidth
                                placeholder="Masukan Nama"
                            />
                        </Grid>

                        <Grid item marginLeft="80px" marginTop={"20px"} >No HP</Grid>
                        <Grid item xs={10} marginLeft="80px" marginTop={"10px"}>
                            <TextField
                                required
                                name="phone" size="small"
                                rows={1}
                                multiline
                                fullWidth
                                placeholder="Masukan No HP"
                            />
                        </Grid>

                        <Grid item marginLeft="80px" marginTop={"20px"} >Email</Grid>
                        <Grid item xs={10} marginLeft="80px" marginTop={"10px"}>
                            <TextField
                                required
                                name="phone" size="small"
                                rows={1}
                                multiline
                                fullWidth
                                placeholder="Masukan Email"
                            />
                        </Grid>
                        <Grid item marginLeft="80px" marginTop={"20px"} >Password</Grid>
                        <Grid item xs={10} marginLeft="80px" marginTop={"10px"}>
                            <TextField
                                required
                                name="phone" size="small"
                                rows={1}
                                multiline
                                fullWidth
                                placeholder="Masukan Password"
                            />
                        </Grid>
                        <Grid item marginLeft="80px" marginTop={"20px"} >Ulangi Password</Grid>
                        <Grid item xs={10} marginLeft="80px" marginTop={"10px"}>
                            <TextField
                                required
                                name="phone" size="small"
                                rows={1}
                                multiline
                                fullWidth
                                placeholder="Masukan Password"
                            />
                        </Grid>

                        <Grid item>
                            <Button

                                variant="outlined"
                                style={{
                                    borderRadius: "0",
                                    backgroundColor: '#D14F1E',
                                    width: "434px",
                                    height: "48px",
                                    marginLeft: "80px",
                                    marginTop: "30px"
                                }}
                                onClick={() => navigate("/")}
                            >
                                <Grid item color='white'>Masuk</Grid>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button

                                variant="outlined"
                                style={{
                                    borderRadius: "0",
                                    backgroundColor: '#FFFFFF',
                                    width: "434px",
                                    height: "48px",
                                    marginLeft: "80px",
                                    marginTop: "30px"
                                }}
                            >
                                <Grid item color='#D14F1E'>   <img src={Goggle} alt="" />   Atau masuk dengan Google</Grid>
                            </Button>
                        </Grid>






                    </Grid>

                </Grid>
            </Grid >


        </>
    );
};

export default About;