import React from "react";
import { Typography, Box } from "@mui/material";
import { Icon } from "@iconify/react";

const Title = ({ value, section, setSection, setLoginTitle }) => {
  const handleSignUp = () => {
    setLoginTitle(true);
    setSection("");
  };
  return (
    <>
      {section !== "" && (
        <Box sx={{ cursor: "pointer" }}>
          <Icon
            icon="ph:arrow-up-bold"
            width="36"
            height="36"
            rotate={3}
            onClick={handleSignUp}
          />
        </Box>
      )}
      <Typography fontWeight="500" fontSize="14px">
        {section === "" ? "Selamat Datang" : "Silahkan Daftar"}
      </Typography>
      <Typography
        sx={{
          fontWeight: "700",
          fontSize: "24px",
          marginBottom: section === "" && "48px",
        }}
      >
        {section === "" ? "Masuk ke akun anda" : value}
      </Typography>
    </>
  );
};

export default Title;
