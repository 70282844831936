import React from "react";
import { Box, Typography } from "@mui/material";

// component
import CustomModal from "../CustomModal/FilterDate";
import MainButton from "../Elements/MainButton";

const ApprovalModal = ({
  toggle,
  isShowing,
  handleClose,
  onApprove,
  onReject,
}) => {
  return (
    <>
      <CustomModal
        toggle={toggle}
        isShowing={isShowing}
        handleClose={handleClose}
        width="27.563rem"
        isSquare
      >
        <Box sx={{ padding: "3rem", minHeight: "11.625rem" }}>
          <Typography
            sx={{ color: "primary.500", fontSize: "1.25rem", fontWeight: 700 }}
          >
            Apakah Anda menyetujui event ini?
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "2rem",
              marginTop: "2rem",
            }}
          >
            <MainButton
              variant="outlined"
              sx={{
                border: "primary.500",
                color: "primary.500",
                height: "2.25rem",
              }}
              onClick={onReject}
            >
              Tolak
            </MainButton>
            <MainButton
              variant="contained"
              sx={{ bgcolor: "primary.500", height: "2.25rem" }}
              onClick={onApprove}
            >
              Setuju
            </MainButton>
          </Box>
        </Box>
      </CustomModal>
    </>
  );
};

export default ApprovalModal;
