import React from "react";
import { Grid, Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  formEventState,
  handlePreviousPage,
} from "../../redux/feature/formEventSlice";

const ButtonForm = () => {
  const dispatch = useDispatch();

  const { page } = useSelector(formEventState);
  const handlePrev = (e) => {
    e.preventDefault(); // it refreshes in submission form
    dispatch(handlePreviousPage());
  };

  const disablePrev = page === 0;
  const disableNext = page === 2;

  return (
    <>
      <Grid container gap={2}>
        {page === 0 ? (
          <Button
            type="submit"
            style={{
              backgroundColor: "#D14F1E",
              color: "white",
              borderRadius: "0",
            }}
            // onClick={handleNext}
            disabled={disableNext}
          >
            Selanjutnya
          </Button>
        ) : page === 2 ? (
          <>
            <Button
              onClick={handlePrev}
              disabled={disablePrev}
              variant="outlined"
              style={{
                borderRadius: "0",
              }}
            >
              Kembali
            </Button>
            <Button
              type="submit"
              style={{
                backgroundColor: "#D14F1E",
                color: "white",
                borderRadius: "0",
              }}
            >
              Submit
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={handlePrev}
              disabled={disablePrev}
              variant="outlined"
              style={{
                borderRadius: "0",
              }}
            >
              Kembali
            </Button>
            <Button
              type="submit"
              // onClick={handleNext}
              disabled={disableNext}
              style={{
                backgroundColor: "#D14F1E",
                color: "white",
                borderRadius: "0",
              }}
            >
              Selanjutnya
            </Button>
          </>
        )}
      </Grid>
    </>
  );
};

export default ButtonForm;
