import React, { useRef } from "react";

import { Grid, Button } from "@mui/material";
import MainButton from "../components/Elements/MainButton";
import Phone from "../assets/phone-solid.svg";

const Headline = ({
  title,
  setSection,
  back,
  content,
  printPr,
  printPo,
  toggle,
  berita,
  kembali,
  about,
  news,
  Edit,
  purchaseOrder,
  productReceipt,
  ijt,
  Conten,
}) => {
  const componentRef = useRef();
  const productRef = useRef();
  return (
    <>
      {/* Print */}

      <Grid
        item
        container
        display="flex"
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
      >
        <Grid
          item
          sx={{
            fontWeight: "700",
            fontSize: "20px",
          }}
        >
          {title}
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            fontWeight: "700",
            fontSize: "20px",
          }}
          // gap={3}
        >
          <Grid item>
            {back ? (
              <Button
                style={{
                  borderColor: "#D12C24",
                  color: "#D12C24",
                  backgroundColor: "white",
                  borderRadius: "8px",
                  textTransform: "none",
                }}
                disableElevation
                variant="outlined"
                onClick={() => setSection("")}
              >
                {"< Kembali"}
              </Button>
            ) : null}
          </Grid>
          <Grid item>
            {kembali ? (
              <Button
                style={{
                  borderColor: "#FF723D",
                  color: "#FF723D",
                  backgroundColor: "white",
                  borderRadius: "8px",
                  textTransform: "none",
                  marginRight: "30px",
                }}
                disableElevation
                variant="outlined"
                onClick={() => setSection("")}
              >
                {"Kembali"}
              </Button>
            ) : null}
          </Grid>

          <Grid item>
            {berita ? (
              <Button
                style={{
                  borderColor: "#FF723D",
                  color: "white",
                  backgroundColor: "#FF723D",
                  borderRadius: "8px",
                  textTransform: "none",
                }}
                disableElevation
                variant="outlined"
                onClick={() => setSection("")}
              >
                Buat Berita
              </Button>
            ) : null}
          </Grid>

          <Grid item>
            {Edit ? (
              <Button
                style={{
                  borderColor: "#FF723D",
                  color: "white",
                  backgroundColor: "#FF723D",
                  borderRadius: "8px",
                  textTransform: "none",
                }}
                disableElevation
                variant="outlined"
                onClick={() => setSection("")}
              >
                Edit
              </Button>
            ) : null}
          </Grid>

          <Grid item display="flex" gap={2}>
            {Conten ? (
              <>
                <Grid item>
                  <Button
                    style={{
                      borderColor: "#D12C24",
                      color: "white",
                      backgroundColor: "#D12C24",
                      borderRadius: "8px",
                      textTransform: "none",
                    }}
                    disableElevation
                    variant="contained"
                    onClick={toggle ? toggle : () => setSection("addData")}
                  >
                    {content}
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    style={{
                      borderColor: "#D12C24",
                      color: "#D12C24",
                      backgroundColor: "white",
                      borderRadius: "8px",
                      textTransform: "none",
                    }}
                    disableElevation
                    variant="outlined"
                    onClick={() => setSection("uploadData")}
                  >
                    Upload Purchase Order
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    style={{
                      borderColor: "#D12C24",
                      color: "#D12C24",
                      backgroundColor: "white",
                      borderRadius: "8px",
                      textTransform: "none",
                    }}
                    disableElevation
                    variant="outlined"
                  >
                    <Grid item marginRight="0.5rem"></Grid>
                    Download Purchase Order
                  </Button>
                </Grid>
              </>
            ) : news ? (
              <>
                <Grid item>
                  <Button
                    style={{
                      borderColor: "#D12C24",
                      color: "white",
                      backgroundColor: "#D12C24",
                      borderRadius: "8px",
                      textTransform: "none",
                    }}
                    disableElevation
                    variant="contained"
                    onClick={toggle ? toggle : () => setSection("addData")}
                  >
                    {content}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    style={{
                      borderColor: "#D12C24",
                      color: "#D12C24",
                      backgroundColor: "white",
                      borderRadius: "8px",
                      textTransform: "none",
                    }}
                    disableElevation
                    variant="outlined"
                    onClick={() => setSection("uploadData")}
                  >
                    <Grid item marginRight="0.5rem"></Grid>
                    Upload Product Receipt
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    style={{
                      borderColor: "#D12C24",
                      color: "#D12C24",
                      backgroundColor: "white",
                      borderRadius: "8px",
                      textTransform: "none",
                    }}
                    disableElevation
                    variant="outlined"
                    onClick={() => setSection("")}
                  >
                    <Grid item marginRight="0.5rem"></Grid>
                    Download Product Receipt
                  </Button>
                </Grid>
              </>
            ) : content ? (
              <Grid item>
                <MainButton
                  variant="contained"
                  onClick={toggle ? toggle : () => setSection("addData")}
                >
                  {content}
                </MainButton>
              </Grid>
            ) : ijt ? (
              <>
                <Grid item>
                  <Button
                    style={{
                      borderColor: "#D12C24",
                      color: "white",
                      backgroundColor: "#D12C24",
                      borderRadius: "8px",
                      textTransform: "none",
                    }}
                    disableElevation
                    variant="contained"
                    onClick={toggle ? toggle : () => setSection("addData")}
                  >
                    + Tambah IJT
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    style={{
                      borderColor: "#D12C24",
                      color: "#D12C24",
                      backgroundColor: "white",
                      borderRadius: "8px",
                      textTransform: "none",
                    }}
                    disableElevation
                    variant="outlined"
                    onClick={() => setSection("uploadData")}
                  >
                    <Grid item marginRight="0.5rem"></Grid>
                    Upload IJT
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    style={{
                      borderColor: "#D12C24",
                      color: "#D12C24",
                      backgroundColor: "white",
                      borderRadius: "8px",
                      textTransform: "none",
                    }}
                    disableElevation
                    variant="outlined"
                  >
                    <Grid item marginRight="0.5rem"></Grid>
                    Download IJT
                  </Button>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Headline;
