import { Icon } from "@iconify/react";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";

function TicketExchangeGuide() {
  return (
    <>
      <Grid container sx={{ marginBottom: "1rem" }}>
        <Grid item xs={12} sx={{ padding: "1rem", bgcolor: "neutral.200" }}>
          <Typography fontWeight="700" fontSize="0.875rem">
            Panduan Penukaran Tiket
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            padding: "1rem",
            bgcolor: "primary.100",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <Box>
            <Icon
              icon="material-symbols:info-rounded"
              height="2rem"
              width="2rem"
              color="#D14F1E"
            />
          </Box>
          <ul style={{ fontSize: "0.75rem", color: "#1F1F1F" }}>
            <li>
              Tunjukkan e-ticket yang telah diterima melalui email atau halaman
              “My Booking” di web TIM kepada petugas di lapangan untuk scan QR
              Code.
            </li>
            <li>
              Sesuaikan tingkat kecerahan layar ponsel anda sebelum menunjukan
              QR Code. Setelah QR Code sukses terverifikasi, customer akan
              mendapatkan wristband yang dapat digunakan untuk memasuki venue.
            </li>
            <li>
              Customer disarankan memakai masker, membawa hand sanitizer pribadi
              dan memenuhi protokol kesehatan selama event berlangsung.
            </li>
          </ul>
        </Grid>
      </Grid>
    </>
  );
}

export default TicketExchangeGuide;
