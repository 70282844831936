import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";

import MainButton from "../../Elements/MainButton";
import CustomModal from "../CustomModal";
import AdminTextArea from "../../../components/Elements/AdminTextArea";
import Reject from "./Reject";
import Accept from "./Approve";

const Approval = ({
  toggle,
  isShowing,
  approve,
  reject,
  setReject,
  showReject,
  setType,
}) => {
  useEffect(() => {
    setReject(false);
  }, [isShowing]);

  return (
    <>
      <CustomModal isShowing={isShowing} toggle={toggle} width="45%">
        <Grid
          container
          sx={{
            backgroundColor: "white",
            borderRadius: "4px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "48px",
          }}
        >
          {showReject ? (
            <Reject reject={reject} setReject={setReject} setType={setType} />
          ) : (
            <Accept approve={approve} setReject={setReject} />
          )}
        </Grid>
      </CustomModal>
    </>
  );
};

export default Approval;
