import React from "react";
import { Grid } from "@mui/material";
import { Icon } from "@iconify/react";

import About1 from "../assets/about.JPG";
import About2 from "../assets/about2.JPG";
import About3 from "../assets/about3.JPG";
import About4 from "../assets/about4.JPG";
import About5 from "../assets/about5.JPG";

const About = () => {
  return (
    <>
      <Grid display={"flex"} direction="column" gap={3}>
        <Grid
          display={"flex"}
          direction="column"
          item
          padding="1rem 7rem"
          gap={4}
        >
          <Grid item display={"flex"} direction="row" gap={2}>
            <Grid item paddingTop="0.1rem">
              <Icon
                icon="material-symbols:home-outline"
                color="#1f1f1f"
                width="16"
                height="16"
              />
            </Grid>
            <Grid item fontWeight="500" fontSize="16px">
              Home
            </Grid>
            <Grid item fontWeight="500" fontSize="16px">
              {">"}
            </Grid>
            <Grid item color={"#828282"} fontWeight="500" fontSize="16px">
              About Us
            </Grid>
          </Grid>
          <Grid item fontWeight="700" fontSize="48px">
            Taman Ismail Marzuki
          </Grid>
          <Grid item display={"flex"} direction="row" height="60vh" gap={2}>
            <Grid
              item
              sx={{
                backgroundImage: `url(${About1})`,
                backgroundSize: "cover",
              }}
              xs={6}
            ></Grid>
            <Grid item display={"flex"} direction="column" gap={2} xs={6}>
              <Grid item display={"flex"} direction="row" height="30vh" gap={2}>
                <Grid
                  item
                  sx={{
                    backgroundImage: `url(${About2})`,
                    backgroundSize: "cover",
                  }}
                  xs={6}
                ></Grid>
                <Grid
                  item
                  sx={{
                    backgroundImage: `url(${About3})`,
                    backgroundSize: "cover",
                  }}
                  xs={6}
                ></Grid>
              </Grid>
              <Grid item display={"flex"} direction="row" height="30vh" gap={2}>
                <Grid
                  item
                  sx={{
                    backgroundImage: `url(${About4})`,
                    backgroundSize: "cover",
                  }}
                  xs={6}
                ></Grid>
                <Grid
                  item
                  sx={{
                    backgroundImage: `url(${About5})`,
                    backgroundSize: "cover",
                  }}
                  xs={6}
                ></Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item fontWeight="600" fontSize="16px">
            Taman Ismail Marzuki dibangun di area seluas kurang lebih 7,2
            hektar. Pembukaannya diresmikan langsung oleh Gubernur DKI Jakarta
            saat itu, Ali Sadikin, pada 10 November 1968. Awalnya lahan ini
            merupakan ruang rekreasi umum Taman Raden Saleh, serta kebun
            binatang Jakarta (saat ini pindah ke Ragunan). Kemudian, Ali Sadikin
            mengubah area ini menjadi pusat kesenian agar para seniman Jakarta
            dapat berkarya. Nama Ismail Marzuki dipilih atas penghargaan sebagai
            seniman asal Betawi (Jakarta) yang telah berjasa menciptakan lebih
            dari 200 lagu, di antaranya lagu-lagu perjuangan bangsa, seperti
            Halo-Halo Bandung, Berkibarlah Benderaku, Nyiur Melambai dan
            Sepasang Mata Bola. Melalui Peraturan Gubernur Nomor 327 Tahun 2016
            Tentang organisasi dan Tata Kerja Unit Pengelola Pusat Kesenian
            Jakarta Taman Ismail Marzuki. Dengan terbitnya Pergub 327 tersebut
            Unit Pengelola Pusat Kesenian Jakarta Taman Ismail marzuki mengelola
            Planetarium dan Observatorium.
          </Grid>
        </Grid>
        {/* <LatestNews /> */}
      </Grid>
    </>
  );
};

export default About;
