import React from "react";
import { Avatar, Grid, IconButton } from "@mui/material";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Logo from "../../assets/LogoPutih.png";
import { userState } from "../../redux/feature/userSlice";
import { useSelector } from "react-redux";
import { homeLabel } from "../../utils/tabsLabel";

// hooks
import useAuth from "../../hooks/useAuth";

const TabsHeader = ({ handleChange, value, navigate }) => {
  const { userData } = useAuth();

  return (
    <>
      <Grid
        item
        display="flex"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap="1rem"
      >
        <Grid item onClick={() => navigate("/")} sx={{ cursor: "pointer" }}>
          <img src={Logo} alt="" />
        </Grid>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="white"
          TabIndicatorProps={{
            style: {
              backgroundColor: "white",
            },
          }}
        >
          {homeLabel?.map((item) => (
            <Tab
              key={item.label}
              label={item?.label}
              value={item?.label}
              sx={{
                textTransform: "none",
                color: "#FFFFFF",
                margin: "0 1rem",
              }}
            /> // tab needs value
          ))}
          {userData?.role && (
            <IconButton
              color="inherit"
              edge="start"
              onClick={() => {
                userData?.role === 1
                  ? navigate("/profile")
                  : navigate("/profile/eo");
              }}
            >
              <Avatar
                sx={{ bgcolor: "primary.500", border: "2px solid white" }}
              >
                {Array.from(userData?.name)[0].toUpperCase()}
              </Avatar>
            </IconButton>
          )}
        </Tabs>
      </Grid>
    </>
  );
};

export default TabsHeader;
