import React from "react";
import { Box, Tabs, Tab } from "@mui/material";

const TabsHome = ({
  handleChange,
  value,
  label,
  membership,
  handleChangeMembership,
}) => {
  console.log(value);
  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          width: "100%",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant={label?.length > 3 ? "fullWidth" : ""}
          textColor="primary"
          indicatorColor="tertiary"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#FF723D",
              height: "0.25rem",
            },
          }}
        >
          {label?.map((item) => (
            <Tab
              label={item?.label}
              value={item?.label}
              sx={{
                textTransform: "none",
                color: "#BDBDBD",
              }}
            />
          ))}
        </Tabs>
      </Box>
      {value === "Membership" && (
        <Box
          sx={{
            borderColor: "divider",
            boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.1)",
            backgroundColor: "white",
            width: "100%",
          }}
        >
          <Tabs
            value={membership}
            onChange={handleChangeMembership}
            textColor="primary"
            indicatorColor="tertiary"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#FF723D",
                height: "0.25rem",
              },
            }}
          >
            {label[1].child?.map((item) => (
              <Tab
                value={item}
                label={item}
                sx={{
                  textTransform: "none",
                  color: "#BDBDBD",
                }}
              />
            ))}
          </Tabs>
        </Box>
      )}
    </>
  );
};

export default TabsHome;
