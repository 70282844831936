import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

// component
import UserHeader from "../../components/UserDashboard/UserHeader";

// redux
import { setUserData } from "../../redux/feature/userSlice";
import EventCard from "../../components/MyEvent/EventCard";

// service
import EventServices from "../../services/EventServices";

function MyEvent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, isLoading, isFetching, error, refetch } = useQuery(
    ["data-event"],
    () => EventServices.getEventById({})
  );

  const userData = {
    name: "Louise Thompson",
    type: "Public Visitor",
    phone: "083221664825",
    email: "louisethompson@gmail.com",
    initial: "L",
    role: "eo",
  };

  const dummyEventData = [
    {
      id: 1,
      title: "Animakini X Ciffest 20233",
      bookingId: 12651257126,
      ticketAmount: 2,
      date: "Senin, 20 Februari 2023 - Rabu, 22 Februari 2023",
      statusProposal: "Menunggu Persetujuan",
      statusPost: "Menunggu Approval Proposal Event",
    },
    {
      id: 2,
      title: "Animakini X Ciffest 2023",
      bookingId: 12651257126,
      ticketAmount: 2,
      date: "Senin, 20 Februari 2023 - Rabu, 22 Februari 2023",
      statusProposal: "Disetujui",
      statusPost: "Data Belum Ada",
    },
    {
      id: 3,
      title: "Animakini X Ciffest 2023",
      bookingId: 12651257126,
      ticketAmount: 2,
      date: "Senin, 20 Februari 2023 - Rabu, 22 Februari 2023",
      statusProposal: "Ditolak",
      statusPost: "Ditolak",
    },
    {
      id: 4,
      title: "Animakini X Ciffest 2023",
      bookingId: 12651257126,
      ticketAmount: 2,
      date: "Senin, 20 Februari 2023 - Rabu, 22 Februari 2023",
      statusProposal: "Disetujui",
      statusPost: "Disetujui",
    },
  ];

  return (
    <>
      <Grid container display="flex" direction="column" sx={{ gap: "1rem" }}>
        <UserHeader title="My Event" isFilter />
        {data?.data?.data.length > 0 ? (
          data?.data?.data.map((item) => (
            <EventCard
              key={item.id}
              bookingId={item?.id}
              eventType={item?.event_type}
              title={item?.event_name}
              startDate={item?.start_date}
              endDate={item?.end_date}
              proposalStatus={item.status_event}
              postStatus={item?.status_event}
              onShowDetail={() =>
                navigate(`/myevent/${item.id}`, { state: data?.data?.data })
              }
            />
          ))
        ) : (
          <Typography>Tidak Ada Data</Typography>
        )}
      </Grid>
    </>
  );
}

export default MyEvent;
