import React, { useState } from "react";

import TabsHome from "../../components/tabsHome";
import About from "./about";
import News from "./news";
import Admission from "./admision";
import VenueMap from "./venueMaps";
import GettingHere from "./gettingHere";
import Contact from "./contact";
import { contentAdminLabel } from "../../utils/tabsLabel";

const ContentTable = ({ setSection, valueItem, setValueItem }) => {
  const [value, setValue] = useState("About");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <TabsHome
        value={value}
        handleChange={handleChange}
        label={contentAdminLabel}
      />
      {value === "About" ? (
        <About />
      ) : value === "Admission" ? (
        <Admission />
      ) : value === "News" ? (
        <News setSection={setSection} />
      ) : value === "Contact" ? (
        <Contact setSection={setSection} />
      ) : value === "News" ? (
        <News setSection={setSection} />
      ) : value === "Getting Here" ? (
        <GettingHere setSection={setSection} />
      ) : (
        <VenueMap setSection={setSection} />
      )}
    </>
  );
};

export default ContentTable;
