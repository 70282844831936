import React from "react";
import { Grid, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import MainButton from "../Elements/MainButton";
import AdminTextArea from "../Elements/AdminTextArea";
import UploadForm from "../Elements/UploadForm";

const Upload = () => {
  return (
    <>
      <Grid
        container
        sx={{ width: "665px" }}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Grid item display={"flex"} direction="row" xs={5.5}>
          <Grid item color="#B43127">
            *
          </Grid>
          <Grid item fontWeight={"700"} fontSize="20px">
            Image
          </Grid>
        </Grid>
        <Grid item display={"flex"} direction="row" xs={5.5}>
          <Grid item color="#B43127">
            *
          </Grid>
          <Grid item fontWeight={"700"} fontSize="20px">
            Deskripsi Image
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ width: "765px" }}
        gap={2}
        display={"flex"}
        // justifyContent={"space-between"}
      >
        <Grid item xs={5.5}>
          <Grid item marginTop={"20px"}>
            {/* column 1 */}
            <UploadForm />
            <Grid item fontWeight="400" fontSize="14px">
              <Grid container>
                <Grid item color="#B43127">
                  *
                </Grid>
                Format file: jpg, jpeg | Ukuran file max 10Mb
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid item marginTop={"20px"}>
            {/* <TextField
                  required
                  name="phone"
                  size="small"
                  rows={5}
                  multiline
                  fullWidth
                  placeholder="Deskripsi Berita"
                /> */}
            <AdminTextArea rows={2} multiline={true} />
          </Grid>
        </Grid>
        <Grid item xs={1} marginTop={"35px"}>
          <MainButton variant={"outlined"} sx={{ width: "110px" }}>
            <Grid container alignItems={"center"} gap={1}>
              <Icon
                icon="ic:outline-delete"
                color="#ff723d"
                width="20"
                height="20"
              />
              <Typography>Hapus</Typography>
            </Grid>
          </MainButton>
        </Grid>
      </Grid>
    </>
  );
};

export default Upload;
