import React from "react";
import { Grid, Button } from "@mui/material";
import { Icon } from "@iconify/react";

const Venue = () => {
  return (
    <>
      <Grid display={"flex"} direction="column" padding="1rem 7rem" gap={2}>
        <Grid
          item
          display={"flex"}
          direction="row"
          alignItems={"center"}
          gap={2}
        >
          <Grid item paddingTop="0.1rem">
            <Icon
              icon="material-symbols:home-outline"
              color="#1f1f1f"
              width="16"
              height="16"
            />
          </Grid>
          <Grid item fontWeight="500" fontSize="16px">
            Home
          </Grid>
          <Grid item fontWeight="500" fontSize="16px">
            {">"}
          </Grid>
          <Grid item fontWeight="500" fontSize="16px">
            Plan Your Visit
          </Grid>
          <Grid item fontWeight="500" fontSize="16px">
            {">"}
          </Grid>
          <Grid item color={"#828282"} fontWeight="500" fontSize="16px">
            Venue Map
          </Grid>
        </Grid>
        <Grid item fontWeight="700" fontSize="48px">
          Venue Map
        </Grid>
        <Grid
          item
          display="flex"
          flexDirection={"column"}
          border={1}
          borderColor="#828282"
          padding="1rem"
        >
          <Grid item>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.5485007339453!2d106.83615461488672!3d-6.191115162380591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f43ed58a394b%3A0x52eb0c276d813909!2sTaman%20Ismail%20Marzuki!5e0!3m2!1sid!2sid!4v1678857908855!5m2!1sid!2sid"
              style={{
                border: "0",
                width: "100%",
              }}
              display="flex"
              height={"600px"}
              allowfullscreen=""
              loading="lazy"
              title="Venue Map"
              referrerpolicy="no-referrer-when-downgrade"
            />
          </Grid>

          <Grid
            item
            display="flex"
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Grid
              item
              display="flex"
              flexDirection={"row"}
              alignItems={"center"}
              gap={2}
            >
              <Grid item>
                <Icon icon="ph:map-pin" width="24" height="24" />
              </Grid>
              <Grid item display="flex" flexDirection={"column"}>
                <Grid item fontWeight="500" fontSize="14px">
                  Jl. Cikini Raya No.73, RT.8/RW.2, Cikini, Kec. Menteng, Kota
                  Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10330
                </Grid>
                <Grid item color="#828282" fontWeight="500" fontSize="14px">
                  Gedung Taman Ismail Marzuki
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <a href="https://www.google.com/maps/dir//Taman+Ismail+Marzuki+Cikini+Kec.+Menteng+Kota+Jakarta+Pusat,+Daerah+Khusus+Ibukota+Jakarta/@-6.1911205,106.8383433,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x2e69f43ed58a394b:0x52eb0c276d813909!2m2!1d106.8383433!2d-6.1911205">
                <Button
                  style={{
                    backgroundColor: "white",
                    color: "#D14F1E",
                    borderRadius: "0",
                    padding: "12px 24px",
                  }}
                >
                  <Grid
                    item
                    display="flex"
                    flexDirection={"row"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <Grid item>
                      <Icon
                        icon="teenyicons:direction-outline"
                        color="#d14f1e"
                        width="14"
                        height="14"
                        rotate={1}
                      />
                    </Grid>
                    <Grid item>Petunjuk Arah</Grid>
                  </Grid>
                </Button>
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Venue;
