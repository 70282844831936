export const adminHomeLabel = [
  { label: "Proposal Event" },
  { label: "Post Event" },
  { label: "Ticket Event" },
];

export const loginLabel = [
  { label: "Public Visitor" },
  { label: "Event Organizer" },
  { label: "Artist" },
];

export const userManagementLabel = [
  { label: "Admin" },
  {
    label: "Membership",
    child: ["Public Visitor", "Event Organizer", "Artist"],
  },
  { label: "Role & Hak Akses" },
];

export const contentAdminLabel = [
  { label: "About" },
  { label: "Admission" },
  { label: "News" },
  { label: "Contact" },
  { label: "Legal" },
  { label: "Getting Here" },
  { label: "Venue Map" },
];

export const homeLabel = [
  { label: "Plan Your Visit" },
  { label: "Exhibition & Event" },
  { label: "Collection" },
  { label: "Join & Support" },
];

export const exhibitionLabel = [
  { label: "Deskripsi" },
  { label: "Paket" },
  { label: "Lokasi" },
];
