import { Grid, Tabs, Tab } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/LogoPutih.png";
import Event3 from "../assets/tim1.png";

const Header = ({
  children,
  title,
  menuList,
  menuTab,
  handleChangeTab,
  setMenuTab,
  isRedirect,
  handleRedirect,
}) => {
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setMenuTab(newValue);
    isRedirect && handleRedirect(newValue);
  };

  return (
    <>
      <Grid
        item
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "15vh",
          //   backgroundImage: `url(${Event3})`,
          //   backgroundSize: "cover",
          backgroundColor: "black",
          zIndex: "2000",
          backgroundPosition: "center",
          backgroundSize: "cover",
          justifyContent: "space-around",
          position: "sticky",
          top: "0",
        }}
      >


        <Grid
          container
          display="flex"
          marginLeft={"30%"}
          color="white"
          fontFamily={'Plus Jakarta Sans'}
          fontStyle="normal"
          fontWeight={"700"}
          fontSize="19px"
          lineHeight={"140%"}
          marginTop="40px"
          letterSpacing={"0.25"}
        >
          <Grid item left={"10%"} right="0%" top={"30px"} bottom="0%" position={"absolute"} onClick={() => navigate("/")} sx={{ cursor: "pointer" }} >
            <img src={Logo} alt="" />
          </Grid>


          <Grid item display="flex" direction={"row"} gap={"100px"}>

            <Grid item onClick={() => navigate("/")}>Plan Your Visit</Grid>
            <Grid item onClick={() => navigate("/")} >Exhibition & Event</Grid>
            <Grid item onClick={() => navigate("/")}>Collection</Grid>
            <Grid item onClick={() => navigate("/")}>Join & Support</Grid>


          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Header;
