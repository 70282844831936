import React from "react";
import { Grid } from "@mui/material";

const AdminCard = ({ image, title, stats, content }) => {
  return (
    <>
      <Grid item sx={{ boxShadow: 2 }} xs={2.9}>
        <Grid
          item
          display={"flex"}
          direction={"row"}
          alignItems={"center"}
          backgroundColor="#FF723D"
          color={"white"}
          gap={2}
          padding="10px 20px 10px 20px"
        >
          <Grid item>
            <img src={image} alt="" />
          </Grid>
          <Grid item>{title}</Grid>
        </Grid>
        <Grid
          item
          display={"flex"}
          direction={"row"}
          alignItems={"center"}
          gap={2}
          padding="10px 20px 10px 20px"
        >
          <Grid item fontWeight="700" fontSize="48px">
            {stats}
          </Grid>
          <Grid item color="#828282" fontSize={"16px"} fontWeight="500">
            {content}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminCard;
