import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// redux
import { setLoading } from "../../redux/feature/modalSlice";
import { setUserData } from "../../redux/feature/userSlice";
import { setFormEventData } from "../../redux/feature/formEventSlice";

// component
import BackButton from "../../components/Elements/BackButton";
import ProposalEvent from "../../components/MyEvent/ProposalEvent";
import PostEvent from "../../components/MyEvent/PostEvent";

// service
import EventServices from "../../services/EventServices";

function DetailMyEvent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchEventDetail = async () => {
      dispatch(setLoading(true));
      try {
        const response = await EventServices.getEventDetail(id);
        setData(response?.data?.data);
        dispatch(setFormEventData(response?.data?.data));
        dispatch(setLoading(false));
      } catch (error) {
        console.log(error);
      }
    };
    fetchEventDetail();
  }, []);

  const userData = {
    name: "Louise Thompson",
    type: "Public Visitor",
    phone: "083221664825",
    email: "louisethompson@gmail.com",
    initial: "L",
    role: "eo",
  };

  useEffect(() => {
    dispatch(setUserData(userData));
  }, []);

  const dummyEventData = [
    {
      id: 1,
      title: "Animakini X Ciffest 2023",
      orderId: 12651257126,
      ticketAmount: 2,
      date: "Senin, 20 Februari 2023",
      bookCode: "1256125137",
      name: "Luis Thomson",
      email: "luisthomson@gmail.com",
      ticketCategory: "VIP 1 Tiket",
      Pax: "1 Pax",
      category: "VIP",
      statusProposal: "Menunggu Persetujuan",
      statusPost: "Menunggu Approval Proposal Event",
      statusPostFeedback: null,
      statusProposalFeedback: null,
    },
    {
      id: 2,
      title: "Animakini X Ciffest 2023",
      orderId: 12651257126,
      ticketAmount: 2,
      date: "Senin, 20 Februari 2023",
      bookCode: "1256125137",
      name: "Luis Thomson",
      email: "luisthomson@gmail.com",
      ticketCategory: "VIP 1 Tiket",
      Pax: "1 Pax",
      category: "VIP",
      statusProposal: "Disetujui",
      statusPost: "Data Belum Ada",
      statusPostFeedback: null,
      statusProposalFeedback: null,
    },
    {
      id: 3,
      title: "Animakini X Ciffest 2023",
      orderId: 12651257126,
      ticketAmount: 2,
      date: "Senin, 20 Februari 2023",
      bookCode: "1256125137",
      name: "Luis Thomson",
      email: "luisthomson@gmail.com",
      ticketCategory: "VIP 1 Tiket",
      Pax: "1 Pax",
      category: "VIP",
      statusProposal: "Ditolak",
      statusPost: "Ditolak",
      statusProposalFeedback:
        "Data proposal tidak menunjukan deskripsi event yang jelas, silahkan sesuaikan dengan template proposal yang ada di menu admission TIM. kemudian lakukan submit ulang untuk ditinjau kembali oleh admin",
      statusPostFeedback:
        "Data paket melebihi kuota yang disetujui, silahkan sesuaikan dengan kuota yang sudah disetujui pada halaman submit your event. kemudian lakukan submit ulang untuk ditinjau kembali oleh admin",
    },
    {
      id: 4,
      title: "Animakini X Ciffest 2023",
      orderId: 12651257126,
      ticketAmount: 2,
      date: "Senin, 20 Februari 2023",
      bookCode: "1256125137",
      name: "Luis Thomson",
      email: "luisthomson@gmail.com",
      ticketCategory: "VIP 1 Tiket",
      Pax: "1 Pax",
      category: "VIP",
      statusProposal: "Disetujui",
      statusPost: "Disetujui",
      statusPostFeedback: null,
      statusProposalFeedback: null,
    },
  ];

  return (
    <>
      <Grid container display={"flex"} direction="column" sx={{ gap: "1rem" }}>
        <BackButton onClick={() => navigate("/myevent")} />
        <ProposalEvent data={data} id={id} />
        <PostEvent data={dummyEventData[id - 1]} />
      </Grid>
    </>
  );
}

export default DetailMyEvent;
