import styled from "@emotion/styled";
import { FormControl, FormLabel, TextField } from "@mui/material";
import React from "react";

function TextArea({
  label,
  name,
  type,
  InputProps,
  placeholder,
  otherProps,
  multiline,
  maxRows,
  rows,
  onChange,
}) {
  const InputText = styled(TextField)(() => ({
    "& .MuiInputBase-root": {
      borderRadius: 0,
    },
  }));
  return (
    <FormControl fullWidth sx={{ marginBottom: "1rem" }}>
      {label && (
        <FormLabel
          sx={{
            marginBottom: "0.5rem",
            color: "neutral.800",
            fontSize: "0.875rem",
            fontWeight: 500,
          }}
        >
          {label}
        </FormLabel>
      )}
      <InputText
        name={name}
        type={type}
        placeholder={placeholder}
        inputProps={{
          style: {
            fontSize: "0.875rem",
          },
        }}
        InputProps={InputProps}
        multiline={multiline}
        rows={rows}
        maxRows={maxRows}
        onChange={onChange}
        {...otherProps}
      />
    </FormControl>
  );
}

export default TextArea;
