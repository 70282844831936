import React, { useState } from "react";
import { Grid, Box, Button, MenuItem, Select, FormControlLabel, Checkbox } from "@mui/material";
import Bri from "../assets/bri.png";
import Bni from "../assets/bni.png";
import Bca from "../assets/bca.png";
import Next from "../assets/Selanjutnya.png";
import Mandiri from "../assets/Mandiri.png";
import Info from "../assets/Infom.png";
import Anim from "../assets/anima.png";

import Back from "../assets/Back.png";
import Header from "../components/headerEx";

import Fotter from "../components/Footer";
import TabsEvent from "../components/tabsEvent";
import { Icon } from "@iconify/react";
import { height } from "@mui/system";
import Time from "../components/time";
import { useNavigate } from "react-router-dom";


const About = () => {
    const navigate = useNavigate();

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Header />
            <Time />
            
            <Grid item width={"148px"}
                height="42px"
                fontFamily={"PLus Jakarta Sans"}
                fontSize="32px"
                fontWeight={"700"}
                lineHeight="130%"
              marginTop={"50px"}
                marginLeft={"150px"}
                letterSpacing={"0.25px"}
                color="#1F1F1F">
                My Order
            </Grid>
            <Grid item
                boxSizing={"border-box"}
                display={"flex"}
                flexDirection="column"
                justifyContent={"center"}
                alignItems="flex-start"
                padding={"16px"}
                gap="16px"
                width={"1040px"}
                height="366px"
                marginLeft="150px"
                marginTop={"10px"}


                border={"1px solid #E0E0E0"}>
                <Grid item width={"100px"}
                    height="36px"
                    fontFamily={"PLus Jakarta Sans"}
                    fontSize="20px"
                    fontWeight={"700"}
                    lineHeight="150%"


                    letterSpacing={"0.25px"}
                    color="#1F1F1F">
                    To do
                </Grid>
                <Grid item
                    boxSizing={"border-box"}
                    display={"flex"}
                    flexDirection="column"
                    justifyContent={"center"}
                    alignItems="flex-start"
                    padding={"16px"}
                    gap="16px"
                    width={"1008px"}

                    marginTop={"10px"}

                    height="366px"
                    border={"1px solid #E0E0E0"}>

                    <Grid item width={"177px"}
                        height="36px"
                        fontFamily={"PLus Jakarta Sans"}
                        fontSize="16px"
                        fontWeight={"600"}
                        lineHeight="150%"


                        letterSpacing={"0.25px"}
                        color="#1F1F1F">
                        Order ID : 12651257126
                    </Grid>
                    <Grid container>
                        <img src={Anim} alt="" />
                        <Grid item color={"black"} fontWeight={"700"} fontSize="16px" marginLeft={"10px"} fontFamily='Plus Jakarta Sans'>

                            Animakini X Ciffest 2023
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item paddingTop={"0.2rem"} marginRight="0.5rem"    >
                            <Icon icon="material-symbols:date-range" />
                        </Grid>
                        <Grid item>20-22 Februari 2023</Grid>




                    </Grid>
                    <Grid container>
                        <Grid item
                            display={"flex"}
                            flexDirection="row"
                            alignItems={"flex-start"}
                            padding="6px 12px"
                            width={"190px"}
                            height="32px"
                            backgroundColor="#FFF1EB">
                            <Grid item
                                width={"166px"}
                                height="20px"
                                fontFamily={"PLus Jakarta Sans"}
                                fontSize="14px"
                                fontWeight={"700"}
                                lineHeight="140%"
                                // marginTop={"250px"}
                                // marginLeft={"200px"}
                                letterSpacing={"0.25px"}
                                color="#D14F1E">

                                Menunggu Pembayaran
                            </Grid>
                        </Grid>
                        <Button

                            variant="outlined"
                            style={{
                                borderRadius: "0",
                                backgroundColor: 'white',
                                marginLeft: '650px',

                            }}
                            onClick={() => navigate("/plan-booking")}


                        >
                            <Grid itam color='#D14F1E'>Lihat Detail</Grid>
                        </Button>
                    </Grid>
                </Grid>


            </Grid>





        </>
    );
};

export default About;
