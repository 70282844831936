import { request } from "../utils/request";
import { URL } from "../utils/constant";

const getNation = () => {
  return request(`${URL}/get_nation`, {
    method: "GET",
  });
};
const getProvince = () => {
  return request(`${URL}/region/get_province`, {
    method: "GET",
  });
};

const getRegency = ({ code }) => {
  return request(`${URL}/region/get_regency`, {
    method: "GET",
    params: {
      code,
    },
  });
};

const getDistrict = ({ code }) => {
  return request(`${URL}/region/get_district`, {
    method: "GET",
    params: {
      code,
    },
  });
};

const getVillage = ({ code }) => {
  return request(`${URL}/region/get_village`, {
    method: "GET",
    params: {
      code,
    },
  });
};

const RegionServices = {
  getNation,
  getProvince,
  getRegency,
  getDistrict,
  getVillage,
};

export default RegionServices;
