import React from "react";
import { Icon } from "@iconify/react";
import { ButtonBase, Typography } from "@mui/material";

function BackButton({ onClick }) {
  return (
    <ButtonBase
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0.75rem",
        width: "fit-content",
      }}
      onClick={onClick}
    >
      <Icon
        icon="mdi:arrow-left"
        color="#1F1F1F"
        height="1.2rem"
        width="1.2rem"
      />
      <Typography color="neutral.800" fontSize="0.875rem" fontWeight="500">
        Kembali
      </Typography>
    </ButtonBase>
  );
}

export default BackButton;
