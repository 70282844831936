import React from "react";
import { Grid } from "@mui/material";
// import { Calendar } from 'react-date-range';
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { Calendar } from "react-date-range";
import { filterLabel } from "../utils/filterLabel";
import Filter from "./Exhibitions/Filter";

const Home = () => {
  return (
    <>
      <Grid display={"flex"} direction="column" gap={2}>
        <Grid
          item
          display={"flex"}
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
          gap={3}
        >
          <Grid item display={"flex"} direction="column" gap={2} xs={6}>
            <Grid item fontWeight="500" fontSize="14px">
              Filter by date
            </Grid>
            <Grid item>
              <Calendar />
            </Grid>

            <Grid
              item
              display={"flex"}
              flexDirection="column"
              alignItems={"flex-start"}
              width={"350px"}
              height="5px"
              borderBottom={"1px solid #E0E0E0 "}
            />

            {filterLabel?.map((item) => (
              <Filter props={item} key={item.key} />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
