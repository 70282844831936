import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
  formEventData: {
    event_organizer_name: "",
    email: "",
    phone: "",
    provinsi_id: "",
    kota_id: "",
    kec_id: "",
    kode_pos_id: "",
    address: "",
    start_date: "",
    end_date: "",
    loc_id: "1",
    event_name: "",
    event_type: "",
    event_detail: "",
    total_visitor: "",
    file: "",
    facility: [
      {
        key: "",
      },
    ],
  },
  page: 0,
};

export const formEventSlice = createSlice({
  name: "formEvent",
  initialState,
  reducers: {
    setFormEventData: (state, action) => {
      if (action?.payload?.name) {
        let name = action.payload.name;
        let value = action.payload.value;
        state.formEventData[name] = value;
      } else {
        state.formEventData = action.payload;
      }
    },

    setFile: (state, action) => {
      state.formEventData.file = action.payload;
    },
    setDateRange: (state, action) => {
      state.formEventData.start_date = dayjs(action.payload.startDate).format(
        "YYYY-MM-DD"
      );
      state.formEventData.end_date = dayjs(action.payload.endDate).format(
        "YYYY-MM-DD"
      );
    },
    addFacility: (state, action) => {
      state.formEventData.facility = state.formEventData.facility.concat(
        action.payload
      );
    },
    deleteFacility: (state, action) => {
      let data = state.formEventData.facility;
      delete data[action.payload];
      state.formEventData.facility = data.filter((item) => item !== undefined);
    },
    handleNextPage: (state) => {
      state.page += 1;
    },
    handlePreviousPage: (state) => {
      state.page -= 1;
    },
    handleChangeFacility: (state, action) => {
      state.formEventData.facility[action.payload.index] = action.payload.value;
    },
  },
});

export const formEventState = (state) => state.formEvent;

export const {
  setFormEventData,
  addFacility,
  deleteFacility,
  handleNextPage,
  handlePreviousPage,
  setFile,
  setDateRange,
  handleChangeFacility,
} = formEventSlice.actions;

export default formEventSlice.reducer;
