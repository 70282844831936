import React from "react";
import { Grid, Box } from "@mui/material";

import News1 from "../assets/News1.JPG";
import News2 from "../assets/News2.JPG";
import News3 from "../assets/News3.JPG";

const LatestNews = () => {
  return (
    <>
      <Grid
        item
        display={"flex"}
        direction="column"
        borderTop={1}
        padding="64px 10rem"
        sx={{ borderColor: "#E0E0E0" }}
        gap={4}
      >
        <Grid item>
          <Grid
            item
            display={"flex"}
            direction="row"
            justifyContent={"flex-start"}
            gap={1}
          >
            <Grid item color="#D14F1E" fontWeight="700" fontSize="20px">
              Latest News
            </Grid>
            <Grid
              item
              height="1px"
              backgroundColor="#FF723D"
              width={"3rem"}
              marginTop="0.9rem"
            ></Grid>
          </Grid>
        </Grid>
        <Grid item display={"flex"} direction="column" gap={2}>
          <Grid item display={"flex"} direction="row" gap={2}>
            <Grid item>
              <Box
                sx={{
                  width: "250px",
                  height: "215px",
                  backgroundImage: `url(${News1})`,
                  backgroundSize: "cover",
                }}
              ></Box>
            </Grid>
            <Grid item display={"flex"} direction="column">
              <Grid item fontWeight="700" fontSize="20px">
                Potret Kompaknya Panglima Saat Main Wayang Bareng Kapolri di TIM
              </Grid>
              <Grid item color="#D14F1E" fontWeight="500" fontSize="14px">
                Potret Kompaknya Panglima Saat Main Wayang Bareng Kapolri di TIM
              </Grid>
              <Grid item fontWeight="500" fontSize="16px">
                Jakarta - Panglima TNI Laksamana Yudo Margono dan Kapolri
                Jenderal Listyo Sigit melakoni tokoh wayang orang dalam
                pergelaran di Teater Jakarta, TIM. Mereka tampak
                kompak.PreviousNextMereka terlihat kompak dengan berdandan tokoh
                wayang orang bertajuk, " Pandawa Boyong" saat tampil di atas
                panggung teater. Acara tersebut digelar di gedung teater Taman
                Ismail Marzuki, Jakarta Pusat, Minggu (15/01).
              </Grid>
            </Grid>
          </Grid>
          <Grid item display={"flex"} direction="row" gap={2}>
            <Grid item>
              <Box
                sx={{
                  width: "250px",
                  height: "215px",
                  backgroundImage: `url(${News2})`,
                  backgroundSize: "cover",
                }}
              ></Box>
            </Grid>
            <Grid item display={"flex"} direction="column">
              <Grid item fontWeight="700" fontSize="20px">
                Potret Kompaknya Panglima Saat Main Wayang Bareng Kapolri di TIM
              </Grid>
              <Grid item color="#D14F1E" fontWeight="500" fontSize="14px">
                Potret Kompaknya Panglima Saat Main Wayang Bareng Kapolri di TIM
              </Grid>
              <Grid item fontWeight="500" fontSize="16px">
                Jakarta - Panglima TNI Laksamana Yudo Margono dan Kapolri
                Jenderal Listyo Sigit melakoni tokoh wayang orang dalam
                pergelaran di Teater Jakarta, TIM. Mereka tampak
                kompak.PreviousNextMereka terlihat kompak dengan berdandan tokoh
                wayang orang bertajuk, " Pandawa Boyong" saat tampil di atas
                panggung teater. Acara tersebut digelar di gedung teater Taman
                Ismail Marzuki, Jakarta Pusat, Minggu (15/01).
              </Grid>
            </Grid>
          </Grid>
          <Grid item display={"flex"} direction="row" gap={2}>
            <Grid item>
              <Box
                sx={{
                  width: "250px",
                  height: "215px",
                  backgroundImage: `url(${News3})`,
                  backgroundSize: "cover",
                }}
              ></Box>
            </Grid>
            <Grid item display={"flex"} direction="column">
              <Grid item fontWeight="700" fontSize="20px">
                Potret Kompaknya Panglima Saat Main Wayang Bareng Kapolri di TIM
              </Grid>
              <Grid item color="#D14F1E" fontWeight="500" fontSize="14px">
                Potret Kompaknya Panglima Saat Main Wayang Bareng Kapolri di TIM
              </Grid>
              <Grid item fontWeight="500" fontSize="16px">
                Jakarta - Panglima TNI Laksamana Yudo Margono dan Kapolri
                Jenderal Listyo Sigit melakoni tokoh wayang orang dalam
                pergelaran di Teater Jakarta, TIM. Mereka tampak
                kompak.PreviousNextMereka terlihat kompak dengan berdandan tokoh
                wayang orang bertajuk, " Pandawa Boyong" saat tampil di atas
                panggung teater. Acara tersebut digelar di gedung teater Taman
                Ismail Marzuki, Jakarta Pusat, Minggu (15/01).
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LatestNews;
