import { request } from "../utils/request";
import { URL } from "../utils/constant";

const loginUser = (userData) => {
  return request(`${URL}/login/membership`, {
    method: "POST",
    data: userData,
  });
};

const MembershipService = {
  loginUser,
};

export default MembershipService;
