import { Outlet } from "react-router-dom";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

// component
import Footer from "../Footer";
import MainNavbar from "../MainNavbar";
import UserSidebar from "./UserSidebar";
import UserNotice from "../UserNotice";

// redux
import { userState } from "../../redux/feature/userSlice";

export default function UserLayout() {
  const { userData } = useSelector(userState);
  return (
    <>
      <MainNavbar />
      {userData?.role === "pv" && <UserNotice />}
      <Grid container>
        <Grid
          item
          xs={11}
          sm={10}
          md={9}
          display="flex"
          direction="row"
          sx={{
            margin: "auto",
            marginTop: "4rem",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <UserSidebar />
            </Grid>
            <Grid item xs={12} md={9}>
              <Outlet />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
