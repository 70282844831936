import { Box, FormLabel, Paper, Typography } from "@mui/material";
import React from "react";
import Headline from "../../components/headline";
import { Icon } from "@iconify/react";
import AdminInput from "../../components/Elements/AdminInput";
import MainButton from "../../components/Elements/MainButton";
import { useNavigate } from "react-router-dom";
import DatePicker from "../../components/Elements/DatePicker";
import MainStepper from "../../components/Elements/MainStepper";

const ApprovalRentDetail = () => {
  const navigate = useNavigate();

  const headline = (
    <>
      <Box display={"flex"} direction={"row"} alignItems={"center"} gap={2}>
        <Icon
          icon="mdi:clipboard-text-outline"
          color={"#1F1F1F"}
          width="2rem"
          height="2rem"
        />
        <Typography sx={{ fontSize: "2rem", fontWeight: "700" }}>
          Detail Sewa
        </Typography>
      </Box>
    </>
  );
  return (
    <>
      <Paper sx={{ padding: "1.5rem", borderRadius: 0 }}>
        <Box sx={{ width: "9.25rem", marginBottom: "2.25rem" }}>
          <MainStepper activeStep={1} />
        </Box>
        <Headline title={headline} />
        <Typography
          sx={{ fontSize: "1.5rem", fontWeight: 700, marginTop: "2.25rem" }}
        >
          Durasi Sewa
        </Typography>
        <form
          style={{
            marginTop: "2.25rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "2rem",
              marginBottom: "2rem",
            }}
          >
            <Box
              sx={{
                minWidth: "19.688rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormLabel
                sx={{
                  marginBottom: "0.5rem",
                  color: "neutral.800",
                  fontSize: "1.25rem",
                  fontWeight: 700,
                }}
              >
                <span style={{ color: "#B43127", fontWeight: "bold" }}>*</span>
                Tanggal Mulai
              </FormLabel>
              <DatePicker
                disabled
                required
                otherProps={{ value: "11/03/2023" }}
              />
            </Box>
            <Box
              sx={{
                minWidth: "19.688rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormLabel
                sx={{
                  marginBottom: "0.5rem",
                  color: "neutral.800",
                  fontSize: "1.25rem",
                  fontWeight: 700,
                }}
              >
                <span style={{ color: "#B43127", fontWeight: "bold" }}>*</span>
                Tanggal Selesai
              </FormLabel>
              <DatePicker
                disabled
                required
                otherProps={{ value: "12/03/2023" }}
              />
            </Box>
          </Box>
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: 700,
              marginBottom: "2.25rem",
            }}
          >
            Gedung/Tempat
          </Typography>
          <AdminInput
            label="Tempat/Lokasi"
            placeholder="Tempat/Lokasi"
            required
            disabled
            otherProps={{ value: "Aula Utama Gedung Ismail Marzuki" }}
            sx={{ maxWidth: "19.688rem" }}
          />
          <AdminInput
            label="Fasilitas"
            placeholder="Fasilitas"
            required
            disabled
            otherProps={{ value: "Sound System" }}
            sx={{ maxWidth: "19.688rem" }}
          />
          <AdminInput
            placeholder="kursi"
            required
            disabled
            otherProps={{ value: "300 Kursi" }}
            sx={{ maxWidth: "19.688rem" }}
          />
          <AdminInput
            placeholder="Proyektor"
            required
            disabled
            otherProps={{ value: "Proyektor" }}
            sx={{ maxWidth: "19.688rem" }}
          />

          <Box sx={{ display: "flex", alignItems: "center", gap: "2rem" }}>
            <MainButton
              variant="outlined"
              sx={{ border: "primary.500", color: "primary.500" }}
              onClick={() => navigate(-1)}
            >
              Kembali
            </MainButton>
            <MainButton
              variant="contained"
              sx={{ bgcolor: "primary.500", mr: "0.5rem" }}
              onClick={() => navigate("/admin-event/approval/event-detail")}
            >
              Selanjutnya
            </MainButton>
          </Box>
        </form>
      </Paper>
    </>
  );
};

export default ApprovalRentDetail;
