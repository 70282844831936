import React, { useState } from "react";

import TabsHome from "../../components/tabsHome";

// data component
import ProposalEventBase from "./ProposalEventBase";
import PostEventBase from "./PostEventBase";
import TicketEventsBase from "./TicketEventsBase";
import { adminHomeLabel } from "../../utils/tabsLabel";

const TableEvent = ({ setSection }) => {
  const [value, setValue] = useState("Proposal Event");

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <TabsHome
        value={value}
        handleChange={handleChangeTabs}
        label={adminHomeLabel}
      />
      {value === "Proposal Event" ? (
        <ProposalEventBase setSection={setSection} />
      ) : value === "Post Event" ? (
        <PostEventBase />
      ) : (
        <TicketEventsBase />
      )}
    </>
  );
};

export default TableEvent;
