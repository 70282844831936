import { Box, Grid } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

// import ErrorIcon from "../../assets/Gagal.png";
import { modalState, setError } from "../../redux/feature/modalSlice";
import CustomModal from "./CustomModal";

const Error = ({ toggle, errorMessages, messages, text }) => {
  let errorMsg = errorMessages ? Object.values(errorMessages) : null;
  const { isError, message } = useSelector(modalState);
  const dispatch = useDispatch();

  setTimeout(() => {
    dispatch(setError(false));
  }, 5000);

  clearTimeout();

  return (
    <>
      <CustomModal isShowing={isError} toggle={toggle} width="421px">
        <Grid
          container
          sx={{
            backgroundColor: "white",
            borderRadius: "4px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "59px 10px",
          }}
        >
          <Grid item>
            {/* <img src={ErrorIcon} alt="" /> */}
          </Grid>
          <Grid item fontWeight={"700"} fontSize="20px">
            GAGAL
          </Grid>
          <Box
            className="message-truncate"
            sx={{ width: "320px", wordWrap: "break-word" }}
          >
            <p>{message}</p>
          </Box>
        </Grid>
      </CustomModal>
    </>
  );
};

export default Error;
