import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setError,
  setLoading,
  setSuccessed,
} from "../../redux/feature/modalSlice";
import { setShowing } from "../../redux/feature/loginAlertSlice";
import { Grid, InputAdornment, IconButton } from "@mui/material";
import { Icon } from "@iconify/react";
import { loginLabel } from "../../utils/tabsLabel";
import Tabs from "../../components/Tabs/TabsLoginUser";
import Title from "../../components/LoginUser/Title";
import RememberMe from "../../components/LoginUser/RememberMe";
import Buttons from "../../components/LoginUser/Buttons";
import MainInput from "../../components/Elements/MainInput";

// services
import MembershipServices from "../../services/MembershipServices";

const Login = ({ setSection, value, setValue, section, setLoginTitle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const [addFormData, setAddFormData] = useState({
    email: "",
    password: "",
  });

  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };

  const [values, setValues] = useState({
    showPassword: false,
    showPasswordVerification: false,
  });
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    MembershipServices.loginUser(addFormData)
      .then((res) => {
        dispatch(setLoading(false));
        localStorage.setItem("user-tim", JSON.stringify(res.data.data));
        dispatch(setSuccessed(true));
        dispatch(setShowing(false));
        navigate(-1);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setError({
            value: true,
            message: "Mohon periksa kembali nama pengguna dan password",
          })
        );
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container direction={"column"} gap={1} padding="0 7.5rem">
          <Title value={value} section={section} />

          <Tabs
            value={value}
            handleChangeTab={handleChangeTab}
            loginLabel={loginLabel}
          />

          <MainInput
            required
            name={"email"}
            label="Email"
            placeholder={"Masukan Email"}
            onChange={handleAddFormChange}
          />
          <MainInput
            required
            type={values.showPassword ? "text" : "password"}
            name={"password"}
            label="Password"
            placeholder={"Masukan Password"}
            onChange={handleAddFormChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? (
                      <Icon
                        icon="material-symbols:visibility-outline"
                        color="#828282"
                        width="20"
                        height="20"
                      />
                    ) : (
                      <Icon
                        icon="ic:outline-visibility-off"
                        color="#828282"
                        width="20"
                        height="20"
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <RememberMe handleForget={() => navigate("/forget-pass")} />

          <Buttons
            type={"submit"}
            setSection={setSection}
            section={section}
            setLoginTitle={setLoginTitle}
          />
        </Grid>
      </form>
    </>
  );
};

export default Login;
