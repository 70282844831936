import React, { useState } from "react";

import BackButton from "../../components/LoginUser/BackButton";
import { Grid } from "@mui/material";
import ChangePass from "../../components/LoginUser/ChangePass";
import EmailVerification from "../../components/LoginUser/CheckEmail";
import NewPassword from "../../components/LoginUser/NewPassword";

const ForgetPass = () => {
  const [section, setSection] = useState("");

  return (
    <>
      <Grid
        container
        display={"flex"}
        direction="column"
        height={"100vh"}
        alignitems={"center"}
        backgroundColor="#FFFFFF"
      >
        <BackButton />

        {section === "" ? (
          <ChangePass setSection={setSection} />
        ) : section === "checkEmail" ? (
          <EmailVerification setSection={setSection} />
        ) : (
          <NewPassword />
        )}
      </Grid>
    </>
  );
};

export default ForgetPass;
