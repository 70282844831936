import Instagram from "../assets/instagram.png";
import Fb from "../assets/fb.png";
import Tk from "../assets/tk.png";
import Twitter from "../assets/twitter.png";

// bank image
import Mandiri from "../assets/Mandiri.png";
import Bca from "../assets/bca.png";
import Bni from "../assets/bni.png";
import Bri from "../assets/bri.png";

const ROOT_API = process.env.REACT_APP_API_BASE_URL;
export const URL = `${ROOT_API}`;

const navItems = [
  {
    title: "Plan Your Visit",
    subMenu: [
      { title: "Submit Your Event", path: "/submit-event" },
      { title: "Admission", path: null },
      { title: "Getting Here", path: "/getting-here" },
      { title: "Venue Map", path: null },
      { title: "Shop", path: null },
    ],
  },
  {
    title: "Exhibition & Event",
    subMenu: [
      { title: "Now Showing", path: "/exhibition-event" },
      { title: "Incoming", path: "/exhibition-all" },
      { title: "Past", path: "/exhibition-view" },
      { title: "My Booking", path: "/mybooking", role: "pv" },
    ],
  },
  { title: "Collection" },
  {
    title: "Join & Support",
    subMenu: [
      { title: "Membership", path: null },
      { title: "Campaign", path: null },
      { title: "Donate", path: null },
    ],
  },
];

const mainFooterLink = [
  { title: "About", path: "/about" },
  { title: "News", path: "/news" },
  { title: "Contact", path: "/contact" },
  { title: "Legal", path: null },
];

const secondaryFooterLink = [
  { title: "Privacy Policy", path: null },
  { title: "Terms of Use", path: null },
  { title: "Sales and Refunds", path: null },
  { title: "Legal", path: null },
];

const socialFooterLink = [
  { image: Instagram, link: null },
  { image: Fb, link: null },
  { image: Tk, link: null },
  { image: Twitter, link: null },
];

const bankList = [
  {
    image: Mandiri,
    title: "Bank Mandiri",
  },
  {
    image: Bca,
    title: "Bank BCA",
  },
  {
    image: Bni,
    title: "Bank BNI",
  },
  {
    image: Bri,
    title: "Bank BRI",
  },
];

export {
  navItems,
  mainFooterLink,
  socialFooterLink,
  secondaryFooterLink,
  bankList,
};
