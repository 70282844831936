import React from "react";
import { Box, Grid } from "@mui/material";

import MainButton from "../../Elements/MainButton";

const Approve = ({ setReject, approve }) => {
  return (
    <>
      <form onSubmit={approve}>
        <Grid item fontWeight={"700"} fontSize="20px" color={"#FF723D"}>
          Apakah Anda menyetujui event ini?
        </Grid>
        <Box
          display={"flex"}
          justifyContent={"center"}
          gap={4}
          marginTop="1rem"
        >
          <MainButton variant={"outlined"} onClick={() => setReject(true)}>
            Tolak
          </MainButton>
          <MainButton variant={"contained"} type={"submit"}>
            Setuju
          </MainButton>
        </Box>
      </form>
    </>
  );
};

export default Approve;
