import { Icon } from "@iconify/react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Breadcrumbs,
  ButtonBase,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

// asset
import Back from "../assets/Back.png";

// component
import DatePicker from "../components/Elements/DatePicker";
import MainButton from "../components/Elements/MainButton";
import MainInput from "../components/Elements/MainInput";
import MainNavbar from "../components/MainNavbar";

const DetailBooking = () => {
  const navigate = useNavigate();

  const breadcrumbs = [
    <Typography
      key="1"
      color="text.primary"
      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
    >
      1. Booking
    </Typography>,
    <Typography
      key="2"
      color="inherit"
      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
    >
      2. Pesan
    </Typography>,
    <Typography
      key="3"
      color="inherit"
      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
    >
      3. Bayar
    </Typography>,
    <Typography
      key="4"
      color="inherit"
      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
    >
      4. Selesai
    </Typography>,
  ];

  return (
    <Box bgcolor="background.paper" minHeight="100vh" paddingBottom="4rem">
      <MainNavbar />
      <Grid container>
        <Grid
          item
          xs={11}
          sm={10}
          md={9}
          sx={{
            margin: "auto",
            marginTop: "4rem",
          }}
        >
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "5rem",
            }}
          >
            {breadcrumbs}
          </Breadcrumbs>
          <ButtonBase
            sx={{ display: "flex", gap: "0.875rem " }}
            onClick={() => navigate(-1)}
          >
            <div>
              <img src={Back} alt="" />
            </div>
            <Typography fontSize="2rem" fontWeight="700">
              Detail Booking
            </Typography>
          </ButtonBase>
          <Divider
            sx={{
              width: "100%",
              bgcolor: "neutral.400",
              marginTop: "1rem",
              marginBottom: "2rem",
            }}
            orientation="horizontal"
          />
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Box sx={{ bgcolor: "primary.100", padding: "1rem" }}>
                <Typography fontSize="1.5rem" fontWeight="700">
                  Paket Terpilih
                </Typography>
                <Box
                  marginTop="1rem"
                  padding="1rem"
                  width="100%"
                  backgroundColor="neutral.100"
                  border="1px solid"
                  borderColor="primary.600"
                >
                  <Typography fontSize="1.25rem" fontWeight="700">
                    VIP
                  </Typography>
                  <Typography
                    fontSize="1.5rem"
                    fontWeight="700"
                    color="primary.600"
                  >
                    IDR 500.000
                  </Typography>
                  <Divider
                    sx={{
                      width: "100%",
                      borderBottom: "1px dashed",
                      borderBottomColor: "neutral.600",
                      marginY: "1rem",
                    }}
                    orientation="horizontal"
                  />
                  <ul
                    style={{
                      margin: 0,
                      paddingLeft: "1rem",
                      fontSize: "0.875rem",
                      color: "#828282",
                    }}
                  >
                    <li>Tidak bisa Refund</li>
                    <li>Pembayaran manual</li>
                  </ul>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
            >
              <Box
                padding="1rem"
                boxSizing="border-box"
                width="100%"
                border="1px solid"
                borderColor="neutral.400"
              >
                <DatePicker
                  label="Tanggal"
                  otherProps={{ defaultValue: "18 April 2023" }}
                />
              </Box>
              <Box
                padding="1rem"
                boxSizing="border-box"
                width="100%"
                border="1px solid"
                borderColor="neutral.400"
              >
                <MainInput
                  label="Jumlah Tiket"
                  otherProps={{ value: "" }}
                  InputProps={{
                    startAdornment: (
                      <Typography variant="body2" fontWeight="700">
                        Pax
                      </Typography>
                    ),
                    endAdornment: (
                      <Box
                        sx={{
                          display: "flex",
                          minWidth: "10rem",
                          gap: "1rem",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="body2"
                          fontWeight="700"
                          color="primary.600"
                        >
                          IDR 500.000
                        </Typography>
                        <Box sx={{ display: "flex", gap: "0.563rem" }}>
                          <ButtonBase width="1rem" height="1rem" padding={0}>
                            <Icon
                              icon="mdi:minus-circle-outline"
                              width="1rem"
                              height="1rem"
                              color="#D14F1E"
                            />
                          </ButtonBase>
                          <Typography variant="caption" fontWeight="700">
                            1
                          </Typography>
                          <ButtonBase width="1rem" height="1rem" padding={0}>
                            <Icon
                              icon="material-symbols:add-circle-outline"
                              width="1rem"
                              height="1rem"
                              color="#D14F1E"
                            />
                          </ButtonBase>
                        </Box>
                      </Box>
                    ),
                  }}
                />
              </Box>
              <Box
                padding="1rem"
                boxSizing="border-box"
                width="100%"
                border="1px solid"
                borderColor="neutral.400"
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="body2">Tanggal</Typography>
                  <Typography variant="body2" fontWeight="700">
                    20 Februari 2023
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    width: "100%",
                    borderBottom: "1px dashed",
                    borderBottomColor: "neutral.600",
                    marginTop: "1.125rem",
                    marginBottom: "1rem",
                  }}
                  orientation="horizontal"
                />
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography variant="body2">Total Harga</Typography>
                    <Typography
                      variant="body2"
                      fontWeight="700"
                      color="primary.600"
                    >
                      IDR 500.000
                    </Typography>
                  </Box>
                  <MainButton
                    variant="contained"
                    onClick={() => navigate("/detail-pemasanan")}
                  >
                    Pesan
                  </MainButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailBooking;
