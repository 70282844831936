import React, { useState } from "react";
import { Grid } from "@mui/material";
import { Icon } from "@iconify/react";

import Headline from "../../components/headline";
import SearchBar from "../../components/Searchbar";
import Table from "../../components/TableProposal";
import Footer from "../../components/foter";
import { tableHeadProposal } from "../../utils/tableHead";

const PostEvent = ({ title, data }) => {
  const [limit, setLimit] = useState(10);
  const [status, setStatus] = useState("");
  const onChangeLimit = (e) => {
    setLimit(e.target.value);
  };

  const onChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  const headline = (
    <>
      <Grid
        container
        display={"flex"}
        direction={"row"}
        alignItems={"center"}
        gap={2}
      >
        <Grid item paddingTop={"0.3rem"}>
          <Icon
            icon="mdi:clipboard-text-outline"
            color={"#1F1F1F"}
            width="20"
            height="20"
          />
        </Grid>
        <Grid item>List Event</Grid>
      </Grid>
    </>
  );

  return (
    <>
      <Grid container backgroundColor="white" padding="1.5rem" gap={2}>
        <Headline title={headline} />
        <SearchBar
          limit={limit}
          status={status}
          onChangeLimit={onChangeLimit}
          onChangeStatus={onChangeStatus}
        />
        <Table header={tableHeadProposal} title={title} data={data} />
        <Footer />
      </Grid>
    </>
  );
};

export default PostEvent;
