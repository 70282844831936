import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import {
  Avatar,
  Box,
  Grid,
  Typography,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

//components
import "../../styles/app.css";

// utils
import useAuth from "../../hooks/useAuth";

// redux
import { userState } from "../../redux/feature/userSlice";

const UserSidebar = () => {
  const { userData } = useAuth();
  const location = useLocation();

  const handleLogout = () => {
    localStorage.removeItem("user-tim");
    navigate("/");
    // toast.success("Logout berhasil !");
  };

  const navigate = useNavigate();

  return (
    <>
      <Grid
        container
        display={"flex"}
        direction="row"
        sx={{
          bgcolor: "background.paper",
          height: "min-content",
        }}
      >
        <Grid item xs={12} padding={"1.5rem"} marginBottom={"1.25rem"}>
          <Box>
            <Avatar
              sx={{
                bgcolor: "primary.500",
                border: "4px solid",
                borderColor: "neutral.400",
                width: "4rem",
                height: "4rem",
                fontSize: "2rem",
                marginBottom: "0.625rem",
              }}
            >
              {userData?.name?.substring(0, 1).toUpperCase()}
            </Avatar>
          </Box>
          <Typography
            fontSize={"1.5rem"}
            fontWeight={700}
            color={"primary.500"}
          >
            {userData?.name}
          </Typography>
          <Typography variant="caption">
            {userData?.role === 1 ? "Public Visitor" : "Event Organization"}
          </Typography>
        </Grid>
        <List
          sx={{ width: "100%" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton
            selected={location?.pathname?.includes("profile")}
            onClick={() => navigate("/profile")}
          >
            <ListItemIcon>
              <Icon icon="mdi:user-circle-outline" width="20px" height="20px" />
            </ListItemIcon>
            <ListItemText disableTypography>
              <Typography variant="body2">Akun</Typography>
            </ListItemText>
          </ListItemButton>
          {userData.role === "pv" ? (
            <ListItemButton
              selected={location?.pathname?.includes("mybooking")}
              onClick={() => navigate("/mybooking")}
            >
              <ListItemIcon>
                <Icon icon="carbon:ticket" width="20px" height="20px" />
              </ListItemIcon>
              <ListItemText disableTypography>
                <Typography variant="body2">My Booking</Typography>
              </ListItemText>
            </ListItemButton>
          ) : (
            <ListItemButton
              selected={location?.pathname?.includes("myevent")}
              onClick={() => navigate("/myevent")}
            >
              <ListItemIcon>
                <Icon
                  icon="mdi:text-box-check-outline"
                  width="20px"
                  height="20px"
                />
              </ListItemIcon>
              <ListItemText disableTypography>
                <Typography variant="body2">My Event</Typography>
              </ListItemText>
            </ListItemButton>
          )}
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <Icon icon="solar:logout-outline" width="20" height="20" />
            </ListItemIcon>
            <ListItemText disableTypography>
              <Typography variant="body2">Logout</Typography>
            </ListItemText>
          </ListItemButton>
        </List>
      </Grid>
    </>
  );
};

export default UserSidebar;
