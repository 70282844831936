import React from "react";
import { Icon } from "@iconify/react";
import { Box, IconButton, Typography } from "@mui/material";
import QRCode from "react-qr-code";

// component
import CustomModal from "../CustomModal/CustomModal";

function QRCodeModal({ isShowing, toggle, value, category }) {
  return (
    <>
      <CustomModal
        isShowing={isShowing}
        toggle={toggle}
        style={{
          paddingY: "2.25rem",
          paddingX: "4.188rem",
          width: { xs: "90%", sm: "23rem" },
        }}
      >
        <IconButton
          onClick={toggle}
          sx={{
            position: "absolute",
            color: "inherit",
            top: 10,
            right: 10,
          }}
        >
          <Icon
            icon="material-symbols:close-rounded"
            width="1.5rem"
            height="1.5rem"
          />
        </IconButton>
        <Box
          sx={{ width: "100%", textAlign: "center", marginBottom: "1.5rem" }}
        >
          <Typography
            sx={{ color: "neutral.600", fontWeight: 500, fontSize: "0.875rem" }}
          >
            Kode Booking
          </Typography>
          <Typography
            sx={{ color: "neutral.800", fontWeight: 700, fontSize: "1.5rem" }}
          >
            {value}
          </Typography>
        </Box>
        <Box
          sx={{ width: "100%", textAlign: "center", marginBottom: "1.5rem" }}
        >
          <Typography
            sx={{ color: "neutral.600", fontWeight: 500, fontSize: "0.875rem" }}
          >
            Kategori
          </Typography>
          <Typography
            sx={{ color: "neutral.800", fontWeight: 700, fontSize: "1.5rem" }}
          >
            {category}
          </Typography>
        </Box>
        <QRCode
          value={value}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        />
      </CustomModal>
    </>
  );
}

export default QRCodeModal;
