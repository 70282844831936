import React from "react";
import { Button, Grid, Box } from "@mui/material";
import { Icon } from "@iconify/react";

import Krl from "../assets/krl.png";
import Busway from "../assets/busway.png";
import Mrt from "../assets/mrt.png";

const GettingHere = () => {
  return (
    <>
      <Grid display={"flex"} direction="column" padding="1rem 7rem" gap={2}>
        <Grid
          item
          display={"flex"}
          direction="row"
          alignItems={"center"}
          gap={2}
        >
          <Grid item paddingTop="0.1rem">
            <Icon
              icon="material-symbols:home-outline"
              color="#1f1f1f"
              width="16"
              height="16"
            />
          </Grid>
          <Grid item fontWeight="500" fontSize="16px">
            Home
          </Grid>
          <Grid item fontWeight="500" fontSize="16px">
            {">"}
          </Grid>
          <Grid item fontWeight="500" fontSize="16px">
            Plan Your Visit
          </Grid>
          <Grid item fontWeight="500" fontSize="16px">
            {">"}
          </Grid>
          <Grid item color={"#828282"} fontWeight="500" fontSize="16px">
            Getting Here
          </Grid>
        </Grid>
        <Grid
          item
          display={"flex"}
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
          gap={3}
        >
          {/* column 1 */}
          <Grid item display={"flex"} direction="column" gap={2} xs={4}>
            <Grid item fontWeight="700" fontSize="48px">
              Getting Here
            </Grid>
            <Grid item color="#828282" fontWeight="600" fontSize="16px">
              Jl. Cikini Raya No.73, RT.8/RW.2, Cikini, Kec. Menteng, Kota
              Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10330
            </Grid>
            <Grid item>
              <Button
                style={{
                  backgroundColor: "#D14F1E",
                  color: "white",
                  borderRadius: "0",
                  padding: "12px 24px",
                }}
              >
                Contact Us
              </Button>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  width: "333px",
                  height: "333px",
                  backgroundImage: `url(${Krl})`,
                  backgroundSize: "cover",
                }}
              ></Box>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  width: "333px",
                  height: "333px",
                  backgroundImage: `url(${Busway})`,
                  backgroundSize: "cover",
                }}
              ></Box>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  width: "333px",
                  height: "333px",
                  backgroundImage: `url(${Mrt})`,
                  backgroundSize: "cover",
                }}
              ></Box>
            </Grid>

            <Grid item backgroundColor="gray" height="30vh" xs={2}></Grid>
            <Grid item backgroundColor="gray" height="30vh" xs={2}></Grid>
          </Grid>
          {/* column 2 */}
          <Grid item display={"flex"} direction="column" gap={2} xs={7}>
            <Grid item>
              <Grid
                item
                sx={{
                  display: "inline-block",
                  backgroundColor: "#FFF1EB",
                  color: "#D14F1E",
                  padding: "12px 24px",
                  fontWeight: "700",
                  fontSize: "24px",
                }}
              >
                Cara ke TIM dengan KRL
              </Grid>
            </Grid>
            <Grid item paddingLeft={"1rem"} fontWeight="500" fontSize="16px">
              <li>Untuk menuju TIM, stasiun terdekat yaitu Stasiun Cikini.</li>
              <li>
                Bagi Anda yang datang dari Bekasi harus transit di Stasiun
                Manggarai lalu naik tujuan Jakarta Kota untuk turun di Stasiun
                Cikini.
              </li>
              <li>
                Bagi yang datang dari Bogor dan Depok bisa langsung naik kereta
                tujuan akhir Jakarta Kota lalu turun langsung di Stasiun Cikini.
              </li>
              <li>
                Bagi yang datang dari Tangerang maka harus transit di Stasiun
                Duri lalu naik kereta tujuan Stasiun Manggarai. Dari Stasiun
                Manggarai naik kereta tujuan Jakarta Kota untuk turun di Stasiun
                Cikini.
              </li>
              <li>
                Dari Stasiun Cikini bisa jalan kaki maupun menggunakan ojek dan
                transportasi online (Gojek, Grab) ke kawasan TIM. Jaraknya hanya
                terpaut 2 kilometer.
              </li>
            </Grid>
            <Grid item>
              <Grid
                item
                sx={{
                  backgroundColor: "#FFF1EB",
                  color: "#D14F1E",
                  padding: "12px 12px",
                  fontWeight: "700",
                  fontSize: "24px",
                }}
              >
                Cara ke TIM dengan Transjakarta
              </Grid>
            </Grid>
            <Grid item paddingLeft={"1rem"} fontWeight="500" fontSize="16px">
              <li>
                Kawasan TIM dilewati oleh feeder Transjakarta koridor 5H
                (Kampung Melayu-Tanah Abang via Cikini) dan koridor 6H
                (Senen-Lebak Bulus).
              </li>
              <li>
                ika datang dari Jakarta Timur bisa naik koridor 5H dan jika dari
                Jakarta Pusat naik koridor 6H. Jika datang dari Jakarta Barat
                naik Transjakarta 2B (Kalideres–Pulogadung).
              </li>
              <li>
                Transit di halte Transjakarta Senen, langsung naik ke jembatan
                di atas menuju ke halte yang di bawah flyover untuk naik koridor
                6H.
              </li>
              <li>
                Turun di halte bus pengumpan Transjakarta di Cikini, depan Ibis
                Budget Hotel lalu jalan kaki sedikit ke TIM.
              </li>
              <li>
                Jika datang dari Jakarta Utara naik dari halte Transjakarta
                Terminal Bus Tanjung Priok lalu turun di halte Pasar Senen.
              </li>
              <li>
                Lanjut menyeberang ke arah timur ke halte busway Pasar Senen
                untuk naik bus Transjakarta koridor 6H.
              </li>
              <li>
                Dari Jakarta Selatan naik bus koridor 9 (Pinang Ranti-Pluit)
                turun di halte busway Kuningan Barat. Pindah jalan menyeberang
                ke halte Kuningan Timur untuk naik bus TransJakarta koridor 6H.
              </li>
            </Grid>
            <Grid item>
              <Grid
                item
                sx={{
                  backgroundColor: "#FFF1EB",
                  color: "#D14F1E",
                  padding: "12px 12px",
                  fontWeight: "700",
                  fontSize: "24px",
                }}
              >
                Cara ke TIM dengan MRT
              </Grid>
            </Grid>
            <Grid item paddingLeft={"1rem"} fontWeight="500" fontSize="16px">
              <li>
                Stasiun terdekat dengan kawasan TIM yaitu Stasiun MRT Bundaran
                HI. Anda bisa naik MRT dari sepanjang stasiun pemberhentian
                Lebak Bulus hingga Bundaran HI.
              </li>
              <li>
                Dari arah Lebak Bulus, Fatmawati, Senayan, juga Sudirman, turun
                di stasiun MRT terakhir yakni Stasiun Bundaran HI.
              </li>
              <li>
                Dari Stasiun Bundaran HI ke TIM jaraknya hanya terpaut 3
                kilometer. Anda bisa menggunakan ojek atau transportasi online
                (Gojek, Grab) ke kawasan TIM.
              </li>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default GettingHere;
