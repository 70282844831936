import React, { useState } from "react";
import { Box, Collapse } from "@mui/material";
import EventHeader from "./EventHeader";
import ProposalEventDetail from "./ProposalEventDetail";
import { useNavigate } from "react-router-dom";

function ProposalEvent({ data, id }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          padding: "1rem",
          borderRadius: 0,
          width: "100%",
          bgcolor: "background.paper",
        }}
      >
        <EventHeader
          open={open}
          setOpen={setOpen}
          title="Proposal Event"
          status={data?.status_event}
          feedback={data?.reject_reason}
          // isEdit={data?.statusProposal !== "Disetujui"}
          onEdit={() => navigate(`/edit-event/${id}`)}
          isEdit={data?.status_event === "reject-plan-visit"}
        />
        <Collapse
          in={open && data.statusProposal !== "Disetujui"}
          timeout="auto"
          unmountOnExit
        >
          <ProposalEventDetail data={data} />
        </Collapse>
      </Box>
    </>
  );
}

export default ProposalEvent;
