import React from "react";
import { Icon } from "@iconify/react";
import { IconButton, OutlinedInput } from "@mui/material";

function SearchInput({ onChange, onClick }) {
  return (
    <OutlinedInput
      size="small"
      name="name"
      onChange={onChange}
      //   onKeyDown={(e) => {
      //     if (e.key === "Enter") {
      //       refetch();
      //     }
      //   }}
      placeholder="Cari..."
      style={{
        height: "3rem",
        fontSize: "0.875rem",
        borderRadius: 0,
        width: "100%",
      }}
      startAdornment={
        <IconButton onClick={onClick} edge="start">
          <Icon icon="material-symbols:search" color="#BDBDBD" />
        </IconButton>
      }
    />
  );
}

export default SearchInput;
