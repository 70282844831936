import React from "react";
import { Grid, Box } from "@mui/material";

const RememberMe = ({ handleForget }) => {
  return (
    <>
      <Grid
        item
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          {/* <input
            defaultChecked
            name="rememberMe"
            type="checkbox"
            // onChange={handleChange}
          />
          <label>Ingat Saya</label> */}
        </Box>
        <Box
          sx={{ cursor: "pointer", color: "#FF723D", fontSize: "14px" }}
          onClick={handleForget}
        >
          Lupa Kata Sandi?
        </Box>
      </Grid>
    </>
  );
};

export default RememberMe;
