import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import MainButton from "../../components/Elements/MainButton";
import Email from "../../assets/checkEmail.png";

const CheckEmail = ({ setSection }) => {
  return (
    <>
      <Grid container item direction="column" width="27.5rem" margin={"auto"}>
        <Box sx={{ width: "10", marginBottom: "32px" }} margin={"auto"}>
          <img src={Email} alt="" />
        </Box>
        <Typography fontWeight="700" fontSize="32px">
          Periksa email Anda
        </Typography>
        <Typography fontWeight="400" fontSize="16px" marginBottom={"2rem"}>
          Kami telah mengirimkan instruksi pemulihan kata sandi ke kata sandi
          Anda
        </Typography>

        <MainButton
          variant={"contained"}
          onClick={() => setSection("password")}
        >
          Buka Email Anda
        </MainButton>
      </Grid>
    </>
  );
};

export default CheckEmail;
