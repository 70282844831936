import styled from "@emotion/styled";
import { FormControl, FormLabel, TextField } from "@mui/material";
import React from "react";

function AdminTextArea({
  label,
  name,
  type,
  InputProps,
  placeholder,
  otherProps,
  multiline,
  maxRows,
  rows,
  onChange,
  required,
  disabled,
  sx,
}) {
  const InputText = styled(TextField)(() => ({
    "& .MuiInputBase-root": {
      borderRadius: 0,
    },
  }));
  return (
    <FormControl fullWidth sx={{ marginBottom: "2rem" }}>
      {label && (
        <FormLabel
          sx={{
            marginBottom: "0.5rem",
            color: "neutral.800",
            fontSize: "1.25rem",
            fontWeight: 700,
          }}
        >
          {required && (
            <span style={{ color: "#B43127", fontWeight: "bold" }}>*</span>
          )}{" "}
          {label}
        </FormLabel>
      )}
      <InputText
        name={name}
        type={type}
        placeholder={placeholder}
        inputProps={{
          style: {
            fontSize: "0.875rem",
          },
        }}
        sx={{
          "& fieldset": {
            borderRadius: 0,
          },
          bgcolor: disabled ? "neutral.300" : "#FFFFFF",
          color: "neutral.700",
          ...sx,
        }}
        InputProps={InputProps}
        multiline={multiline}
        rows={rows}
        maxRows={maxRows}
        onChange={onChange}
        {...otherProps}
      />
    </FormControl>
  );
}

export default AdminTextArea;
