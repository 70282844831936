import React from "react";
import PropTypes from "prop-types";
import { Modal, Fade, Box, Backdrop } from "@mui/material";

export default function CustomModal({
  children,
  toggle,
  isShowing,
  handleClose,
  style,
}) {
  const componentStyle = {
    ...style,
    position: "absolute",
    maxHeight: "90vh",
    overflowY: "auto",
    overflowX: "auto",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    // p: 7,
    // pt: 3,
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isShowing}
      onClose={handleClose || toggle}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isShowing}>
        <Box sx={componentStyle}>{children}</Box>
      </Fade>
    </Modal>
  );
}

CustomModal.propTypes = {
  children: PropTypes.node.isRequired,
  toggle: PropTypes.func.isRequired,
  isShowing: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  width: PropTypes.string,
};

CustomModal.defaultProps = {
  width: "60%",
};
