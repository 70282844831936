import { Stack, CircularProgress, Grid } from "@mui/material";

// components
import CustomModal from "../CustomModal";
import { useSelector } from "react-redux";
import { modalState } from "../../../redux/feature/modalSlice";

export default function LoadingModal() {
  const { isLoading } = useSelector(modalState);

  return (
    <CustomModal isShowing={isLoading} toggle={() => false} width="400px">
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        marginTop={"1rem"}
        padding={"3rem"}
        gap={4}
      >
        <Grid item>
          <CircularProgress color="primary" thickness={8} size={80} />
        </Grid>
        <Grid item fontWeight={"700"} fontSize="20px">
          SEDANG DIPROSES...
        </Grid>
      </Grid>
    </CustomModal>
  );
}
