import React, { useState } from "react";

import {
  Grid,
  Typography,
  Box,
  Tabs,
  Tab,
  InputAdornment,
} from "@mui/material";
import Logo from "../../assets/logotim.png";
import { Icon } from "@iconify/react";
import AdminInput from "../../components/Elements/MainInput";
import MainButton from "../../components/Elements/MainButton";
import { loginLabel } from "../../utils/tabsLabel";
import Title from "../../components/LoginUser/Title";
import RememberMe from "../../components/LoginUser/RememberMe";
import Buttons from "../../components/LoginUser/Buttons";
import IconButton from "@mui/material/IconButton";

const SignUp = ({ setSection, value, section, setLoginTitle }) => {
  const [addFormData, setAddFormData] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    confirm_password: "",
    role: "",
  });

  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };

  const [values, setValues] = useState({
    showPassword: false,
    showPasswordVerification: false,
  });
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleClickShowVerificationPassword = () => {
    setValues({
      ...values,
      showPasswordVerification: !values.showPasswordVerification,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Grid container direction={"column"} gap={0.5} padding="0 7.5rem">
        <Title
          value={value}
          section={section}
          setSection={setSection}
          setLoginTitle={setLoginTitle}
        />

        <AdminInput
          required
          name="name"
          label="Nama"
          placeholder={"Masukan Nama"}
          onChange={handleAddFormChange}
        />
        <AdminInput
          required
          name="phone"
          label="No HP"
          placeholder={"Masukan No HP"}
          onChange={handleAddFormChange}
        />
        <AdminInput
          required
          name="email"
          label="Email"
          placeholder={"Masukan Email"}
          onChange={handleAddFormChange}
        />

        <AdminInput
          required
          type={values.showPassword ? "text" : "password"}
          label="Password"
          placeholder={"Masukkan Password"}
          onChange={handleAddFormChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? (
                    <Icon
                      icon="material-symbols:visibility-outline"
                      color="#828282"
                      width="20"
                      height="20"
                    />
                  ) : (
                    <Icon
                      icon="ic:outline-visibility-off"
                      color="#828282"
                      width="20"
                      height="20"
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <AdminInput
          required
          type={values.showPasswordVerification ? "text" : "password"}
          label="Ulangi Password"
          placeholder={"Masukkan Password"}
          onChange={handleAddFormChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowVerificationPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPasswordVerification ? (
                    <Icon
                      icon="material-symbols:visibility-outline"
                      color="#828282"
                      width="20"
                      height="20"
                    />
                  ) : (
                    <Icon
                      icon="ic:outline-visibility-off"
                      color="#828282"
                      width="20"
                      height="20"
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Buttons
          setSection={setSection}
          section={section}
          setLoginTitle={setLoginTitle}
        />
      </Grid>
    </>
  );
};

export default SignUp;
