import React, { useState } from "react";

import Login from "./login";
import ForgotPass from "./ForgotPass";

const About = ({ setLoginTitle }) => {
  const [section, setSection] = useState("");

  return (
    <>
      {section === "" ? (
        <Login setSection={setSection} setLoginTitle={setLoginTitle} />
      ) : (
        <ForgotPass setSection={setSection} setLoginTitle={setLoginTitle} />
      )}
    </>
  );
};

export default About;
