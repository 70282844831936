import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Grid, Typography, InputAdornment, Box } from "@mui/material";
import Logo from "../../assets/logotim.png";
import { Icon } from "@iconify/react";
import AdminInput from "../../components/Elements/AdminInput";
import MainButton from "../../components/Elements/MainButton";

const Login = ({ setSection, setLoginTitle }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleForget = () => {
    setSection("forgot");
    setLoginTitle(
      "Forgot Password Link authentication will send to your email"
    );
  };
  return (
    <>
      <Grid container direction={"column"} padding={"0 3rem"}>
        <Grid item align={"center"} marginBottom={"3rem"}>
          <img src={Logo} alt="" />
        </Grid>

        <Typography
          sx={{ fontWeight: "700", fontSize: "48px", marginBottom: "3rem" }}
        >
          Log in
        </Typography>

        <AdminInput
          label="Email"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon icon="mdi:user" color="#828282" width="18" height="18" />
              </InputAdornment>
            ),
          }}
          placeholder={"Masukkan Email"}
        />
        <AdminInput
          label="Password"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon
                  icon="material-symbols:lock"
                  color="#828282"
                  width="18"
                  height="18"
                />
              </InputAdornment>
            ),
          }}
          placeholder={"Masukkan Password"}
        />
        <Grid
          item
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            {/* <input
              defaultChecked
              name="rememberMe"
              type="checkbox"
              onChange={handleChange}
            />
            <label>Ingat Saya</label> */}
          </Box>
          <Box
            sx={{ cursor: "pointer", color: "#FF723D" }}
            onClick={handleForget}
          >
            Forgot Password?
          </Box>
        </Grid>
        <MainButton sx={{ marginTop: "1rem" }} variant={"contained"}>
          Log in
        </MainButton>
      </Grid>
    </>
  );
};

export default Login;
