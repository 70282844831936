import React from "react";
import { Grid } from "@mui/material";
import { formEventState } from "../../redux/feature/formEventSlice";
import { useSelector } from "react-redux";

const FormTitle = () => {
  const title = {
    0: "Info Event Organizer",
    1: "Detail Sewa",
    2: "Detail Event",
  };

  const desc = {
    0: "Isi data untuk data Event Organizer. Ini akan memakan waktu beberapa menit.",
    1: "Isi data untuk data sewa tempat. Ini akan memakan waktu beberapa menit.",
    2: "Digunakan untuk verifikasi Event Organizer. Ini akan memakan waktu beberapa menit.",
  };

  const { page } = useSelector(formEventState);
  return (
    <>
      <Grid item display="flex" direction="column">
        <Grid item fontWeight={"700"} fontSize="32px">
          {title[page]}
        </Grid>
        <Grid
          item
          color="#828282"
          fontWeight={"500"}
          fontSize="16px"
          textAlign={"left"}
        >
          {desc[page]}
        </Grid>
      </Grid>
    </>
  );
};

export default FormTitle;
