import React from "react";
import { Divider, Grid, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Logo from "../assets/logotim.png";
import {
  mainFooterLink,
  secondaryFooterLink,
  socialFooterLink,
} from "../utils/constant";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <Grid
        container
        display="flex"
        direction="column"
        alignItems="center"
        padding="64px 24px"
        marginTop="64px"
        minHeight="400px"
        gap="36px"
        bottom="0"
        sx={{
          borderTop: 1,
          borderColor: "#E0E0E0",
          bgcolor: "background.paper",
        }}
      >
        <Grid
          item
          display="flex"
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          gap="36px"
        >
          {mainFooterLink.map((item) => (
            <Link
              key={item.title}
              sx={{
                cursor: "pointer",
                color: "neutral.800",
                textDecoration: "none",
              }}
              onClick={() => item.path && navigate(item.path)}
            >
              {item.title}
            </Link>
          ))}
          <Divider
            sx={{ height: "1.188rem", bgcolor: "neutral.800" }}
            orientation="vertical"
          />
          {socialFooterLink.map((item) => (
            <Link
              key={item.link}
              sx={{
                cursor: "pointer",
                color: "neutral.800",
                textDecoration: "none",
              }}
              href={item.link}
            >
              <img src={item.image} alt="" />
            </Link>
          ))}
        </Grid>
        <Grid item>
          <img src={Logo} alt="" />
        </Grid>
        <Grid item display="flex" direction="row" flexWrap="wrap" gap="48px">
          {secondaryFooterLink.map((item) => (
            <Link
              key={item.title}
              sx={{
                cursor: "pointer",
                color: "neutral.800",
                textDecoration: "none",
              }}
              onClick={() => item.path && navigate(item.path)}
            >
              {item.title}
            </Link>
          ))}
        </Grid>
        <Grid item>Copyright © 2023 Inagri. All Rights Reserved.</Grid>
      </Grid>
    </>
  );
};

export default Footer;
