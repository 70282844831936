import React, { useState } from "react";

import { Button, Grid, Box, TextField, Radio, FormControlLabel } from "@mui/material";
import { Icon } from "@iconify/react";
import Image from "../../assets/welcome.png";
import Logo from "../../assets/logotim.png";




import { height } from "@mui/system";
import { useNavigate } from "react-router-dom";




const About = () => {

    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Grid container>
                <Grid item  >
                    <Box
                        sx={{
                            height: "795px",
                            width: "706px",
                            backgroundImage: `url(${Image})`,
                            backgroundSize: "cover",
                            backgroundBlendMode: "hard-light, normal"
                        }}
                    >


                    </Box>


                </Grid>
                {/* <Grid item marginLeft="200px">
                    <img src={Logo} alt="" />
                </Grid> */}
                <Grid item marginTop={"100px"} marginLeft="80px">
                    <Grid item marginLeft="150px">
                        <img src={Logo} alt="" />
                    </Grid>
                    <Grid item fontWeight="700" marginLeft="80px" marginTop={"50px"} fontFamily={'Plus Jakarta Sans'} lineHeight="150%" fontStyle={"normal"} fontSize="48px" color="#1A202C" width={"236px"} height={"36px"}  >
                        Forgot
                        Password
                    </Grid>
                    <Grid item >

                        <Grid item marginLeft="80px" marginTop={"130px"} color={"#1F1F1F"} >New Password</Grid>
                        <Grid item xs={10} marginLeft="80px" marginTop={"10px"}>
                            <TextField
                                required
                                name="phone" size="small"
                                rows={1}
                                multiline
                                fullWidth
                                placeholder="Masukan Email"
                            />
                        </Grid>
                        <Grid item marginLeft="80px" marginTop={"20px"} color={"#1F1F1F"} >Confirm New Password</Grid>
                        <Grid item xs={10} marginLeft="80px" marginTop={"10px"}>
                            <TextField
                                required
                                name="phone" size="small"
                                rows={1}
                                multiline
                                fullWidth
                                placeholder="Masukan Password"
                            />
                        </Grid>

                    
                        <Grid item>
                            <Button

                                variant="outlined"
                                style={{
                                    borderRadius: "0",
                                    backgroundColor: '#D14F1E',
                                    width: "434px",
                                    height: "48px",
                                    marginLeft: "80px",
                                    marginTop: "30px"
                                }}
                                onClick={() => navigate("/login-admin")}

                            >
                                <Grid item color='white'>Reset Password</Grid>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button

                                variant="outlined"
                                style={{
                                    borderRadius: "0",
                                    backgroundColor: '#FFFFFF',
                                    width: "434px",
                                    height: "48px",
                                    marginLeft: "80px",
                                    marginTop: "10px"
                                }}
                                onClick={() => navigate("/forgot-password-admin")}

                            >
                                <Grid item color='#D14F1E'>Kembali</Grid>
                            </Button>
                        </Grid>





                    </Grid>

                </Grid>

            </Grid>


        </>
    );
};

export default About;