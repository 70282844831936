import React from "react";
import { Card } from "@mui/material";
import { Icon } from "@iconify/react";

// component
import FieldData from "../Elements/FieldData";
import MainButton from "../Elements/MainButton";
import TicketExchangeGuide from "./TicketExchangeGuide";

function BookingDetailCard({
  bookCode,
  name,
  email,
  ticketCategory,
  Pax,
  date,
}) {
  return (
    <>
      <Card sx={{ padding: "1rem", borderRadius: 0 }}>
        <FieldData title="Kode Booking" content={bookCode || "-"} />
        <FieldData
          title="Kategori Tiket"
          content={ticketCategory || "-"}
          secondContent={Pax}
        />
        <FieldData title="Tanggal Dipilih" content={date || "-"} />
        <FieldData title="Nama Pemesan" content={name || "-"} />
        <FieldData title="email" content={email || "-"} />
        <TicketExchangeGuide />
        <MainButton
          variant="outlined"
          sx={{ width: "fit-content" }}
          startIcon={
            <Icon
              icon="iconoir:maps-arrow-diagonal"
              height="1.25rem"
              width="1.25rem"
            />
          }
        >
          Lihat Denah Lokasi
        </MainButton>
      </Card>
    </>
  );
}

export default BookingDetailCard;
