import React from "react";
import { Grid, Select, MenuItem, Button } from "@mui/material";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";

import {
  handleChangeFacility,
  formEventState,
} from "../../redux/feature/formEventSlice";

const InputRow = ({ index, handleDeleteRes }) => {
  const dispatch = useDispatch();
  const { formEventData } = useSelector(formEventState);
  const handleChange = (e) => {
    let value = e.target.value;
    dispatch(handleChangeFacility({ index, value }));
  };
  return (
    <>
      <Grid item color={"#1F1F1F"} fontWeight={"500"} fontSize="14px">
        Fasilitas {index + 1}
      </Grid>
      <Grid
        container
        display="flex"
        direction="row"
        alignItems={"center"}
        justifyContent="space-between"
        gap={2}
      >
        <Grid item xs={8}>
          <Select
            required
            size="small"
            fullWidth
            onChange={handleChange}
            value={formEventData?.facility[index]}
          >
            <MenuItem value="test">test</MenuItem>
            <MenuItem value="test2">test1</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            variant="outlined"
            style={{
              borderRadius: "0",
            }}
            onClick={() => handleDeleteRes(index)}
          >
            <Grid item paddingTop={"0.2rem"} marginRight="0.5rem">
              <Icon icon="ph:trash-simple" color="#d14f1e" />
            </Grid>
            <Grid itam>Hapus</Grid>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default InputRow;
