import React from "react";
import { Grid } from "@mui/material";
import Upload from "./Admission";
import MainButton from "../Elements/MainButton";
import SupportFile from "./SupportFile";

const List = ({ attachment, supportFile }) => {
  const results = attachment?.map((_data, index) => <Upload />);
  const resultsSupport = supportFile?.map((_data, index) => <SupportFile />);
  return (
    <>
      {results || resultsSupport}
      <Grid item marginLeft={"463px"}>
        <MainButton variant={"contained"} sx={{ width: "153px" }}>
          + Tambah Media
        </MainButton>
      </Grid>
    </>
  );
};

export default List;
