import styled from "@emotion/styled";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
} from "@mui/material";
import React from "react";

function AdminInput({
  label,
  type,
  helperText,
  onClick,
  onChange,
  name,
  InputProps,
  placeholder,
  otherProps,
  sx,
  required,
  disabled,
}) {
  return (
    <FormControl fullWidth sx={{ marginBottom: "2rem" }}>
      {label && (
        <FormLabel
          sx={{
            marginBottom: "0.5rem",
            color: "neutral.800",
            fontSize: "1.25rem",
            fontWeight: 700,
          }}
        >
          {required && (
            <span style={{ color: "#B43127", fontWeight: "bold" }}>*</span>
          )}
          {label}
        </FormLabel>
      )}
      <TextField
        type={type}
        name={name}
        placeholder={placeholder}
        required={required}
        // disabled={disabled}
        onClick={onClick}
        onChange={onChange}
        inputProps={{
          style: {
            fontSize: "0.875rem",
          },
        }}
        InputProps={InputProps}
        sx={{
          "& fieldset": {
            borderRadius: 0,
          },
          bgcolor: disabled ? "neutral.300" : "#FFFFFF",
          color: "neutral.700",
          ...sx,
        }}
        {...otherProps}
      />
      {helperText && (
        <FormHelperText
          sx={{
            marginX: 0,
            marginTop: "0.5rem",
            color: "neutral.600",
            fontSize: "0.625rem",
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default AdminInput;
