import React from "react";
import { Typography, Grid } from "@mui/material";
import MainInput from "../../components/Elements/MainInput";
import MainButton from "../../components/Elements/MainButton";

const ChangePass = ({ setSection }) => {
  return (
    <>
      <Grid container item direction="column" width="27.5rem" margin={"auto"}>
        <Typography fontWeight="700" fontSize="32px">
          Ubah Password
        </Typography>
        <Typography fontWeight="400" fontSize="16px" marginBottom={"2rem"}>
          Masukkan email yang terkait dengan akun Anda dan kami akan mengirim
          email dengan instruksi untuk mengubah kata sandi
        </Typography>
        <MainInput label={"Email"} placeholder={"Masukan Email"} />
        <MainButton
          variant={"contained"}
          onClick={() => setSection("checkEmail")}
        >
          Kirim
        </MainButton>
      </Grid>
    </>
  );
};

export default ChangePass;
