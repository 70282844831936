import React, { useState } from "react";
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

// redux
import { userState } from "../../redux/feature/userSlice";

function ItemMenu({ item }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { userData } = useSelector(userState);

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton
          sx={{ textAlign: "center" }}
          onClick={() => setOpen(!open)}
        >
          <ListItemText primary={item.title} />
          {item.subMenu && (open ? <ExpandLess /> : <ExpandMore />)}
        </ListItemButton>
      </ListItem>
      {item.subMenu && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {item.subMenu?.map((subItem) =>
            subItem?.role ? (
              subItem?.role === userData?.role && (
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ textAlign: "center" }}
                    onClick={() => navigate(subItem?.path)}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          sx={{ fontSize: "0.875rem", color: "neutral.600" }}
                        >
                          {subItem.title}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </List>
              )
            ) : (
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ textAlign: "center" }}
                  onClick={() => navigate(subItem?.path)}
                >
                  <ListItemText
                    primary={
                      <Typography
                        sx={{ fontSize: "0.875rem", color: "neutral.600" }}
                      >
                        {subItem.title}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </List>
            )
          )}
        </Collapse>
      )}
    </>
  );
}

export default ItemMenu;
