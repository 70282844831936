import { Icon } from "@iconify/react";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";

// component
import CustomModal from "./CustomModal/CustomModal";
import TextArea from "./Elements/TextArea";
import GoogleMaps from "./GoogleMaps";
import MainButton from "./Elements/MainButton";

function SetAddressMapModal({ isShowing, toggle, setDetailAddress }) {
  const [address, setAddress] = useState({
    address: "Lokasi",
    addressName: "Lokasi",
  });
  const [detailLocation, setDetailLocation] = useState();

  return (
    <>
      <CustomModal
        isShowing={isShowing}
        toggle={toggle}
        style={{
          padding: "2rem",
          paddingTop: "4rem",
          width: { xs: "90%", sm: "41.5rem" },
        }}
      >
        <IconButton
          onClick={toggle}
          sx={{
            position: "absolute",
            color: "inherit",
            top: 10,
            right: 10,
          }}
        >
          <Icon
            icon="material-symbols:close-rounded"
            width="1.5rem"
            height="1.5rem"
          />
        </IconButton>

        <GoogleMaps setAddress={setAddress} />

        <Box
          sx={{ padding: "1rem", bgcolor: "neutral.200", marginBottom: "1rem" }}
        >
          <Typography variant="body1" fontWeight="700" color="neutral.800">
            {address.address}
          </Typography>
          <Typography variant="body2" fontWeight="500" color="neutral.600">
            {address.addressName}
          </Typography>
        </Box>
        <TextArea
          label="Detail Lokasi"
          placeholder="Masukkan Detail Lokasi"
          multiline={true}
          rows={2}
          maxRows={4}
          onChange={(e) => setDetailLocation(e.target.value)}
        />
        <Typography fontSize="0.625rem" color="neutral.500">
          Tuliskan Detail Alamat Lokasi dengan Jelas
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            marginTop: "1rem",
          }}
        >
          <MainButton
            variant="contained"
            sx={{ width: "fit-content" }}
            onClick={() => {
              setDetailAddress({ ...address, detailLocation });
              toggle();
            }}
          >
            Simpan
          </MainButton>
        </Box>
      </CustomModal>
    </>
  );
}

export default React.memo(SetAddressMapModal);
