import React from "react";
import { Outlet } from "react-router-dom";
import { Grid, CssBaseline, Typography } from "@mui/material";
import LoginImage from "../../assets/loginpict.png";
import SignUpUser from "../../assets/signUpUser.png";

export default function LayoutAuthUser({ loginTitle }) {
  console.log(loginTitle);
  return (
    <Grid
      container
      component="main"
      sx={{ height: "100vh", backgroundColor: "white" }}
    >
      <CssBaseline />
      <Grid
        item
        xs={6}
        // sm={8}
        // md={8}
        sx={{
          backgroundImage: loginTitle
            ? `url(${LoginImage})`
            : `url(${SignUpUser})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {loginTitle === "string" && (
          <Grid item padding="20rem 0 0 3rem">
            <Typography color={"white"} fontSize={"64px"} fontWeight={"700"}>
              {loginTitle?.length === 55
                ? loginTitle.substring(13, 0)
                : loginTitle.substring(15, 0)}
            </Typography>
            <Typography color={"white"} fontSize={"32px"} fontWeight={"700"}>
              {loginTitle?.length === 55
                ? loginTitle.substring(13)
                : loginTitle.substring(15)}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        item
        xs={6}
        // sm={8}
        // md={6}
      >
        <Outlet />
      </Grid>
    </Grid>
  );
}
