import { Box, Typography } from "@mui/material";
import React from "react";

function LargeImagePreview({ file, onClick, width, height }) {
  return (
    <>
      <Box sx={{ position: "relative", width: "fit-content" }}>
        {/* <IconButton
          sx={{
            position: "absolute",
            top: 5,
            right: 5,
            zIndex: 10,
            bgcolor: "primary.100",
            border: "1px solid",
            borderColor: "primary.600",
            width: "1.5rem",
            height: "1.5rem",
            padding: "0.2rem",
          }}
          onClick={onClick}
        >
          <Icon
            icon="material-symbols:close"
            height="1.25rem"
            width="1.25rem"
            color="#D14F1E"
          />
        </IconButton> */}
        <Box
          sx={{
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))",
            borderRadius: "1rem",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10,
          }}
        ></Box>
        <img
          alt="profile-picture"
          src={file.url || URL.createObjectURL(file)}
          style={{
            width: width,
            height: height,
            fontSize: "9.375rem",
            borderRadius: "1rem",
            objectFit: "contain",
          }}
          variant="square"
        />
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            zIndex: 20,
            width: "100%",
            bgcolor: "rgba(189, 189, 189, 0.5);",
            paddingX: "1rem",
            paddingY: "0.5rem",
            borderRadius: "0 0 1rem 1rem",
          }}
        >
          <Typography noWrap variant="body2">
            {file.name}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default LargeImagePreview;
