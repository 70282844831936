import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import Headline from "../../components/headline";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AdminInput from "../../components/Elements/AdminInput";
import AdminTextArea from "../../components/Elements/AdminTextArea";
import MainButton from "../../components/Elements/MainButton";
import RequiredLabel from "../../components/Elements/RequiredLabel";
import List from "../../components/AdminNews/List";
import { Icon } from "@iconify/react";

const AddNews = ({ setSection }) => {
  const [attachment, setAttachment] = useState([1]);
  const [newsDescription, setNewsDescription] = useState("");

  const titleHeadline = (
    <Grid container alignItems={"center"} gap={2}>
      <Icon icon="tabler:receipt" width="40" height="40" />
      <Typography fontSize={"32px"} fontWeight={"700"}>
        News
      </Typography>
    </Grid>
  );

  const editHeadline = (
    <Grid container alignItems={"center"} gap={1}>
      <Icon
        icon="material-symbols:edit-outline"
        color="white"
        width="20"
        height="20"
      />
      <Typography>Edit</Typography>
    </Grid>
  );
  return (
    <>
      <Grid
        container
        direction="column"
        backgroundColor="white"
        padding="1.5rem"
        gap={2}
      >
        <Headline title={titleHeadline} content={editHeadline} />
        <Grid item>
          <Grid item margin={"20px 0"}>
            <RequiredLabel label={"Judul"} />
          </Grid>
          <AdminInput sx={{ width: "315px" }} />
          <List attachment={attachment} />
        </Grid>

        <Grid container>
          <Grid item color="#B43127">
            *
          </Grid>
          <Grid item fontWeight={"700"} fontSize="20px">
            Deskripsi
          </Grid>
        </Grid>

        <Grid item sx={{ width: "665px" }}>
          <ReactQuill
            theme="snow"
            value={newsDescription}
            onChange={setNewsDescription}
          />
        </Grid>

        <Grid container>
          <Grid item color="#B43127">
            *
          </Grid>
          <Grid item fontWeight={"700"} fontSize="20px">
            Tag
          </Grid>
        </Grid>

        <AdminInput sx={{ width: "315px" }} />

        <MainButton variant={"contained"} sx={{ width: "130px" }}>
          Simpan Berita
        </MainButton>
      </Grid>
    </>
  );
};

export default AddNews;
