// ----------------------------------------------------------------------

export default function Lists(theme) {
  return {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: theme.palette.primary[500],
            backgroundColor: theme.palette.neutral[200],
            borderRight: `${theme.palette.primary[500]} 4px solid`,
            ".MuiListItemIcon-root": {
              color: theme.palette.primary[500],
            },
          },
          ".MuiListItemIcon-root": {
            color: theme.palette.neutral[500],
          },
          height: "3rem",
          color: theme.palette.neutral[700],
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: "auto",
          marginRight: theme.spacing(2),
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0,
        },
        multiline: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
  };
}
