import React from "react";
import { Grid, FormControlLabel, Checkbox, Typography } from "@mui/material";

const Filter = ({ props }) => {
  return (
    <>
      <Grid item>
        <Grid item fontWeight="500" fontSize="14px">
          {props?.key}
        </Grid>
      </Grid>
      <Grid item>
        {props?.children?.map((item) => (
          <Grid item key={item}>
            <FormControlLabel
              control={<Checkbox />}
              label={
                <Typography fontWeight="400" fontSize="14px">
                  {item}
                </Typography>
              }
            />
          </Grid>
        ))}

        <Grid
          item
          display={"flex"}
          flexDirection="column"
          alignItems={"flex-start"}
          width={"350px"}
          height="5px"
          borderBottom={"1px solid #E0E0E0 "}
        />
      </Grid>
    </>
  );
};

export default Filter;
