import { Icon } from "@iconify/react";
import { Box, ButtonBase, InputBase, Typography } from "@mui/material";
import {
  GoogleMap,
  Marker,
  StandaloneSearchBox,
  useLoadScript,
} from "@react-google-maps/api";
import React, { useCallback, useState } from "react";
import Geocode from "react-geocode";

function GoogleMaps({ setAddress }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "",
    libraries: ["places"],
  });
  Geocode.setApiKey("");
  Geocode.setLanguage("id");

  const [location, setLocation] = useState();
  const [searchBox, setSearchBox] = useState();
  const [center, setCenter] = useState();

  const handleChangeLocation = ({ lat, lng }) => {
    setCenter({
      lat,
      lng,
    });
    setLocation({
      lat,
      lng,
    });
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        const addressName =
          response.results[0].address_components[0].long_name +
          ", " +
          response.results[0].address_components[1].long_name;
        setAddress({
          address,
          addressName,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const getMyLocation = (e) => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          handleChangeLocation({ lat, lng });
        },
        (error) => {
          const lat = -6.2087634;
          const lng = 106.845599;
          handleChangeLocation({ lat, lng });
        }
      );
    }
  };

  const onLoadSearch = useCallback((props) => {
    setSearchBox(props);
  }, []);

  const onChangeMarker = useCallback((props) => {
    const lat = props?.latLng?.lat();
    const lng = props?.latLng?.lng();
    handleChangeLocation({ lat, lng });
  }, []);

  const onPlacesChanged = () => {
    if (searchBox !== null) {
      const lat = searchBox?.getPlaces()[0].geometry.location.lat();
      const lng = searchBox?.getPlaces()[0].geometry.location.lng();
      handleChangeLocation({ lat, lng });
    }
  };

  let myOptions = {
    disableDefaultUI: true, // a way to quickly hide all controls
    mapTypeControl: false,
    scaleControl: false,
    zoomControl: false,
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <GoogleMap
        zoom={15}
        center={center}
        mapContainerStyle={{
          height: "25rem",
          width: "100%",
        }}
        options={myOptions}
      >
        <StandaloneSearchBox
          onLoad={onLoadSearch}
          onPlacesChanged={onPlacesChanged}
        >
          <InputBase
            type="text"
            placeholder="Masukkan Lokasi"
            sx={{
              padding: "0.875rem",
              bgcolor: "background.paper",
              width: `95%`,
              height: "3rem",
              boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.1)",
              fontSize: `0.875rem`,
              textOverflow: `ellipses`,
              position: "absolute",
              top: "2.5%",
              left: "2.5%",
            }}
          />
        </StandaloneSearchBox>
        {location && (
          <Marker
            draggable
            onDragEnd={onChangeMarker}
            animation={2}
            position={location}
          />
        )}
      </GoogleMap>
      <ButtonBase
        sx={{
          position: "absolute",
          bottom: 20,
          left: 20,
          bgcolor: "neutral.100",
          width: "14.188rem",
          height: "2.5rem",
          display: "flex",
          alignItems: "center",
          gap: "0.688rem",
          boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.1)",
        }}
        onClick={getMyLocation}
      >
        <Icon
          icon="material-symbols:my-location-outline"
          height="1.25rem"
          width="1.25rem"
        />
        <Typography variant="body2" color="neutral.600">
          Gunakan Lokasi Saat Ini
        </Typography>
      </ButtonBase>
    </Box>
  );
}

export default GoogleMaps;
