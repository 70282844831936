import React, { useState } from "react";
import Headline from "../../components/headline";
import SearchBar from "../../components/Searchbar";
import Table from "../../components/tableMember";
import Footer from "../../components/foter";
import Tabs from "../../components/tabsMembersip";
import TabsEvent from "../../components/tabsLogin";

import { Grid } from "@mui/material";

const PurchaseOrder = ({ title, setSection }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container backgroundColor="white" padding="1.5rem" gap={2}>
        <Tabs value={value} handleChange={handleChange} />

        <Headline title="List membership" />
        <SearchBar />

        <Table title={title} />
        <Footer />
      </Grid>
    </>
  );
};

export default PurchaseOrder;
