import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, Grid, Typography } from "@mui/material";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";

import Headline from "../../components/headline";
import Footer from "../../components/foter";
import { tableHeadEventBase, tableHeadProposal } from "../../utils/tableHead";
import SearchBarBase from "../../components/SearchbarBase";
import TableEventBase from "../../components/AdminEvent/TableEventBase";

// service
import EventServices from "../../services/EventServices";

const ProposalEventBase = ({ title }) => {
  const [limit, setLimit] = useState(10);
  const [status, setStatus] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  const onChangeLimit = (e) => {
    setLimit(e.target.value);
  };

  const onChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleSelectItem = (id) => {
    let dataSelected = selectedItems.includes(id)
      ? selectedItems.filter((item) => item !== id)
      : [...selectedItems, id];

    setSelectedItems(dataSelected);
  };

  const handleSelectAll = () => {
    let dataSelected = [];
    if (selectedItems.length < data?.length) {
      data?.forEach((item) => {
        dataSelected.push(item.id);
      });
    }

    setSelectedItems(dataSelected);
  };

  const { data, isLoading, isFetching, error, refetch } = useQuery(
    ["proposal-event"],
    () => EventServices.getEvent()
  );

  // const data = [
  //   {
  //     id: "1",
  //     nama_event: "Animakini X Ciffest 2023",
  //     event_organizer: " Kentang Production",
  //     date: "11 Sep 2023",
  //     location: "Aula Utama Gedung Ismail Marzuki",
  //     ticket_amount: 300,
  //     status: "Disetujui",
  //   },
  //   {
  //     id: "2",
  //     nama_event: "Urang Minang Baralek Gadang",
  //     event_organizer: " Cimol Production",
  //     date: "8 Sep 2023",
  //     location: "Aula Utama Gedung Ismail Marzuki",
  //     ticket_amount: 100,
  //     status: "Disetujui",
  //   },
  //   {
  //     id: "3",
  //     nama_event: "Pameran Akhir Tahun",
  //     event_organizer: " Otak-Otak Production",
  //     date: "1 Sep 2023",
  //     location: "Aula Utama Gedung Ismail Marzuki",
  //     ticket_amount: 500,
  //     status: "Disetujui",
  //   },
  // ];

  const headline = (
    <>
      <Box display={"flex"} direction={"row"} alignItems={"center"} gap={2}>
        <Icon
          icon="mdi:clipboard-text-outline"
          color={"#1F1F1F"}
          width="2rem"
          height="2rem"
        />
        <Typography sx={{ fontSize: "2rem", fontWeight: "700" }}>
          List Event
        </Typography>
      </Box>
    </>
  );

  return (
    <>
      <Grid container backgroundColor="white" padding="1.5rem" gap={2}>
        <Headline title={headline} />
        <SearchBarBase
          limit={limit}
          status={status}
          onChangeLimit={onChangeLimit}
          onChangeStatus={onChangeStatus}
          selectedItems={selectedItems}
        />
        {/* table resposive check the grid container as well */}
        <TableEventBase
          header={tableHeadEventBase}
          data={data?.data?.data}
          title={title}
          onSelectItem={handleSelectItem}
          selectedItems={selectedItems}
          onSelectAll={handleSelectAll}
          refetch={refetch}
          isAction
        />
        <Footer
          pagination={{
            current_page: 1,
            total_row_per_page: 5,
            total_data: 50,
          }}
          length={50}
          // pageTarget={page}
          // handleChangePageTarget={handleChangePage}
        />
      </Grid>
    </>
  );
};
export default ProposalEventBase;
