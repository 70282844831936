import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Avatar,
  Box,
  Breadcrumbs,
  ButtonBase,
  Checkbox,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

// asset
import Back from "../assets/Back.png";
import Anim from "../assets/event3.jpg";

// component
import MainNavbar from "../components/MainNavbar";
import MainInput from "../components/Elements/MainInput";
import MainSelect from "../components/Elements/MainSelect";
import FieldData from "../components/Elements/FieldData";
import { Icon } from "@iconify/react";
import MainButton from "../components/Elements/MainButton";

const DetailPemesanan = () => {
  const navigate = useNavigate();

  const breadcrumbs = [
    <Typography
      key="1"
      color="text.primary"
      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
    >
      1. Booking
    </Typography>,
    <Typography
      key="2"
      color="text.primary"
      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
    >
      2. Pesan
    </Typography>,
    <Typography
      key="3"
      color="inherit"
      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
    >
      3. Bayar
    </Typography>,
    <Typography
      key="4"
      color="inherit"
      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
    >
      4. Selesai
    </Typography>,
  ];

  return (
    <Box bgcolor="background.paper" minHeight="100vh" paddingBottom="4rem">
      <MainNavbar />
      <Grid container>
        <Grid
          item
          xs={11}
          sm={10}
          md={9}
          sx={{
            margin: "auto",
            marginTop: "4rem",
          }}
        >
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "5rem",
            }}
          >
            {breadcrumbs}
          </Breadcrumbs>
          <ButtonBase
            sx={{ display: "flex", gap: "0.875rem " }}
            onClick={() => navigate(-1)}
          >
            <div>
              <img src={Back} alt="" />
            </div>
            <Typography fontSize="2rem" fontWeight="700">
              Detail Pemesanan
            </Typography>
          </ButtonBase>
          <Divider
            sx={{
              width: "100%",
              bgcolor: "neutral.400",
              marginTop: "1rem",
              marginBottom: "2rem",
            }}
            orientation="horizontal"
          />

          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <Box
                padding="1rem"
                paddingBottom="0"
                boxSizing="border-box"
                width="100%"
                border="1px solid"
                borderColor="neutral.400"
                marginTop="1rem"
              >
                <Typography fontSize="1.5rem" fontWeight="700">
                  Detail Pemesan
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    gap: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  <MainSelect
                    label="Titel"
                    options={[
                      { value: 1, title: "Tn." },
                      { value: 2, title: "Ny." },
                    ]}
                  />
                  <MainSelect
                    label="Titel"
                    options={[
                      { value: 1, title: "Tn." },
                      { value: 2, title: "Ny." },
                    ]}
                  />
                  <Box sx={{ width: { xs: "100%", md: "70%" } }}>
                    <MainInput
                      label="Nama Lengkap"
                      placeholder="Masukkan Nama"
                    />
                  </Box>
                </Box>
                <MainInput
                  label="Email"
                  placeholder="Masukkan Email"
                  type="email"
                  helperText="E-Voucher akan dikirim ke email ini."
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    gap: "1rem",
                  }}
                >
                  <Box sx={{ width: { xs: "100%", md: "60%" } }}>
                    <MainSelect
                      label="Kode Negara"
                      options={[
                        { value: 1, title: "+62" },
                        { value: 2, title: "+52" },
                      ]}
                    />
                  </Box>
                  <MainInput label="Telp" placeholder="Masukkan Telp" />
                </Box>
              </Box>
              <Box
                padding="1rem"
                paddingBottom="0"
                boxSizing="border-box"
                width="100%"
                border="1px solid"
                borderColor="neutral.400"
                marginTop="1rem"
              >
                <Typography fontSize="1.5rem" fontWeight="700">
                  Detail Pengunjung
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingX: "1rem",
                    paddingY: "0.5rem",
                    bgcolor: "neutral.200",
                    marginTop: "1rem",
                  }}
                >
                  <Typography variant="body2" fontWeight="700">
                    Info Pengunjung
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.8rem",
                    }}
                  >
                    <Typography variant="body2">Sama dengan Pemesan</Typography>
                    <Checkbox defaultChecked />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingX: "1rem",
                    paddingY: "0.5rem",
                    bgcolor: "neutral.200",
                    gap: "0.625rem",
                    bgcolor: "primary.100",
                    minHeight: "3.5rem",
                  }}
                >
                  <Icon
                    icon="material-symbols:info-rounded"
                    height="1.25rem"
                    width="1.25rem"
                    color="#D14F1E"
                  />
                  <Typography variant="caption">
                    Hanya butuh satu info pengnjung untuk semua tiket yang kamu
                    pesan
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    gap: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  <MainSelect
                    label="Titel"
                    options={[
                      { value: 1, title: "Tn." },
                      { value: 2, title: "Ny." },
                    ]}
                  />
                  <MainSelect
                    label="Titel"
                    options={[
                      { value: 1, title: "Tn." },
                      { value: 2, title: "Ny." },
                    ]}
                  />
                  <Box sx={{ width: { xs: "100%", md: "70%" } }}>
                    <MainInput
                      label="Nama Lengkap"
                      placeholder="Masukkan Nama"
                    />
                  </Box>
                </Box>
                <MainInput
                  label="Email"
                  placeholder="Masukkan Email"
                  type="email"
                  helperText="E-Voucher akan dikirim ke email ini."
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    gap: "1rem",
                  }}
                >
                  <Box sx={{ width: { xs: "100%", md: "60%" } }}>
                    <MainSelect
                      label="Kode Negara"
                      options={[
                        { value: 1, title: "+62" },
                        { value: 2, title: "+52" },
                      ]}
                    />
                  </Box>
                  <MainInput label="Telp" placeholder="Masukkan Telp" />
                </Box>
                <MainInput
                  label="No KTP"
                  placeholder="Masukan Nomor KTP"
                  required
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box
                padding="1rem"
                boxSizing="border-box"
                width="100%"
                border="1px solid"
                borderColor="neutral.400"
                marginTop="1rem"
              >
                <Typography fontSize="1.5rem" fontWeight="700">
                  Detail Pemesanan
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "start",
                    marginTop: "1rem",
                  }}
                >
                  <Avatar
                    alt="profile-picture"
                    src={Anim}
                    sx={{
                      width: "4.5rem",
                      height: "4.5rem",
                      fontSize: "9.375rem",
                      marginBottom: "1rem",
                    }}
                    variant="square"
                  />
                  <Typography sx={{ fontSize: "1rem", fontWeight: 700 }}>
                    Animakini X Ciffest 2023
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    width: "100%",
                    bgcolor: "neutral.400",
                    marginY: "1rem",
                  }}
                  orientation="horizontal"
                />
                <Typography sx={{ fontSize: "1.25rem", fontWeight: 700 }}>
                  VIP
                </Typography>
                <Box sx={{ display: "flex", marginTop: "0.5rem" }}>
                  <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
                    1 Tiket
                  </Typography>
                  &nbsp;
                  <Typography
                    sx={{
                      fontSize: "0.875rem",
                      fontWeight: 700,
                      color: "neutral.600",
                    }}
                  >
                    - 1 Pax
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    width: "100%",
                    bgcolor: "neutral.400",
                    marginY: "1rem",
                  }}
                  orientation="horizontal"
                />
                <FieldData
                  title="Tanggal Dipilih"
                  content="Senin, 20 Februari 2023"
                />
                <Divider
                  sx={{
                    width: "100%",
                    bgcolor: "neutral.400",
                    marginY: "1rem",
                  }}
                  orientation="horizontal"
                />
                <ul
                  style={{
                    margin: 0,
                    paddingLeft: "1rem",
                    fontSize: "0.875rem",
                    color: "#828282",
                  }}
                >
                  <li>Tidak bisa Refund</li>
                  <li>Pembayaran manual</li>
                </ul>
                <Divider
                  sx={{
                    width: "100%",
                    bgcolor: "neutral.400",
                    marginY: "1rem",
                  }}
                  orientation="horizontal"
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="body2">Total Pembayaran</Typography>
                  <Typography
                    fontSize="1.25rem"
                    fontWeight="700"
                    color="primary.600"
                  >
                    IDR 500.000
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  marginTop: "1rem",
                }}
              >
                <Typography fontSize="1rem">
                  Dengan mengklik <strong>"Lanjutkan ke Pembayaran"</strong>,
                  Anda menyetujui aturan, batasan, dan{" "}
                  <Link
                    color="primary.600"
                    fontWeight="700"
                    sx={{
                      textDecoration: "none",
                      "&:hover": { cursor: "pointer" },
                    }}
                  >
                    Syarat & Ketentuan
                  </Link>
                  .
                </Typography>
                <MainButton
                  variant="contained"
                  sx={{ marginTop: "1.5rem" }}
                  onClick={() => navigate("/detail-pembayaran")}
                >
                  Lanjutkan ke Pembayaran
                </MainButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailPemesanan;
