import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccessed: false,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.isError = action.payload;
    },
    setSuccessed: (state, action) => {
      state.isSuccessed = action.payload;
    },
  },
});

export const modalState = (state) => state.modal;

export const { setLoading, setError, setSuccessed } = modalSlice.actions;

export default modalSlice.reducer;
