import { request } from "../utils/request";
import { URL } from "../utils/constant";
import authHeader from "./authHeader";

const postEvent = (formEventData) => {
  var formData = new FormData();

  // const data = {
  //   event_organizer_name: "eventzzv9",
  //   email: "eventzzv9@gmail.com",
  //   phone: "089999999929",
  //   provinsi_id: "1",
  //   kota_id: "2",
  //   kec_id: "3",
  //   kode_pos_id: "2403",
  //   address: "alamat event ini dijalan test rt 01 rw 02 kec. test kab. test",
  //   start_date: "2022-04-01",
  //   end_date: "2022-04-11",
  //   loc_id: "1",
  //   facility: "tas",
  //   event_name: "tas",
  //   event_type: "tas",
  //   event_detail: "tas",
  //   total_visitor: "2",
  // };

  // key is each report if there are multiple report
  for (var key in formEventData) {
    formData.append(key, formEventData[key]);
  }

  // console.log FORMDATA
  // for (var pair of formData.entries()) {
  //   console.log(pair[0] + ", " + pair[1]);
  // }

  return request(`${URL}/plan_visit_all`, {
    method: "POST",
    headers: authHeader(),
    data: formData,
  });
};

const getEventType = () => {
  return request(`${URL}/get_event_type`, {
    method: "GET",
    headers: authHeader(),
  });
};

const getEvent = () => {
  return request(`${URL}/plan_visit`, {
    method: "GET",
    headers: authHeader(),
  });
};

const confirmationEvent = (id, type) => {
  var form_data = new FormData();

  if (type === "approve") {
    let item = {
      status_event: `${type}-plan-visit`,
    };
    for (var key in item) {
      form_data.append(key, item[key]);
    }

    return request(`${URL}/plan_visit/confirm/${id}`, {
      method: "PUT",
      headers: authHeader(),
      data: form_data,
    });
  } else {
    console.log(type);
    let item = {
      status_event: `reject-plan-visit`,
      reject_reason: type,
    };
    for (var keys in item) {
      form_data.append(keys, item[keys]);
    }

    return request(`${URL}/plan_visit/confirm/${id}`, {
      method: "PUT",
      headers: authHeader(),
      data: form_data,
    });
  }
};

const rejectEvent = (id, type) => {
  var form_data = new FormData();
  let item = {
    status_event: `reject-plan-visit`,
    reject_reason: type,
  };

  for (var key in item) {
    form_data.append(key, item[key]);
  }

  return request(`${URL}/plan_visit/confirm/${id}`, {
    method: "PUT",
    headers: authHeader(),
    data: form_data,
  });
};

const getEventById = () => {
  return request(`${URL}/plan_visit_by_eo`, {
    method: "GET",
    headers: authHeader(),
  });
};

const getEventDetail = (id) => {
  return request(`${URL}/plan_visit/${id}`, {
    method: "GET",
    headers: authHeader(),
  });
};

const getLocation = () => {
  return request(`${URL}/get_location`, {
    method: "GET",
    headers: authHeader(),
  });
};

const EventServices = {
  postEvent,
  getEventType,
  getEvent,
  getEventById,
  confirmationEvent,
  getEventDetail,
  getLocation,
  rejectEvent,
};

export default EventServices;
