import React, { Component } from 'react';

import { Grid } from "@mui/material";
class time extends Component {
    render() {
        return (
            <div>
                {/* <Grid item
                    display={'flex'}
                    flexDirection='row'
                    justifyContent={'center'}
                    alignItems='center'
                    padding={'16px'}
                    gap='16px'
                    position={'absolute'}
                    width='1350px'
                    height={'68px'}
                    left='0px'
                    top={'96px'}
                    backgroundColor='#D14F1E'>
                    <Grid item width={"232px"}
                        height="26px"
                        fontFamily={"PLus Jakarta Sans"}
                        fontSize="16px"
                        fontWeight={"500"}
                        lineHeight="160%"

                        letterSpacing={"0.25px"}
                        color="#FFFFFF">
                        Selesaikan pemesanan dalam
                    </Grid>
                    <Grid item
                        display={"flex"}
                        flexDirection="column"
                        justifyContent={"center"}
                        alignItems="center"
                        padding={"8px"}
                        width="36px"
                        height="36px"
                        backgroundColor="#F0E5CF">00</Grid>
                    <Grid item

                        color="#F0E5CF">:</Grid>
                    <Grid item
                        display={"flex"}
                        flexDirection="column"
                        justifyContent={"center"}
                        alignItems="center"
                        padding={"8px"}
                        width="36px"
                        height="36px"
                        backgroundColor="#F0E5CF">59</Grid>
                    <Grid item

                        color="#F0E5CF">:</Grid>

                    <Grid item
                        display={"flex"}
                        flexDirection="column"
                        justifyContent={"center"}
                        alignItems="center"
                        padding={"8px"}
                        width="36px"
                        height="36px"
                        backgroundColor="#F0E5CF">59</Grid>




                </Grid> */}
                <Grid
                    item
                    container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        height: "10vh",
                        //   backgroundImage: `url(${Event3})`,
                        //   backgroundSize: "cover",
                        backgroundColor: "#D14F1E",
                        justifyContent:'center',
                        alignItems:'center',
                        padding:'16px',
                        gap:'16px',

                    }}
                >
                    <Grid item width={"232px"}
                        height="26px"
                        fontFamily={"PLus Jakarta Sans"}
                        fontSize="16px"
                        fontWeight={"500"}
                        lineHeight="160%"

                        letterSpacing={"0.25px"}
                        color="#FFFFFF">
                        Selesaikan pemesanan dalam
                    </Grid>
                    <Grid item
                        display={"flex"}
                        flexDirection="column"
                        justifyContent={"center"}
                        alignItems="center"
                        padding={"8px"}
                        width="36px"
                        height="36px"
                        backgroundColor="#F0E5CF">00</Grid>
                    <Grid item

                        color="#F0E5CF">:</Grid>
                    <Grid item
                        display={"flex"}
                        flexDirection="column"
                        justifyContent={"center"}
                        alignItems="center"
                        padding={"8px"}
                        width="36px"
                        height="36px"
                        backgroundColor="#F0E5CF">59</Grid>
                    <Grid item

                        color="#F0E5CF">:</Grid>

                    <Grid item
                        display={"flex"}
                        flexDirection="column"
                        justifyContent={"center"}
                        alignItems="center"
                        padding={"8px"}
                        width="36px"
                        height="36px"
                        backgroundColor="#F0E5CF">59</Grid>


                </Grid>
            </div>
        );
    }
}

export default time;