import React, { useState } from "react";
import Headline from "../../components/headline";
import SearchBar from "../../components/Searchbar";
import Table from "../../components/tableAdmin";
import TableProposal from "../../components/TableProposal";
import Footer from "../../components/foter";
import Tabs from "../../components/tabsHome";
import { userManagementLabel } from "../../utils/tabsLabel";
import { Icon } from "@iconify/react";
import { Grid, Typography } from "@mui/material";

import { tableHeadUser, tableHeadRole } from "../../utils/tableHead";

const PurchaseOrder = ({ title, setSection }) => {
  const [value, setValue] = useState("Admin");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [membership, setMembership] = useState("Public Visitor");
  const handleChangeMembership = (event, newValue) => {
    setMembership(newValue);
  };

  const rowsAdmin = [
    {
      id: "1",
      username: "Ahmad Aziz Nuraidi",
      email: "Ahmadaziz123@gmail.com ",
      password: "Ahmad123",
      status: true,
    },
    {
      id: "2",
      username: "Rozak Nurlail",
      email: "Rozaknurlail123@gmail.com ",
      password: "Rozak123",
      status: true,
    },
    {
      id: "3",
      username: "Bani Imanudin",
      password: "Baniimanudin123@gmail.com ",
      status: false,
    },
  ];

  const titleHeadline = (
    <>
      {value === "Admin" ? (
        <Grid container alignItems={"center"} gap={2}>
          <Icon icon="mdi:user-check-outline" width="40" height="40" />
          <Typography fontSize={"32px"} fontWeight={"700"}>
            List Admin
          </Typography>
        </Grid>
      ) : value === "Membership" ? (
        <Grid container alignItems={"center"} gap={2}>
          <Icon icon="mdi:user-check-outline" width="40" height="40" />
          <Typography fontSize={"32px"} fontWeight={"700"}>
            List Membership
          </Typography>
        </Grid>
      ) : (
        <Grid container alignItems={"center"} gap={2}>
          <Icon icon="mdi:user-check-outline" width="40" height="40" />
          <Typography fontSize={"32px"} fontWeight={"700"}>
            Role & Hak Akses
          </Typography>
        </Grid>
      )}
    </>
  );

  const dataRole = [
    {
      no: "1",
      role: "Super Admin",
      home: "",
      event: "View Only",
      collection: "Edit & Delete",
      user: "Edit & Delete",
      content: "Edit & Delete",
    },
    {
      no: "2",
      role: "Admin Venue A",
      home: "",
      event: "Approve & Reject",
      collection: "View Only",
      user: "View Only",
      content: "View Only",
    },
    {
      no: "3",
      role: "Admin Venue B",
      home: "",
      event: "Approve & Reject",
      collection: "View Only",
      user: "View Only",
      content: "View Only",
    },
    {
      no: "3",
      role: "Admin Venue C",
      home: "",
      event: "Approve & Reject",
      collection: "View Only",
      user: "View Only",
      content: "View Only",
    },
  ];

  return (
    <>
      <Grid container backgroundColor="white">
        <Tabs
          value={value}
          handleChange={handleChange}
          label={userManagementLabel}
          membership={membership}
          handleChangeMembership={handleChangeMembership}
        />
        <Grid container backgroundColor="white" padding="1.5rem" gap={2}>
          <Headline
            title={titleHeadline}
            content={
              value === "Admin"
                ? "+ Tambah Admin"
                : value === "Role & Hak Akses"
                ? "+ Tambah Role"
                : null
            }
            setSection={setSection}
          />
          <SearchBar />

          {value === "Role & Hak Akses" ? (
            <TableProposal header={tableHeadRole} data={dataRole} />
          ) : (
            <Table title={title} header={tableHeadUser} data={rowsAdmin} />
          )}

          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

export default PurchaseOrder;
