import React from "react";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";

import { formEventState } from "../../redux/feature/formEventSlice";

import { useSelector } from "react-redux";

import { useQuery } from "@tanstack/react-query";

// services
import RegionServices from "../../services/RegionServices";

const InfoEo = ({ handleChange, filter }) => {
  const { formEventData } = useSelector(formEventState);
  const { data: dataNation } = useQuery(
    ["nation"],
    () => RegionServices.getNation(),
    {
      keepPreviousData: true,
    }
  );
  let array = [];
  const listNation = array.concat(dataNation?.data?.data);

  const { data: dataProvince } = useQuery(
    ["province"],
    () => RegionServices.getProvince(),
    {
      keepPreviousData: true,
    }
  );
  const listProvince = dataProvince?.data?.data;

  const { data: dataRegency } = useQuery(
    ["regency", filter?.provinsi_id],
    () => RegionServices.getRegency({ code: filter?.provinsi_id }),
    {
      keepPreviousData: true,
      enabled: !!filter?.provinsi_id,
    }
  );
  const listRegency = filter?.provinsi_id ? dataRegency?.data?.data : [];

  const { data: dataDistrict } = useQuery(
    ["district", filter?.kota_id],
    () => RegionServices.getDistrict({ code: filter?.kota_id }),
    {
      keepPreviousData: true,
      enabled: !!filter?.kota_id,
    }
  );
  const listDistrict = filter?.kota_id ? dataDistrict?.data?.data : [];

  return (
    <>
      <Grid item>
        <FormControlLabel
          control={<Checkbox />}
          label="I agree with Terms of use"
        />
      </Grid>
      <Grid
        display={"flex"}
        direction="column"
        border={1}
        borderColor="#BDBDBD"
        padding="1rem"
        gap={2}
      >
        <Grid item fontWeight={"700"} fontSize="20px">
          Data Event Organizer
        </Grid>
        <Grid item color={"#828282"} fontWeight={"500"} fontSize="12px">
          Tentukan persis sesuai data asli dalam dokumen resmi
        </Grid>
        <Grid item color={"#1F1F1F"} fontWeight={"500"} fontSize="14px">
          Nama Event Organizer
        </Grid>
        <TextField
          required
          size="small"
          placeholder="Masukan Nama Event Organizer"
          name="event_organizer_name"
          value={formEventData?.event_organizer_name}
          onChange={handleChange}
        />
        <Grid item color={"#1F1F1F"} fontWeight={"500"} fontSize="14px">
          Email
        </Grid>
        <TextField
          required
          name="email"
          value={formEventData?.email}
          size="small"
          placeholder="Masukan Email"
          onChange={handleChange}
        />
        <Grid item color={"#1F1F1F"} fontWeight={"500"} fontSize="14px">
          No HP
        </Grid>
        <TextField
          required
          name="phone"
          value={formEventData?.phone}
          size="small"
          placeholder="Masukan No HP"
          onChange={handleChange}
        />
      </Grid>
      <Grid
        display={"flex"}
        direction="column"
        border={1}
        borderColor="#BDBDBD"
        padding="1rem"
        gap={2}
      >
        <Grid item fontWeight={"700"} fontSize="20px">
          Alamat Event Organizer
        </Grid>
        <Grid item color={"#828282"} fontWeight={"500"} fontSize="12px">
          Digunakan untuk verifikasi Event Organizer
        </Grid>
        <Grid item color={"#1F1F1F"} fontWeight={"500"} fontSize="14px">
          Negara
        </Grid>
        <Select
          required
          size="small"
          name="negara_id"
          onChange={handleChange}
          value={formEventData?.negara_id}
        >
          {listNation?.map((_nation) => (
            <MenuItem value={_nation?.nation_id} key={_nation?.nation_id}>
              {_nation?.nation_name}
            </MenuItem>
          ))}
        </Select>
        <Grid item color={"#1F1F1F"} fontWeight={"500"} fontSize="14px">
          Provinsi
        </Grid>

        <Select
          required
          name="provinsi_id"
          size="small"
          onChange={handleChange}
          value={formEventData?.provinsi_id}
          MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
        >
          {listProvince?.map((_prov, i) => (
            <MenuItem value={_prov?.region_code} key={i} height="20px">
              {_prov?.name}
            </MenuItem>
          ))}
        </Select>

        <Grid item color={"#1F1F1F"} fontWeight={"500"} fontSize="14px">
          Kabupaten/Kota
        </Grid>
        <Select
          required
          name="kota_id"
          size="small"
          value={formEventData?.kota_id}
          MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
          onChange={handleChange}
        >
          {listRegency?.map((_regency, i) => (
            <MenuItem value={_regency?.region_code} key={i}>
              {_regency?.name}
            </MenuItem>
          ))}
        </Select>
        <Grid container justifyContent="space-between">
          <Grid item display={"flex"} direction="column" xs={5.5} gap={2}>
            <Grid item color={"#1F1F1F"} fontWeight={"500"} fontSize="14px">
              Kecamatan
            </Grid>
            <Select
              required
              name="kec_id"
              size="small"
              fullWidth
              value={formEventData?.kec_id}
              MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
              onChange={handleChange}
            >
              {listDistrict?.map((_district, i) => (
                <MenuItem value={_district?.region_code} key={i}>
                  {_district?.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item display={"flex"} direction="column" xs={5.5} gap={2}>
            <Grid item color={"#1F1F1F"} fontWeight={"500"} fontSize="14px">
              Kode Pos
            </Grid>
            <TextField
              required
              name="kode_pos_id"
              size="small"
              value={formEventData?.kode_pos_id}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid item color={"#1F1F1F"} fontWeight={"500"} fontSize="14px">
          Detail Alamat
        </Grid>
        <TextField
          required
          name="address"
          multiline
          rows={4}
          placeholder="Masukan Detail Alamat"
          value={formEventData?.address}
          onChange={handleChange}
        />
      </Grid>
    </>
  );
};

export default InfoEo;
