import React from "react";
import { Icon } from "@iconify/react";
import { Grid, Box, InputAdornment, Typography } from "@mui/material";
import AdminInput from "../components/Elements/AdminInput";
import AdminTextArea from "../components/Elements/AdminTextArea";

const Home = () => {
  return (
    <>
      <Grid item>
        {/* hide map details */}
        <div style={{ width: "100%", overflow: "hidden", height: "700px" }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.5485007339453!2d106.83615461488672!3d-6.191115162380591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f43ed58a394b%3A0x52eb0c276d813909!2sTaman%20Ismail%20Marzuki!5e0!3m2!1sid!2sid!4v1678857908855!5m2!1sid!2sid"
            width="100%"
            height="800" // hide map details
            style={{ border: "0", marginTop: "-150px" }} // hide map details
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            position="relative"
            title="map"
          ></iframe>
        </div>
        <Grid
          container
          display="flex"
          flexDirection={"column"}
          backgroundColor="white"
          borderRadius={"6px"}
          width={"423px"}
          gap={2}
          position="absolute"
          top={600}
          marginLeft={"10rem"}
          padding={"33px 54px 20px 54px"}
        >
          <Grid item>
            <Typography fontSize={"24px"} fontWeight={"700"}>
              Get In Touch
            </Typography>
          </Grid>

          <Grid item>
            <AdminInput
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon
                      icon="mdi:user"
                      color="#828282"
                      width="18"
                      height="18"
                    />
                  </InputAdornment>
                ),
              }}
              placeholder={"Your Email"}
            />
            <AdminInput
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon
                      icon="mdi:user"
                      color="#828282"
                      width="18"
                      height="18"
                    />
                  </InputAdornment>
                ),
              }}
              placeholder={"Subject"}
            />
            <AdminTextArea
              multiline={true}
              rows={3}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ marginBottom: "3rem" }}
                  >
                    <Icon
                      icon="mdi:user"
                      color="#828282"
                      width="18"
                      height="18"
                    />
                  </InputAdornment>
                ),
              }}
              placeholder={"Message"}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        display={"flex"}
        direction="row"
        justifyContent="center"
        // marginTop={"80px"}
      >
        <Grid
          item
          sx={{
            display: "flex",
            flexWrap: "wrap",
            border: "1px solid #E0E0E0",
            // alignContent: "center",
            paddingTop: "4rem",
          }}
          xs={3}
        >
          <Grid container flexDirection={"column"} paddingLeft={"3rem"}>
            <Grid item gap={1} marginBottom={"1rem"}>
              <Grid container alignItems={"center"} gap={1}>
                <Box
                  backgroundColor="black"
                  borderRadius={"50%"}
                  padding={"1.2rem 1rem 1rem 1rem"}
                >
                  <Icon
                    icon="carbon:phone-filled"
                    color="white"
                    width="24"
                    height="24"
                  />
                </Box>
                <Typography fontSize={"16px"}>Phone</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography color="#828282" fontSize={"16px"}>
                +62 8212505 4242
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          sx={{
            display: "flex",
            flexWrap: "wrap",
            border: "1px solid #E0E0E0",
            // alignContent: "center",
            paddingTop: "4rem",
          }}
          xs={3}
        >
          <Grid container flexDirection={"column"} paddingLeft={"3rem"}>
            <Grid item gap={1} marginBottom={"1rem"}>
              <Grid container alignItems={"center"} gap={1}>
                <Box
                  backgroundColor="black"
                  borderRadius={"50%"}
                  padding={"1.2rem 1rem 1rem 1rem"}
                >
                  <Icon
                    Icon
                    icon="bx:map"
                    color="white"
                    width="24"
                    height="24"
                  />
                </Box>
                <Typography fontSize={"16px"}>Address</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography color="#828282" fontSize={"16px"}>
                Cikini, Menteng, Jakarta Pusat, DKI Jakarta, Indonesia, 10330.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          sx={{
            display: "flex",
            flexWrap: "wrap",
            border: "1px solid #E0E0E0",
            // alignContent: "center",
            paddingTop: "4rem",
          }}
          xs={3}
        >
          <Grid container flexDirection={"column"} paddingLeft={"3rem"}>
            <Grid item gap={1} marginBottom={"1rem"}>
              <Grid container alignItems={"center"} gap={1}>
                <Box
                  backgroundColor="black"
                  borderRadius={"50%"}
                  padding={"1.2rem 1rem 1rem 1rem"}
                >
                  <Icon
                    Icon
                    icon="ic:outline-email"
                    color="white"
                    width="24"
                    height="24"
                  />
                </Box>
                <Typography fontSize={"16px"}>Email</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography color="#828282" fontSize={"16px"}>
                info@TMI.com
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          display: "flex",
          borderTop: 1,
          borderColor: "#E0E0E0",
          justifyContent: "center",
        }}
        marginTop="64px"
        paddingTop={"64px"}
        gap={16}
      >
        <Grid item width={"422px"}>
          <Typography fontWeight="400" fontSize="16px" color="#FF9168">
            Faq
          </Typography>
          <Typography fontWeight="700" fontSize="48px">
            Frequently Asked Questions
          </Typography>
          <Typography
            fontWeight="500"
            fontSize="16px"
            color="#828282"
            marginBottom="24px"
          >
            Taman Ismail Marzuki (TIM) merupakan sebuah pusat kesenian dan
            kebudayaan
          </Typography>
          <Typography fontWeight="400" fontSize="16px" color="#1F1F1F">
            Ask us anything
          </Typography>
        </Grid>

        <Grid item width={"512px"}>
          <Typography fontWeight="400" fontSize="16px" color="#FF9168">
            Location
          </Typography>
          <Typography fontWeight="700" fontSize="48px">
            Pusat Kesenian Jakarta Taman Ismail Marzuki
          </Typography>
          <Typography
            fontWeight="500"
            fontSize="16px"
            color="#828282"
            marginBottom="24px"
          >
            Jalan Cikini Raya No.73, Cikini, Menteng, Kota Jakarta Pusat, Daerah
            Khusus Ibukota Jakarta 10330.
          </Typography>
          <Typography fontWeight="400" fontSize="16px" color="#1F1F1F">
            Phone : +62 821 2505 4242
          </Typography>
          <Typography fontWeight="400" fontSize="16px" color="#1F1F1F">
            Email : Info@TMI.com
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
