import React from "react";
import { Grid, Box } from "@mui/material";
// components
import CustomPagination from "../components/Pagination/index";

const Footer = ({ pagination, pageTarget, handleChangePageTarget, length }) => {
  return (
    <>
      <Grid
        container
        display="flex"
        flexDirection={"row"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box fontSize="14px">
          Menampilkan{" "}
          {(pagination?.current_page - 1) * pagination?.total_row_per_page + 1}{" "}
          sampai{" "}
          {(pagination?.current_page - 1) * pagination?.total_row_per_page +
            length}{" "}
          dari {pagination?.total_data} entri
        </Box>
        <CustomPagination
          count={pagination?.total_page}
          page={pageTarget}
          handleChangePage={handleChangePageTarget}
        />
      </Grid>
    </>
  );
};

export default Footer;
