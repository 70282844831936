import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Grid, Select, MenuItem, Button } from "@mui/material";
import { Icon } from "@iconify/react";
import ListResource from "./ListResource";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFacility,
  formEventState,
  addFacility,
  setDateRange,
} from "../../redux/feature/formEventSlice";
import useModal from "../../hooks/useModal";
import DatePicker from "../Elements/DatePicker";

// service
import EventServices from "../../services/EventServices";

const DetailSewa = ({ handleChange }) => {
  const dispatch = useDispatch();
  const { toggle: toggleDate } = useModal();
  const { formEventData } = useSelector(formEventState);
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [dateLocal, setDateLocal] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection",
    },
  ]);

  const { data } = useQuery(["location"], () => EventServices.getLocation());

  const handleDeleteFacility = (index) => {
    dispatch(deleteFacility(index));
  };

  const handleAddFacility = () => {
    dispatch(
      addFacility({
        key: "",
      })
    );
  };

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(
    formEventData?.startDate ? formEventData?.start_date : date[0]?.startDate
  );
  const secondDate = new Date(
    formEventData.endDate ? formEventData?.end_date : date[0]?.endDate
  );

  const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

  const handleDateChange = () => {
    dispatch(setDateRange(date[0]));
  };

  console.log(formEventData?.location_id);

  return (
    <>
      <Grid
        display={"flex"}
        direction="column"
        border={1}
        borderColor="#BDBDBD"
        padding="1rem"
        gap={2}
      >
        <Grid item fontWeight={"700"} fontSize="20px">
          Durasi Sewa
        </Grid>
        <Grid item color={"#828282"} fontWeight={"500"} fontSize="12px">
          Tentukan persis sesuai roundown acara dari mulai persiapan sampai
          selesai
        </Grid>
        {/* <FormControlLabel
          control={<Checkbox />}
          label="Sewa lebih dari 1 Hari"
        /> */}
        <Grid container justifyContent="space-between">
          <Grid item display={"flex"} direction="column" gap={2} xs={5}>
            <Grid item color={"#1F1F1F"} fontWeight={"500"} fontSize="14px">
              Tanggal Mulai
            </Grid>
            <DatePicker
              required={true}
              name={"start_date"}
              toggle={toggleDate}
              onChange={handleDateChange}
              date={date}
              setDate={setDate}
              setDateLocal={setDateLocal}
              value={
                formEventData.start_date
                  ? formEventData?.start_date
                  : dateLocal[0]?.startDate
              }
              placeholder={"Pilih Tanggal"}
            />
          </Grid>

          <Grid
            item
            height="1px"
            margin="4rem 0"
            backgroundColor="#D9D9D9"
            xs={0.3}
          ></Grid>

          <Grid item display={"flex"} direction="column" gap={2} xs={5}>
            <Grid item color={"#1F1F1F"} fontWeight={"500"} fontSize="14px">
              Tanggal Selesai
            </Grid>
            <DatePicker
              required={true}
              toggle={toggleDate}
              name={"end_date"}
              onChange={handleDateChange}
              date={date}
              setDate={setDate}
              setDateLocal={setDateLocal}
              value={
                formEventData.end_date
                  ? formEventData?.end_date
                  : dateLocal[0]?.endDate
              }
              placeholder={"Pilih Tanggal"}
            />
          </Grid>
        </Grid>
        <Grid
          item
          backgroundColor="#F5F5F5"
          color={"#1F1F1F"}
          fontWeight={"500"}
          fontSize="14px"
          padding="14px"
        >
          <Grid
            container
            display="flex"
            direction="row"
            alignItems={"center"}
            gap={2}
          >
            <Grid item paddingTop={"0.3rem"}>
              <Icon icon="mdi:information" color="#828282" />
            </Grid>
            <Grid item>Durasi Sewa {diffDays + 1} Hari</Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        display={"flex"}
        direction="column"
        border={1}
        borderColor="#BDBDBD"
        padding="1rem"
        gap={2}
      >
        <Grid item fontWeight={"700"} fontSize="20px">
          Gedung/Tempat
        </Grid>
        <Grid item color={"#828282"} fontWeight={"500"} fontSize="12px">
          Digunakan untuk verifikasi Event Organizer
        </Grid>
        <Grid item color={"#1F1F1F"} fontWeight={"500"} fontSize="14px">
          Tempat/Lokasi
        </Grid>
        <Select
          name="loc_id"
          required
          size="small"
          placeholder="Pilih Negara"
          value={formEventData?.loc_id}
          onChange={handleChange}
        >
          {data?.data?.data.map((item) => (
            <MenuItem value={item?.location_id} key={item?.location_id}>
              {item?.location_name}
            </MenuItem>
          ))}
        </Select>

        {/* resource list */}
        <ListResource
          resource={formEventData.facility}
          handleDeleteRes={handleDeleteFacility}
        />

        <Grid item>
          <Button
            style={{
              backgroundColor: "#D14F1E",
              color: "white",
              borderRadius: "0",
              // padding: "14px 24px 14px 24px",
            }}
            onClick={handleAddFacility}
          >
            + Tambah
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default DetailSewa;
