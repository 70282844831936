import styled from "@emotion/styled";
import {
  Grid,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
} from "@mui/material";
import React from "react";
import MainButton from "./MainButton";

import { Icon } from "@iconify/react";

function AdminInputX({
  label,
  type,
  helperText,
  onClick,
  onChange,
  name,
  InputProps,
  placeholder,
  otherProps,
  sx,
  required,
  disabled,
}) {
  return (
    <Grid container>
      <Grid item>
        <FormControl sx={{ marginBottom: "2rem", width: 253 }}>
          {label && (
            <FormLabel
              sx={{
                marginBottom: "0.5rem",
                color: "neutral.800",
                fontSize: "1.25rem",
                fontWeight: 700,
              }}
            >
              {required && (
                <span style={{ color: "#B43127", fontWeight: "bold" }}>*</span>
              )}
              {label}
            </FormLabel>
          )}

          <TextField
            type={type}
            name={name}
            placeholder={placeholder}
            required={required}
            // disabled={disabled}
            onClick={onClick}
            onChange={onChange}
            inputProps={{
              style: {
                fontSize: "0.875rem",
              },
            }}
            InputProps={InputProps}
            sx={{
              "& fieldset": {
                borderRadius: 0,
              },
              bgcolor: disabled ? "neutral.300" : "#FFFFFF",
              color: "neutral.700",

              ...sx,
            }}
            {...otherProps}
          />

          {helperText && (
            <FormHelperText
              sx={{
                marginX: 0,
                marginTop: "0.5rem",
                color: "neutral.600",
                fontSize: "0.625rem",
              }}
            >
              {helperText}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item>
        <MainButton variant={"contained"} sx={{ height: "3.3rem" }}>
          <Icon icon="ph:x" color="white" width="25" height="25" />
        </MainButton>
      </Grid>
    </Grid>
  );
}

export default AdminInputX;
