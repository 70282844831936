import React from "react";
import { Grid, Typography } from "@mui/material";
import MainButton from "../../components/Elements/MainButton";
const Buttons = ({ setSection, section, setLoginTitle, type }) => {
  const handleSignUp = () => {
    setLoginTitle(false);
    setSection("signUp");
  };
  return (
    <>
      <MainButton
        type={type}
        sx={{ marginTop: "1rem", backgroundColor: "#D14F1E" }}
        variant={"contained"}
      >
        Masuk
      </MainButton>
      <MainButton variant={"outlined"}>Atau Masuk dengan Google</MainButton>

      {section === "" && (
        <Grid item display={"flex"} direction={"row"} justifyContent={"center"}>
          <Typography fontSize={"14px"} marginRight={"0.5rem"}>
            Belum punya akun?
          </Typography>
          <Typography
            fontSize={"14px"}
            color="#D14F1E"
            sx={{ cursor: "pointer" }}
            onClick={handleSignUp}
          >
            Join Membership Sekarang
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default Buttons;
