import React from "react";
import { Box, Typography } from "@mui/material";

// component
import CustomModal from "../CustomModal/FilterDate";
import MainButton from "../Elements/MainButton";
import AdminTextArea from "../Elements/AdminTextArea";

const ReasonRejectModal = ({
  toggle,
  isShowing,
  handleClose,
  onBack,
  onSubmit,
}) => {
  return (
    <>
      <CustomModal
        toggle={toggle}
        isShowing={isShowing}
        handleClose={handleClose}
        width="27.563rem"
        isSquare
      >
        <Box
          sx={{
            padding: "3rem",
            minHeight: "18rem",
            minWidth: "25.688rem",
          }}
        >
          <Typography
            sx={{
              color: "neutral.800",
              fontSize: "1.25rem",
              fontWeight: 700,
              marginBottom: "2rem",
            }}
          >
            Alasan Penolakan
          </Typography>
          <AdminTextArea
            placeholder="Feedback Penolakan"
            multiline
            rows={3}
            maxRows={4}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "2rem",
            }}
          >
            <MainButton
              variant="outlined"
              sx={{
                border: "primary.500",
                color: "primary.500",
                height: "2.25rem",
                width: "100%",
              }}
              onClick={onBack}
            >
              Kembali
            </MainButton>
            <MainButton
              variant="contained"
              sx={{ bgcolor: "primary.500", height: "2.25rem", width: "100%" }}
              onClick={onSubmit}
            >
              Kirim
            </MainButton>
          </Box>
        </Box>
      </CustomModal>
    </>
  );
};

export default ReasonRejectModal;
