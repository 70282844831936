/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import JwtDecode from "jwt-decode";
import { useQuery } from "@tanstack/react-query";

import { setShowing } from "../redux/feature/loginAlertSlice";

// custom hooks
// import RoleService from "services/RoleService";

export default function useAuth() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const userTim = JSON.parse(localStorage.getItem("user-tim"));
  const currentPath = location.pathname.split("/")[1];

  const [isGranted, setIsGranted] = useState(false);
  const [userData, setUserData] = useState("");

  // const { data } = useQuery(
  //   ["roles", id],
  //   () => RoleService.getRoleById({ id }),
  //   {
  //     keepPreviousData: true,
  //     enabled: !!id,
  //   }
  // );

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (!userTim) {
        dispatch(setShowing(true));
        // navigate("/login");
      } else {
        const decoded = JwtDecode(userTim?.access_token);
        setUserData(decoded?.sub);
        if (Date.now() >= decoded?.exp * 1000) {
          localStorage.clear();
          navigate("/");
          window.location.reload();
        }
      }
    }
  }, []);

  return {
    isGranted,
    userData,
  };
}
