import React from "react";
import { Typography, Box } from "@mui/material";

const index = (props) => {
  const { children, value, index, title } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index || value === title ? (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      ) : null}
    </div>
  );
};

export default index;
