import React from "react";

// component
import TicketEvent from "./TicketEvent";

const List = ({ attachment }) => {
  const results = attachment?.map((_data, index) => (
    <TicketEvent attachment={attachment} key={index} />
  ));
  return <>{results}</>;
};

export default List;
