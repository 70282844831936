import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShowing: false,
};

export const loginAlertSlice = createSlice({
  name: "loginAlert",
  initialState,
  reducers: {
    setShowing: (state, action) => {
      state.isShowing = action.payload;
    },
  },
});

export const loginAlertState = (state) => state.loginAlert;

export const { setShowing } = loginAlertSlice.actions;

export default loginAlertSlice.reducer;
