import { Box } from "@mui/material";
import StatusTagAdmin from "../components/Elements/StatusTagAdmin";

export const tableHeadProposal = [
  { title: "ID", key: "id" },
  { title: "Nama Event", key: "nama_event" },
  { title: "Event Organizer", key: "event_organizer" },
  { title: "Tanggal Event", key: "date" },
  { title: "Lokasi", key: "location" },
  { title: "Status", key: "status" },
];
export const tableHeadUser = [
  { title: "ID", key: "id" },
  { title: "Username", key: "username" },
  { title: "Email", key: "email" },
  { title: "Password", key: "password" },
  {
    title: "Status",
    key: "status",
    render: function (content) {
      return (
        <>
          <Box sx={{ width: "fit-content", height: "fit-content" }}>
            <StatusTagAdmin variant="success">{content}</StatusTagAdmin>
          </Box>
        </>
      );
    },
  },
  { title: "Aksi", key: "action" },
];

export const tableHeadEventBase = [
  { title: "ID", key: "id" },
  { title: "Nama Event", key: "event_name" },
  { title: "Event Organizer", key: "event_organizer_name" },
  { title: "Tanggal Kegiatan", key: "start_date" },
  { title: "Lokasi", key: "loc_id" },
  { title: "Jumlah Tiket", key: "total_visitor" },
  {
    title: "Status",
    key: "status_event",
    render: function (content) {
      return (
        <>
          {content === "submit-plan-event" ? (
            <Box sx={{ width: "fit-content", height: "fit-content" }}>
              <StatusTagAdmin variant="pending">
                {"Menunggu Persetujuan"}
              </StatusTagAdmin>
            </Box>
          ) : content === "approve-plan-visit" ? (
            <Box sx={{ width: "fit-content", height: "fit-content" }}>
              <StatusTagAdmin variant="success">{"Disetujui"}</StatusTagAdmin>
            </Box>
          ) : (
            <Box sx={{ width: "fit-content", height: "fit-content" }}>
              <StatusTagAdmin variant="danger">{"Ditolak"}</StatusTagAdmin>
            </Box>
          )}
        </>
      );
    },
  },
];

export const tableHeadPostEventBase = [
  { title: "ID", key: "id" },
  { title: "Nama Event", key: "nama_event" },
  { title: "Tanggal Event", key: "date" },
  { title: "Paket", key: "package" },
  { title: "Lokasi", key: "location" },
  {
    title: "Status",
    key: "status",
    render: function (content) {
      return (
        <>
          <Box sx={{ width: "fit-content", height: "fit-content" }}>
            <StatusTagAdmin variant="success">{content}</StatusTagAdmin>
          </Box>
        </>
      );
    },
  },
];

export const tableHeadTicketEventBase = [
  { title: "ID", key: "id" },
  { title: "Nama Event", key: "nama_event" },
  { title: "Tanggal Event", key: "date" },
  { title: "Hasil Penjualan", key: "income" },
  { title: "Tiket Terjual", key: "sold" },
  { title: "Tiket Tersisa", key: "remaining" },
  {
    title: "Status",
    key: "status",
    render: function (content) {
      return (
        <>
          <Box sx={{ width: "fit-content", height: "fit-content" }}>
            <StatusTagAdmin variant="success">{content}</StatusTagAdmin>
          </Box>
        </>
      );
    },
  },
];

export const tableHeadRole = [
  { title: "No", key: "no" },
  { title: "Role", key: "role" },
  { title: "Home", key: "home" },
  { title: "Event", key: "event" },
  { title: "Collection", key: "collection" },
  { title: "User Management", key: "user" },
  { title: "Content", key: "content" },
  { title: "Aksi", key: "action" },
];
