import React, { useState } from "react";
import { Box, Grid } from "@mui/material";

import AdminTextArea from "../../Elements/AdminTextArea";
import MainButton from "../../Elements/MainButton";
import { TextField } from "@mui/material";

const Reject = ({ reject, setReject, setType }) => {
  return (
    <>
      {/* button fill its remaining row empty space */}
      <Box display={"flex"} flexDirection={"column"} width="300px">
        <form onSubmit={reject}>
          <Grid item fontWeight={"700"} fontSize="20px" marginBottom={"1rem"}>
            Alasan Penolakan
          </Grid>
          <TextField
            required={true}
            sx={{
              "& fieldset": {
                borderRadius: 0,
              },
              color: "neutral.700",
            }}
            fullWidth
            rows={4}
            multiline={4}
            placeholder={"Feedback Penolakan"}
            onChange={(e) => setType(e.target.value)}
          />

          <Box
            display={"flex"}
            gap={4}
            marginTop="1rem"
            justifyContent={"center"}
          >
            <MainButton
              sx={{ width: "100%" }}
              variant={"outlined"}
              onClick={() => setReject(false)}
            >
              Kembali
            </MainButton>

            <MainButton
              type={"submit"}
              sx={{ width: "100%" }}
              variant={"contained"}
            >
              Kirim
            </MainButton>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default Reject;
