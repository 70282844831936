import React from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import { Grid } from "@mui/material";
import AdminCard from "../../components/AdminCard";
import NewsAdmin from "../../assets/newsAdmin.png";
import Proposal from "../../assets/proposalEvent.png";
import Ticket from "../../assets/ticket.png";
import TotalPasien from "../../assets/totalPasien.png";

const Chart = () => {
  const data = [
    {
      name: "",
      uv: 0,
      pv: 0,
      amt: 0,
    },
    {
      name: "1",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "2",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "3",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "4",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "5",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "6",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "7",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  return (
    <>
      <Grid container padding="1.5rem" backgroundColor="white" gap={3}>
        <Grid container justifyContent={"space-between"}>
          <AdminCard
            image={NewsAdmin}
            title="News"
            stats="23"
            content="News Terunggah"
          />
          <AdminCard
            image={Ticket}
            title="Ticket"
            stats="1121"
            content="Tiket Terjual"
          />
          <AdminCard
            image={Proposal}
            title="Proposal Event"
            stats="80"
            content="Proposal Event"
          />
          <AdminCard
            image={Proposal}
            title="Post Event"
            stats="80"
            content="Post Event"
          />
        </Grid>
        <Grid container alignItems={"center"} gap={2}>
          <Grid item>
            <img src={TotalPasien} alt="" />
          </Grid>
          <Grid item fontWeight="700" fontSize="32px">
            Pengunjung Website
          </Grid>
        </Grid>
        <Grid item width={"100%"}>
          <ResponsiveContainer height={400}>
            <LineChart data={data}>
              <Line type="monotone" dataKey="uv" stroke="#D14F1E" />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="name" />
              <YAxis />
            </LineChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default Chart;
