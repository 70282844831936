import { Icon } from "@iconify/react";
import { Box, Typography } from "@mui/material";
import React from "react";

function Feedback({ message }) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderColor: "primary.600",
          borderWidth: "1px",
          borderStyle: "solid",
          padding: "1rem",
          width: "100%",
          bgcolor: "primary.100",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            marginBottom: "0.5rem",
          }}
        >
          <Icon
            icon="ic:outline-info"
            height="1.5rem"
            width="1.25rem"
            color="#B43127"
          />
          <Typography variant="body1" fontWeight="700" color="#B43127">
            Feedback
          </Typography>
        </Box>
        <Typography variant="caption" fontWeight="500" color="#B43127">
          {message}
        </Typography>
      </Box>
    </>
  );
}

export default Feedback;
