import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Icon } from "@iconify/react";

import Headline from "../../components/headline";
import Footer from "../../components/foter";
import { tableHeadTicketEventBase } from "../../utils/tableHead";
import SearchBarBase from "../../components/SearchbarBase";
import TableEventBase from "../../components/AdminEvent/TableEventBase";

const TicketEventBase = ({ title, setSection }) => {
  const [limit, setLimit] = useState(10);
  const [status, setStatus] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  const onChangeLimit = (e) => {
    setLimit(e.target.value);
  };

  const onChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleSelectItem = (id) => {
    let dataSelected = selectedItems.includes(id)
      ? selectedItems.filter((item) => item !== id)
      : [...selectedItems, id];

    setSelectedItems(dataSelected);
  };

  const handleSelectAll = () => {
    let dataSelected = [];
    if (selectedItems.length < data?.length) {
      data?.forEach((item) => {
        dataSelected.push(item.id);
      });
    }

    setSelectedItems(dataSelected);
  };

  const data = [
    {
      id: "1",
      nama_event: "Animakini X Ciffest 2023",
      date: "11 Sep 2023",
      income: "39.000.000",
      sold: "270",
      remaining: "230",
      status: "Disetujui",
    },
    {
      id: "2",
      nama_event: "Urang Minang Baralek Gadang",
      date: "8 Sep 2023",
      income: "39.000.000",
      sold: "270",
      remaining: "230",
      status: "Disetujui",
    },
    {
      id: "3",
      nama_event: "Pameran Akhir Tahun",
      date: "1 Sep 2023",
      income: "39.000.000",
      sold: "270",
      remaining: "230",
      status: "Disetujui",
    },
  ];

  const headline = (
    <>
      <Box display={"flex"} direction={"row"} alignItems={"center"} gap={2}>
        <Icon
          icon="mdi:clipboard-text-outline"
          color={"#1F1F1F"}
          width="2rem"
          height="2rem"
        />
        <Typography sx={{ fontSize: "2rem", fontWeight: "700" }}>
          List Event
        </Typography>
      </Box>
    </>
  );

  return (
    <>
      <Grid container backgroundColor="white" padding="1.5rem" gap={2}>
        <Headline title={headline} />
        <SearchBarBase
          limit={limit}
          status={status}
          onChangeLimit={onChangeLimit}
          onChangeStatus={onChangeStatus}
          selectedItems={selectedItems}
        />
        {/* table resposive check the grid container as well */}
        <TableEventBase
          header={tableHeadTicketEventBase}
          data={data}
          title={title}
          onSelectItem={handleSelectItem}
          selectedItems={selectedItems}
          onSelectAll={handleSelectAll}
        />
        <Footer
          pagination={{
            current_page: 1,
            total_row_per_page: 5,
            total_data: 50,
          }}
          length={50}
          // pageTarget={page}
          // handleChangePageTarget={handleChangePage}
        />
      </Grid>
    </>
  );
};
export default TicketEventBase;
