import React from "react";
import InputRow from "./InputRow";

const ListResource = ({ resource, handleDeleteRes }) => {
  const results = resource?.map((item, index) => (
    <InputRow item={item} index={index} handleDeleteRes={handleDeleteRes} />
  ));
  return <>{results?.length > 0 ? results : <>Tidak ada data</>}</>;
};

export default ListResource;
