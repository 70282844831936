import React, { useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import Headline from "../../components/headline";

import { Icon } from "@iconify/react";
import RequiredLabel from "../../components/Elements/RequiredLabel";
import AdminInput from "../../components/Elements/AdminInput";
import AdminInputX from "../../components/Elements/AdminInputX";
import AdminTextArea from "../../components/Elements/AdminTextArea";

const PurchaseOrder = ({ title, setSection }) => {
  const titleHeadline = (
    <Grid container alignItems={"center"} gap={2}>
      <Icon icon="mdi:information-outline" width="40" height="40" />
      <Typography fontSize={"32px"} fontWeight={"700"}>
        About
      </Typography>
    </Grid>
  );

  const editHeadline = (
    <Grid container alignItems={"center"} gap={1}>
      <Icon
        icon="material-symbols:edit-outline"
        color="white"
        width="20"
        height="20"
      />
      <Typography>Edit</Typography>
    </Grid>
  );

  return (
    <>
      <Grid container backgroundColor="white">
        <Grid container backgroundColor="white" padding="1.5rem" gap={2}>
          <Headline title={titleHeadline} content={editHeadline} />
          <Grid item xs={4}>
            <Grid item marginTop={"20px"}>
              <RequiredLabel label="Judul" />
            </Grid>
            <Grid item marginTop={"20px"}>
              <AdminInput placeholder={"Judul About"} />
            </Grid>

            <Grid item marginTop={"20px"}>
              <RequiredLabel label="Video" />
            </Grid>
            <Grid item marginTop={"20px"}>
              <AdminInputX placeholder="Upload Video" />
            </Grid>
            <Grid item marginTop={"20px"}>
              <RequiredLabel label="Image" />
            </Grid>
            <Grid item marginTop={"20px"}>
              <AdminInputX placeholder="Upload Image" />
            </Grid>
            <Grid item>
              <AdminInputX placeholder="Upload Image" />
            </Grid>
            <Grid item>
              <AdminInputX placeholder="Upload Image" />
            </Grid>
            <Grid item>
              <AdminInputX placeholder="Upload Image" />
            </Grid>
            <Grid item>
              <AdminInputX placeholder="Upload Image" />
            </Grid>

            <Grid item marginTop={"20px"}>
              <RequiredLabel label="Deskripsi" />
            </Grid>
            <Grid item marginTop={"20px"}>
              <AdminTextArea
                rows={3}
                multiline={true}
                placeholder={"Deskripsi About"}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PurchaseOrder;
