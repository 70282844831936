import React, { useState } from "react";

import Login from "./login";
import SignUp from "./SignUp";

const About = ({ setLoginTitle }) => {
  const [section, setSection] = useState("");
  const [value, setValue] = useState("Public Visitor");

  return (
    <>
      {section === "" ? (
        <Login
          setSection={setSection}
          value={value}
          setValue={setValue}
          section={section}
          setLoginTitle={setLoginTitle}
        />
      ) : (
        <SignUp
          setSection={setSection}
          value={value}
          section={section}
          setLoginTitle={setLoginTitle}
        />
      )}
    </>
  );
};

export default About;
