import React from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Card, Typography } from "@mui/material";

// component
import StatusTag from "../Elements/StatusTag";
import MainButton from "../Elements/MainButton";

function BookingHeadCard({ title, orderId, status, onShowQr }) {
  const navigate = useNavigate();

  return (
    <Card sx={{ padding: "1rem", borderRadius: 0 }}>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "start",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "start",
          }}
        >
          <Avatar
            alt="profile-picture"
            src="https://picsum.photos/72/72"
            sx={{
              width: "4.5rem",
              height: "4.5rem",
              fontSize: "9.375rem",
              marginBottom: "1rem",
            }}
            variant="square"
          />
          <Typography sx={{ fontSize: "1rem", fontWeight: 700 }}>
            {title}
          </Typography>
        </Box>
        <Typography
          sx={{ fontSize: "1rem", fontWeight: 600, color: "neutral.600" }}
        >
          Order ID : {orderId}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" color="neutral.800">
          Total Pembayaran
        </Typography>
        <Typography fontSize="1.25rem" fontWeight="700" color="primary">
          IDR 499.563
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "1rem",
          gap: "0.5rem",
        }}
      >
        <StatusTag>{status}</StatusTag>
        {status === "Menunggu Pembayaran" ? (
          <MainButton
            variant="outlined"
            sx={{ width: "fit-content" }}
            onClick={() => navigate("/detail-pembayaran")}
          >
            Lanjutkan Pembayaran
          </MainButton>
        ) : (
          <MainButton
            variant="contained"
            sx={{ width: "fit-content" }}
            onClick={onShowQr}
          >
            Lihat QR Code
          </MainButton>
        )}
      </Box>
    </Card>
  );
}

export default BookingHeadCard;
