import React, { useState } from "react";
import { Box, Collapse } from "@mui/material";
import EventHeader from "./EventHeader";
import PostEventForm from "./PostEventForm";
import { useNavigate } from "react-router-dom";

function PostEvent({ data }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          padding: "1rem",
          borderRadius: 0,
          width: "100%",
          bgcolor: "background.paper",
        }}
      >
        <EventHeader
          open={open}
          setOpen={setOpen}
          title="Post Event"
          status={data?.statusPost}
          feedback={data?.statusPostFeedback}
          isEdit={
            data?.statusPost === "Ditolak" || data?.statusPost === "Disetujui"
          }
          onEdit={setOpen}
        />
        <Collapse
          in={open && data.statusPost !== "Menunggu Approval Proposal Event"}
          timeout="auto"
          unmountOnExit
        >
          <PostEventForm status={data?.statusPost} />
        </Collapse>
      </Box>
    </>
  );
}

export default PostEvent;
