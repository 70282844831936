import React from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Grid,
  Box,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  Button,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { setFile, formEventState } from "../../redux/feature/formEventSlice";

// service
import EventServices from "../../services/EventServices";

const DetailEvent = ({ handleChange }) => {
  const dispatch = useDispatch();
  const onBtnAddFile = (e) => {
    dispatch(setFile(e.target.files[0]));
  };

  const { formEventData } = useSelector(formEventState);

  const { data } = useQuery(["event-type"], () => EventServices.getEventType());

  return (
    <>
      <Grid
        display={"flex"}
        direction="column"
        border={1}
        borderColor="#BDBDBD"
        padding="1rem"
        gap={2}
      >
        <Grid item fontWeight={"700"} fontSize="20px">
          Data Event
        </Grid>
        <Grid item color={"#828282"} fontWeight={"500"} fontSize="12px">
          Tentukan persis sesuai data asli dalam dokumen resmi
        </Grid>
        <Grid item color={"#1F1F1F"} fontWeight={"500"} fontSize="14px">
          Nama Event
        </Grid>
        <TextField
          required
          size="small"
          name="event_name"
          value={formEventData?.event_name}
          onChange={handleChange}
          placeholder="Masukan Nama Event"
        />
        <Grid item color={"#1F1F1F"} fontWeight={"500"} fontSize="14px">
          Jenis Event
        </Grid>
        <Select
          required
          name="event_type"
          size="small"
          value={formEventData?.event_type}
          onChange={handleChange}
        >
          {data?.data?.data.map((item) => (
            <MenuItem value={item?.id} key={item?.id}>
              {item?.event_type_name}
            </MenuItem>
          ))}
        </Select>
        <Grid item color={"#1F1F1F"} fontWeight={"500"} fontSize="14px">
          Detail Event
        </Grid>
        <TextField
          required
          name="event_detail"
          multiline
          rows={4}
          placeholder="Masukan Detail Event"
          value={formEventData?.event_detail}
          onChange={handleChange}
        />
        <Grid item color={"#1F1F1F"} fontWeight={"500"} fontSize="14px">
          Jumlah Pengunjung/Tiket
        </Grid>
        <TextField
          required
          size="small"
          name="total_visitor"
          onChange={handleChange}
          value={formEventData?.total_visitor}
          placeholder="Masukan Jumlah Pengunjung/Tiket"
          InputProps={{
            endAdornment: <InputAdornment position="end">Orang</InputAdornment>,
          }}
        />
      </Grid>
      <Grid
        display={"flex"}
        direction="column"
        border={1}
        borderColor="#BDBDBD"
        padding="1rem"
        gap={2}
      >
        <Grid item fontWeight={"700"} fontSize="20px">
          Dokumen Event
        </Grid>
        <Grid item color={"#828282"} fontWeight={"500"} fontSize="12px">
          Masukan dokumen proposal event
        </Grid>
        <Grid
          item
          container
          display={"flex"}
          flexDirection="column"
          gap={2}
          sx={{
            backgroundColor: "#FFF1EB",
            border: "1px dashed #FF723D",
            padding: "1rem 0 1rem 0",
          }}
          // backgroundColor="yellow"
        >
          <Grid item sx={{ margin: "auto" }}>
            <Icon icon="bi:cloud-upload" color="#FF723D" fontSize={70} />
          </Grid>
          <Grid item sx={{ margin: "auto" }} fontSize={"0.875rem"}>
            Upload file
          </Grid>
          <Grid item sx={{ margin: "auto" }} fontSize={"0.875rem"}></Grid>
          <Grid
            item
            sx={{ margin: "auto", marginBottom: "0.5rem" }}
            fontSize={"0.875rem"}
          ></Grid>
          <Grid item sx={{ margin: "auto" }} fontSize={"0.875rem"}>
            <Button
              style={{
                backgroundColor: "#D14F1E",
              }}
              variant="contained"
              component="label"
              onChange={onBtnAddFile}
              sx={{ boxShadow: "none" }}
            >
              <input
                required
                multiple
                type="file"
                style={{ marginLeft: "4rem", cursor: "pointer" }}
              />
            </Button>
          </Grid>
        </Grid>
        <Grid item display={"flex"} fontWeight="400" fontSize="14px">
          <Box color={"#FF723D"}>*</Box>
          Format file: .pdf, .pptx | Ukuran file max 10Mb
        </Grid>
      </Grid>
    </>
  );
};

export default DetailEvent;
