import { Avatar, Box, Button, Grid, Input, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";

// component
import MainButton from "../Elements/MainButton";
import StatusTag from "../Elements/StatusTag";
import MainInput from "../Elements/MainInput";
import { userState } from "../../redux/feature/userSlice";

function UserProfileForm({ onResetPassword }) {
  const [file, setFile] = useState();
  const { userData } = useSelector(userState);

  return (
    <>
      <Grid
        container
        sx={{
          gap: "2rem",
          padding: "2rem",
          bgcolor: "background.paper",
        }}
      >
        <Grid item>
          <Box
            sx={{
              padding: "1rem",
              boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.1)",
              width: "14.5rem",
            }}
          >
            {file ? (
              <Avatar
                alt="profile-picture"
                src={URL.createObjectURL(file)}
                sx={{
                  border: "4px solid",
                  borderColor: "neutral.400",
                  width: "12.5rem",
                  height: "12.5rem",
                  fontSize: "9.375rem",
                  marginBottom: "1rem",
                }}
                variant="square"
              />
            ) : (
              <Avatar
                sx={{
                  bgcolor: "primary.500",
                  border: "4px solid",
                  borderColor: "neutral.400",
                  width: "12.5rem",
                  height: "12.5rem",
                  fontSize: "9.375rem",
                  marginBottom: "1rem",
                }}
                variant="square"
              >
                {userData.initial}
              </Avatar>
            )}

            <label
              style={{
                border: "1px solid #D14F1E",
                color: "#D14F1E",
                width: "100%",
                display: "inline-block",
                height: "3rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                fontSize: "0.875rem",
                fontWeight: 700,
              }}
            >
              Upload File
              <Input
                id="file-upload"
                accept="image/*"
                type="file"
                style={{ display: "none" }}
                onChange={(e) => setFile(e.target.files[0])}
              />
            </label>
            <Typography variant="body2" sx={{ marginTop: "1rem" }}>
              <span style={{ color: "#D14F1E" }}>*</span>Format file: .jpg,
              .jpeg, .png | Ukuran file max 10Mb
            </Typography>
          </Box>
          <MainButton
            variant="outlined"
            sx={{ width: "100%", marginTop: "2rem" }}
            onClick={onResetPassword}
          >
            Ubah Kata Sandi
          </MainButton>
        </Grid>
        <Grid item sx={{ flex: 1 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              fontSize: "0.875rem",
              marginBottom: "2rem",
            }}
          >
            <StatusTag>Active Membership</StatusTag>
            <span>Unlimited</span>
          </div>
          <form>
            <MainInput
              label="Nama"
              placeholder="Masukkan Nama"
              otherProps={{ defaultValue: userData.name }}
            />
            <MainInput
              label="No HP"
              placeholder="Masukkan No HP"
              otherProps={{ defaultValue: userData.phone }}
            />
            <MainInput
              label="Email"
              type="email"
              placeholder="Masukkan Email"
              otherProps={{ defaultValue: userData.email }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "1rem",
              }}
            >
              <MainButton variant="contained" size="large">
                Simpan
              </MainButton>
            </div>
          </form>
        </Grid>
      </Grid>
    </>
  );
}

export default UserProfileForm;
