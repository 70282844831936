import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: {
    email: "",
    id: "",
    name: "",
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
  },
});

export const userState = (state) => state.user;

export const { setUserData } = userSlice.actions;

export default userSlice.reducer;
