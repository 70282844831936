import React from "react";

function StatusTagAdmin({ children, variant }) {
  const styleTag = () => {
    switch (variant) {
      case "danger":
        return {
          backgroundColor: "#FFD5DA",
          color: "#CA5471",
          padding: "0.5rem 1rem",
          fontSize: "12px",
          fontWeight: 700,
          border: "0.063rem solid #CA5471",
          borderRadius: "0.25rem",
        };
      case "success":
        return {
          backgroundColor: "#E0FFD5",
          color: "#81CA54",
          padding: "0.5rem 1rem",
          fontSize: "12px",
          fontWeight: 700,
          border: "0.063rem solid #81CA54",
          borderRadius: "0.25rem",
          width: "fit-content",
        };
      case "basic":
        return {
          backgroundColor: "#FFF1EB",
          color: "#D14F1E",
          padding: "0.5rem 1rem",
          fontSize: "0.875rem",
          fontWeight: 700,
        };
      case "pending":
        return {
          backgroundColor: "#F7FFD5",
          color: "#C8CA54",
          padding: "0.5rem 1rem",
          fontSize: "12px",
          border: "0.063rem solid #C8CA54",
          borderRadius: "0.25rem",
          fontWeight: 700,
          textAlign: "center",
        };
      default:
        return {
          backgroundColor: "#FFF1EB",
          color: "#D14F1E",
          padding: "0.5rem 1rem",
          fontSize: "0.875rem",
          fontWeight: 700,
        };
    }
  };
  return <div style={styleTag()}>{children}</div>;
}

export default StatusTagAdmin;
