import React from "react";
import { Icon } from "@iconify/react";
import { Avatar, Box, Card, Divider, Typography } from "@mui/material";

// component
import StatusTag from "../Elements/StatusTag";
import MainButton from "../Elements/MainButton";

function BookingCard({
  title,
  orderId,
  ticketAmount,
  date,
  status,
  onShowDetail,
}) {
  return (
    <Card sx={{ padding: "1rem", borderRadius: 0 }}>
      <Typography
        sx={{ fontSize: "1rem", fontWeight: 600, color: "neutral.600" }}
      >
        Order ID : {orderId}
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "start",
          marginTop: "0.5rem",
        }}
      >
        <Avatar
          alt="profile-picture"
          src="https://picsum.photos/72/72"
          sx={{
            width: "4.5rem",
            height: "4.5rem",
            fontSize: "9.375rem",
            marginBottom: "1rem",
          }}
          variant="square"
        />
        <Typography sx={{ fontSize: "1rem", fontWeight: 700 }}>
          {title}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="caption" color="neutral.600">
          {ticketAmount} Tiket
        </Typography>
        <Divider
          sx={{ height: "1.5rem", bgcolor: "neutral.600", marginX: "1rem" }}
          orientation="vertical"
        />
        <Box sx={{ display: "flex", gap: "0.688rem", alignItems: "center" }}>
          <Icon
            icon="mdi:calendar-range"
            color="#828282"
            height="1.25rem"
            width="1.25rem"
          />
          <Typography variant="caption" color="neutral.600">
            {date}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "0.688rem",
          gap: "0.5rem",
        }}
      >
        <StatusTag>{status}</StatusTag>
        <MainButton
          variant="outlined"
          sx={{ width: "fit-content" }}
          onClick={onShowDetail}
        >
          Lihat Detail
        </MainButton>
      </Box>
    </Card>
  );
}

export default BookingCard;
