import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ProSidebarProvider } from "react-pro-sidebar";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";

import App from "./App";
import store from "./redux/store";
import "./index.css";
import "react-date-range/dist/styles.css";

// For GET requests
axios.interceptors.request.use((config) => {
  const user = JSON.parse(localStorage.getItem("user-tim"));
  if (user?.access_token) {
    config.headers.Authorization = `Bearer ${user.access_token}`;
  }
  config.headers["Access-Control-Allow-Origin"] = "*";
  return config;
});

// For POST requests
axios.interceptors.response.use(
  (response) => {
    if (response?.data?.code === 500) {
      console.error(response?.data?.message);
      console.clearWaitingQueue();
    }
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      console.error(error.response?.data?.detail_message?.message);
      console.clearWaitingQueue();
    }
    if (error.response?.status === 404) {
      console.error("The requested resource was not found.");
      console.clearWaitingQueue();
    }
    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <ProSidebarProvider>
          <App />
        </ProSidebarProvider>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);
