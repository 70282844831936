import React from "react";
import { Grid, Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import MainButton from "./Elements/MainButton";
import Edit from "../assets/Edit.png";
import Hapus from "../assets/hapus.png";
import Persetujuan from "../assets/persetujuan.png";

import useModal from "../hooks/useModal";
import { useState } from "react";

const TableBig = ({
  total,
  result,
  onEdit,
  title,
  ijt,
  setSection,
  onSelectItem,
  onDelete,
  selectedItems,
  onSelectAll,
  data,
  header,
  numbering,
  isGranted,
}) => {
  const { isShowing: isShowingDelete, toggle: toggleDelete } = useModal();
  const [selectedId, setSelectedId] = useState();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#FFECEC",
      color: "#FF723D",
      //   border: "1px solid #D12C24",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: "#FF723D",
    },
  }));

  const renderCell = (row, index) => {
    return (
      <TableRow key={index}>
        <TableCell
          style={{
            position: "sticky",
            left: "0",
            background: "white",
          }}
        >
          <input
            style={{ width: "18px", height: "18px" }}
            type={"checkbox"}
            name="foo"
            checked={selectedItems?.includes(row.id)}
            id={row.id}
            onChange={() => onSelectItem(row.id)}
          />
        </TableCell>
        {numbering && <TableCell align="left">{index + 1}</TableCell>}

        {header?.map((item) => {
          if (item.key === "status") {
            if (row["status"] === "pending") {
              return (
                <TableCell align="left" key={item.key}>
                  <img src={Persetujuan} alt="" />
                </TableCell>
              );
            }
          } else if (item.key === "action") {
            return (
              <TableCell align="left">
                <Grid container gap={2} sx={{ width: "200px" }}>
                  <MainButton variant={"contained"}>
                    <img src={Edit} alt="" />
                    <Box marginLeft={"0.5rem"}>Edit</Box>
                  </MainButton>
                  <MainButton variant={"outlined"}>
                    <img src={Hapus} alt="" />
                    <Box marginLeft={"0.5rem"}>Hapus</Box>
                  </MainButton>
                </Grid>
              </TableCell>
            );
          } else {
            return (
              <TableCell align="left" key={item.key}>
                {row[item.key] ? row[item.key] : "-"}
              </TableCell>
            );
          }
        })}
      </TableRow>
    );
  };

  const renderHead = () => {
    return (
      <TableRow>
        <StyledTableCell style={{ position: "sticky", left: "0" }}>
          <input
            style={{ width: "18px", height: "18px" }}
            type={"checkbox"}
            name="select-all"
            id="all"
            checked={selectedItems?.length === data?.length}
            onChange={onSelectAll}
          />
        </StyledTableCell>
        {numbering && <StyledTableCell>No</StyledTableCell>}
        {header?.map((item) => (
          <StyledTableCell>{item.title}</StyledTableCell>
        ))}
      </TableRow>
    );
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" sx={{ minWidth: 650 }}>
          <TableHead>{renderHead()}</TableHead>
          <TableBody>
            {data?.map((row, index) => renderCell(row, index))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableBig;
