import React, { useState } from "react";
import { Grid } from "@mui/material";
import { Icon } from "@iconify/react";

import Headline from "../../components/headline";
import Footer from "../../components/foter";
import SearchBar from "../../components/Searchbar";
import List from "../../components/AdminHome/List";

const TicketEvents = () => {
  const [attachment, setAttachment] = useState([
    { id: 1 },
    { id: 1 },
    { id: 1 },
    { id: 1 },
    { id: 1 },
    { id: 1 },
  ]);
  const [limit, setLimit] = useState(10);
  const [status, setStatus] = useState("");
  const onChangeLimit = (e) => {
    setLimit(e.target.value);
  };
  const onChangeStatus = (e) => {
    setStatus(e.target.value);
  };
  const headline = (
    <>
      <Grid
        container
        display={"flex"}
        direction={"row"}
        alignItems={"center"}
        gap={2}
      >
        <Grid item paddingTop={"0.3rem"}>
          <Icon
            icon="mdi:clipboard-text-outline"
            color={"#1F1F1F"}
            width="20"
            height="20"
          />
        </Grid>
        <Grid item>Detail Event</Grid>
      </Grid>
    </>
  );
  return (
    <>
      <Grid
        container
        padding="1.5rem"
        gap={2}
        backgroundColor="white"
        justifyContent="space-between"
      >
        <Headline title={headline} />
        <SearchBar
          limit={limit}
          status={status}
          onChangeLimit={onChangeLimit}
          onChangeStatus={onChangeStatus}
          ticketEvent={true}
        />
        {/* mapping  */}
        <List attachment={attachment} />
        {/* <TicketEvent />
        <TicketEvent />
        <TicketEvent />
        <TicketEvent />
        <TicketEvent />
        <TicketEvent /> */}
        <Footer />
      </Grid>
    </>
  );
};

export default TicketEvents;
