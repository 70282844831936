import React from "react";
import { Grid } from "@mui/material";

const RequiredLabel = ({ label, children }) => {
  return (
    <>
      <Grid container>
        <Grid item color="#B43127">
          *
        </Grid>
        <Grid item fontWeight={"700"} fontSize="20px">
          {label || children}
        </Grid>
      </Grid>
    </>
  );
};

export default RequiredLabel;
