import React from "react";
import { Grid } from "@mui/material";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";

//components
import Navbar from "./Navbar";
import "../styles/app.css";

const LayoutSidebar = ({ children, toggleSidebar, handleToggle }) => {
  const navigate = useNavigate();
  const { collapseSidebar, collapsed } = useProSidebar();

  return (
    <>
      <Grid container display={"flex"} direction="row">
        <Grid item>
          <Sidebar
            collapsed={toggleSidebar}
            style={{
              position: "fixed",
              height: "100vh",
              zIndex: 1031,
              backgroundColor: "#FFFFFF",
              boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Menu
              style={{
                textAlign: "center",
                color: "#FF723D",
                fontWeight: "600",
                fontSize: "24px",
              }}
            >
              <MenuItem>TIM</MenuItem>
            </Menu>
            <Menu
              menuItemStyles={{
                button: ({ active }) => {
                  // only apply styles on first level elements of the tree

                  return {
                    // backgroundColor: active ?  : undefined,
                    backgroundColor: active ? "#F5F5F5" : null,
                    borderRight: active ? "#FF723D 7px solid" : null,
                    fontWeight: "600",
                    fontStyle: "normal",
                    fontSize: "14px",
                    lineHeight: "150%",
                    letterSpacing: "-0.202759px",
                    color: active ? "#A81811" : "#444444",
                  };
                },
              }}
            >
              <MenuItem
                onClick={() => navigate("/admin")}
                active={window.location.pathname === "/admin"}
                // style={{ backgroundColor: active ? "#eecef9" : undefined }}
              >
                <Grid container gap={2}>
                  <Grid item margin={collapsed ? "0.4rem" : null}>
                    <Icon
                      icon="material-symbols:home-outline"
                      color={
                        window.location.pathname === "/admin"
                          ? " #FF723D"
                          : "#bdbdbd"
                      }
                      width="20"
                      height="20"
                    />
                  </Grid>
                  {collapsed ? null : (
                    <Grid item fontWeight={"500"}>
                      Home
                    </Grid>
                  )}
                </Grid>
              </MenuItem>
              <MenuItem
                onClick={() => navigate("/admin-Event")}
                active={window.location.pathname === "/admin-Event"}
              >
                <Grid container gap={2}>
                  <Grid item margin={collapsed ? "0.4rem" : null}>
                    <Icon
                      icon="mdi:clipboard-text-outline"
                      color={
                        window.location.pathname === "/admin-Event"
                          ? "#D12C24"
                          : "#bdbdbd"
                      }
                      width="20"
                      height="20"
                    />
                  </Grid>
                  {collapsed ? null : (
                    <Grid item fontWeight={"500"}>
                      Event
                    </Grid>
                  )}
                </Grid>
              </MenuItem>
              <MenuItem
                onClick={() => navigate("/inventory-journal")}
                active={window.location.pathname === "/inventory-journal"}
              >
                <Grid container gap={2}>
                  <Grid item margin={collapsed ? "0.4rem" : null}>
                    <Icon
                      icon="material-symbols:inventory-2-outline"
                      color={
                        window.location.pathname === "/inventory-journal"
                          ? "#D12C24"
                          : "#bdbdbd"
                      }
                      width="20"
                      height="20"
                    />
                  </Grid>
                  {collapsed ? null : (
                    <Grid item fontWeight={"500"}>
                      Collection
                    </Grid>
                  )}
                </Grid>
              </MenuItem>
              <MenuItem
                onClick={() => navigate("/listAdmin")}
                active={window.location.pathname === "/listAdmin"}
              >
                <Grid container gap={2}>
                  <Grid item margin={collapsed ? "0.4rem" : null}>
                    <Icon
                      icon="mdi:user-check-outline"
                      color={
                        window.location.pathname === "/listAdmin"
                          ? "#D12C24"
                          : "#bdbdbd"
                      }
                      width="20"
                      height="20"
                    />
                  </Grid>
                  {collapsed ? null : (
                    <Grid item fontWeight={"500"}>
                      User Management
                    </Grid>
                  )}
                </Grid>
              </MenuItem>
              <MenuItem
                onClick={() => navigate("/admin-content-about")}
                active={
                  window.location.pathname === "/admin-content-about" ||
                  window.location.pathname === "/admin-content-admission"
                }
              >
                <Grid container gap={2}>
                  <Grid item margin={collapsed ? "0.4rem" : null}>
                    <Icon
                      icon="ph:files-light"
                      color={
                        window.location.pathname === "/admin-content-about" ||
                        window.location.pathname === "/admin-content-admission"
                          ? "#D12C24"
                          : "#bdbdbd"
                      }
                      width="20"
                      height="20"
                    />
                  </Grid>
                  {collapsed ? null : (
                    <Grid item fontWeight={"500"}>
                      Content
                    </Grid>
                  )}
                </Grid>
              </MenuItem>
              <Menu
                style={{
                  position: "fixed",
                  bottom: "0",
                  width: collapsed ? "5rem" : "15.6rem",
                }}
              >
                <MenuItem>
                  <Grid container gap={2}>
                    <Grid item margin={collapsed ? "0.4rem" : null}>
                      <Icon
                        icon="bx:log-out-circle"
                        color="#bdbdbd"
                        width="20"
                        height="20"
                      />
                    </Grid>
                    {collapsed ? null : (
                      <Grid item fontWeight={"500"} fontSize="14px">
                        Logout
                      </Grid>
                    )}
                  </Grid>
                </MenuItem>
                <MenuItem>
                  <Grid container gap={2} alignItems="center">
                    <Grid item margin={collapsed ? "0.4rem" : null}>
                      <Icon
                        icon="radix-icons:avatar"
                        color="#bdbdbd"
                        width="20"
                        height="20"
                      />
                    </Grid>
                    {collapsed ? null : (
                      <Grid item display={"flex"} direction="column">
                        <Grid item fontWeight={"500"} fontSize="14px">
                          Louise Thompson
                        </Grid>
                        <Grid item fontWeight={"400"} fontSize="12px">
                          Admin
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </MenuItem>
              </Menu>
            </Menu>

            {/* <div style={{ position: "fixed", bottom: "0" }}>
              <Menu
                menuItemStyles={{
                  button: ({ active }) => {
                    // only apply styles on first level elements of the tree

                    return {
                      // backgroundColor: active ?  : undefined,
                      backgroundColor: active ? "#F5F5F5" : null,
                      borderRight: active ? "#D12C24 7px solid" : null,
                      fontWeight: "600",
                      fontStyle: "normal",
                      fontSize: "14px",
                      lineHeight: "150%",
                      letterSpacing: "-0.202759px",
                      color: active ? "#A81811" : "#444444",
                    };
                  },
                }}
              >
                <MenuItem>
                  <Grid container gap={2}>
                    <Grid item>
                      <Icon
                        icon="bx:log-out-circle"
                        color="#bdbdbd"
                        width="20"
                        height="20"
                      />
                    </Grid>
                    {collapsed ? null : (
                      <Grid item fontWeight={"500"}>
                        Logout
                      </Grid>
                    )}
                  </Grid>
                </MenuItem>
                <MenuItem>Thompson</MenuItem>
              </Menu>
            </div> */}
          </Sidebar>
        </Grid>
        <Grid item>
          <Grid item container display={"flex"} direction={"column"}>
            <Grid item>
              <Navbar collapseSidebar={collapseSidebar} collapsed={collapsed} />
            </Grid>
            <Grid
              item
              sx={{
                width: "100vw", // always start with the parent container
                padding: collapsed
                  ? "3.5rem 1rem 1rem 5.7rem"
                  : "3.5rem 1rem 1rem 16.5rem",
              }}
            >
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LayoutSidebar;
