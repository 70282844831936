import React from "react";
import { Grid } from "@mui/material";

import Ticket from "../../assets/ticketOrange.png";

const TicketEvent = () => {
  return (
    <>
      <Grid
        container
        sx={{
          padding: "24px",
          border: 1,
          borderColor: "#FF723D",
          justifyContent: "space-between",
        }}
        xs={5.8}
      >
        <Grid item display={"flex"} direction={"column"} gap={2}>
          <Grid item display={"flex"} direction={"column"}>
            <Grid item fontWeight="400" fontSize="14px">
              Kentang Production
            </Grid>
            <Grid item fontWeight="700" fontSize="24px">
              Animakini X Ciffest 2023
            </Grid>
          </Grid>
          <Grid item display={"flex"} direction={"column"}>
            <Grid item fontWeight="400" fontSize="14px">
              Hasil Penjualan
            </Grid>
            <Grid item fontWeight="700" fontSize="24px">
              Rp 39.000.000
            </Grid>
          </Grid>
        </Grid>
        <Grid item display={"flex"} direction={"column"}>
          <Grid item fontWeight="400" fontSize="14px">
            Tiket Telah Terjual
          </Grid>
          <Grid item display={"flex"} fontWeight="700" fontSize="24px" gap={1}>
            <Grid item>
              <img src={Ticket} alt="" />
            </Grid>
            <Grid item>270/600</Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TicketEvent;
