import React, { useState } from "react";
import InfoEo from "./InfoEo";
import DetailEvent from "./DetailEvent";
import DetailSewa from "./DetailSewa";
import { useDispatch, useSelector } from "react-redux";
import { formEventState } from "../../redux/feature/formEventSlice";
import { setFormEventData } from "../../redux/feature/formEventSlice";

const FormInputs = () => {
  const dispatch = useDispatch();
  const { page, formEventData } = useSelector(formEventState);
  const [filter, setFilter] = useState({
    provinsi_id: formEventData?.provinsi_id ? formEventData?.provinsi_id : null,
    kota_id: formEventData?.kota_id ? formEventData?.kota_id : null,
    kec_id: null,
  });

  const handleChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    if (e.target.name === "provinsi_id") {
      setFilter({
        ...filter,
        [e.target.name]: fieldValue,
        kota_id: null,
        kec_id: null,
      });
    } else if (e.target.name === "kota_id") {
      setFilter({
        ...filter,
        [e.target.name]: fieldValue,
        kec_id: null,
      });
    } else if (e.target.name === "kec_id") {
      setFilter({
        ...filter,
        [e.target.name]: fieldValue,
      });
    } else {
      setFilter({
        ...filter,
        [e.target.name]: fieldValue,
      });
    }

    dispatch(
      setFormEventData({
        name: fieldName,
        value: fieldValue,
      })
    );
  };

  const display = {
    0: <InfoEo handleChange={handleChange} filter={filter} />,
    1: <DetailSewa handleChange={handleChange} />,
    2: <DetailEvent handleChange={handleChange} />,
  };

  return <>{display[page]}</>;
};

export default FormInputs;
