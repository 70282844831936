import React, { useState } from "react";
import { Grid, Button } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { Icon } from "@iconify/react";

import LatestNews from "../components/LatestNews";
import Card from "../components/Card";

import Event1 from "../assets/event1.jpg";
import Event2 from "../assets/event2.jpg";
import Event3 from "../assets/event3.jpg";

import TabPanel from "../components/Tabs";
import TabsMasterData from "../components/TabsMasterData";
import CarouselList from "../components/CarouselList";

const Home = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const items = [
    { Event1, Event2, Event3 },
    { Event1, Event2, Event3 },
  ];
  return (
    <>
      <Grid display={"flex"} direction="column" gap={2}>
        <Grid item display={"flex"} direction="column">
          <Grid
            item
            display={"flex"}
            direction="column"
            padding="2rem 10rem"
            gap={4}
          >
            <Grid
              item
              display={"flex"}
              direction="row"
              alignItems={"center"}
              gap={2}
            >
              <Grid item paddingTop="0.1rem">
                <Icon
                  icon="material-symbols:home-outline"
                  color="#1f1f1f"
                  width="16"
                  height="16"
                />
              </Grid>
              <Grid item fontWeight="500" fontSize="16px">
                Home
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                item
                display={"flex"}
                direction="row"
                justifyContent={"flex-end"}
                gap={1}
              >
                <Grid
                  item
                  height="1px"
                  backgroundColor="#D14F1E"
                  width={"3rem"}
                  marginTop="0.9rem"
                ></Grid>
                <Grid item color="#D14F1E" fontWeight="700" fontSize="20px">
                  Event
                </Grid>
              </Grid>
            </Grid>
            <Grid item display={"flex"} direction="row" gap={1}>
              <Card photo={Event1} />
              <Card photo={Event2} />
              <Card photo={Event3} />
            </Grid>

            <Grid item textAlign={"center"}>
              <Button
                style={{
                  color: "#D14F1E",
                  fontWeight: "700",
                  fontSize: "14px",
                  cursor: "pointer",
                  textTransform: "none",
                }}
                disableElevation
              >
                View More {">"}
              </Button>
            </Grid>
            <Grid item>
              <TabsMasterData value={value} handleChange={handleChange} />
              <TabPanel value={value} index={0}></TabPanel>
              <TabPanel value={value} index={1}></TabPanel>
              <TabPanel value={value} index={2}></TabPanel>
              <TabPanel value={value} index={3}></TabPanel>
              <TabPanel value={value} index={4}></TabPanel>
            </Grid>
          </Grid>
          <Grid item padding="0 6rem">
            <Carousel
              animation="slide"
              autoPlay={false}
              navButtonsProps={{
                // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                style: {
                  backgroundColor: "#D14F1E",
                },
              }}
              indicators={false}
              navButtonsAlwaysVisible={true}
            >
              {/* <CarouselList data={items} /> */}
              {items.map((item, i) => (
                <CarouselList key={i} data={item} />
              ))}
            </Carousel>
          </Grid>
          {/* <Card photo={Event1} />
              <Card photo={Event2} />
              <Card photo={Event3} /> */}
          {/* <Grid item>
                <Button
                  style={{
                    height: "8vh",
                    color: "white",
                    borderRadius: "50%",
                    backgroundColor: "#D14F1E",
                  }}
                >
                  {">"}
                </Button>
              </Grid> */}
          {/* </Grid> */}
        </Grid>
        <Grid item textAlign={"center"} marginBottom="3rem">
          <Button
            style={{
              color: "#D14F1E",
              fontWeight: "700",
              fontSize: "14px",
              cursor: "pointer",
              textTransform: "none",
            }}
            disableElevation
          >
            View All {">"}
          </Button>
        </Grid>

        <LatestNews />
      </Grid>
    </>
  );
};

export default Home;
