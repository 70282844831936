import React, { useState } from "react";

// components
import TableEvent from "./TableEvent";
import ApprovalInfoEO from "./ApprovalInfoEO";

const Index = () => {
  const [section, setSection] = useState("");

  return (
    <>
      {section === "proposal" ? (
        <ApprovalInfoEO setSection={setSection} />
      ) : (
        <TableEvent setSection={setSection} />
      )}
    </>
  );
};

export default Index;
