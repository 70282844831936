import { Box, FormLabel, Paper, Typography } from "@mui/material";
import React from "react";
import Headline from "../../components/headline";
import { Icon } from "@iconify/react";
import AdminInput from "../../components/Elements/AdminInput";
import AdminTextArea from "../../components/Elements/AdminTextArea";
import MainButton from "../../components/Elements/MainButton";
import { useNavigate } from "react-router-dom";
import LargeImagePreview from "../../components/LargeImagePreview";
import MainStepper from "../../components/Elements/MainStepper";

const ApprovalEventDescription = () => {
  const navigate = useNavigate();

  const headline = (
    <>
      <Box display={"flex"} direction={"row"} alignItems={"center"} gap={2}>
        <Icon
          icon="mdi:clipboard-text-outline"
          color={"#1F1F1F"}
          width="2rem"
          height="2rem"
        />
        <Typography sx={{ fontSize: "2rem", fontWeight: "700" }}>
          Deskripsi Event
        </Typography>
      </Box>
    </>
  );
  return (
    <>
      <Paper sx={{ padding: "1.5rem", borderRadius: 0 }}>
        <Box sx={{ width: "9.25rem", marginBottom: "2.25rem" }}>
          <MainStepper activeStep={0} />
        </Box>
        <Headline title={headline} />
        <form
          style={{
            marginTop: "2.25rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <AdminInput
            label="Judul"
            placeholder="Judul"
            required
            disabled
            otherProps={{ value: "Animakini X Ciffest 2023" }}
            sx={{ maxWidth: "19.688rem" }}
          />
          <AdminInput
            label="Deskripsi"
            placeholder="Deskripsi"
            required
            disabled
            otherProps={{ value: "Kentangproduction@gmail.com" }}
            sx={{ maxWidth: "19.688rem" }}
          />
          <AdminTextArea
            label="Info Penting"
            placeholder="Info Penting"
            required
            disabled
            otherProps={{
              value:
                "20-22 Februari 2023 Redemption : 12.00 PM (20-22 Februari 2023) Event Time: 8.00 PM Open Gate: 5.30 WIB Venue: Gedung Teater Besar Taman Ismail Marzuki",
            }}
            sx={{ maxWidth: "19.688rem" }}
            multiline
            rows={3}
            maxRows={4}
          />
          <Box
            sx={{
              minWidth: "19.688rem",
              display: "flex",
              flexDirection: "column",
              marginBottom: "2.25rem",
            }}
          >
            <FormLabel
              sx={{
                marginBottom: "0.5rem",
                color: "neutral.800",
                fontSize: "1.25rem",
                fontWeight: 700,
              }}
            >
              <span style={{ color: "#B43127", fontWeight: "bold" }}>*</span>
              Poster
            </FormLabel>
            <LargeImagePreview
              file={{
                url: "/assets/event3.jpg",
                name: "Image_001.JPEG",
              }}
              width="20rem"
              height="25rem"
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "2rem" }}>
            <MainButton
              variant="outlined"
              sx={{ border: "primary.500", color: "primary.500" }}
              onClick={() => navigate(-1)}
            >
              Kembali
            </MainButton>
            <MainButton
              variant="contained"
              sx={{ bgcolor: "primary.500", mr: "0.5rem" }}
              onClick={() => navigate("/admin-event/approval/event-package")}
            >
              Selanjutnya
            </MainButton>
          </Box>
        </form>
      </Paper>
    </>
  );
};

export default ApprovalEventDescription;
