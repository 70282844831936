import React, { useState } from "react";
import { Grid, Button, TextField, Typography } from "@mui/material";
import Headline from "../../components/headline";
import RequiredLabel from "../../components/Elements/RequiredLabel";
import AdminTextArea from "../../components/Elements/AdminTextArea";
import Phone from "../../assets/phone-solid.svg";
import TabsMasterData from "../../components/content/TabsContent";

import { Icon } from "@iconify/react";

const PurchaseOrder = ({ title, setSection }) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const titleHeadline = (
    <Grid container alignItems={"center"} gap={2}>
      <Icon
        icon="streamline:travel-map-location-pin-navigation-map-maps-pin-gps-location"
        width="40"
        height="40"
      />
      <Typography fontSize={"32px"} fontWeight={"700"}>
        Venue Maps
      </Typography>
    </Grid>
  );

  const editHeadline = (
    <Grid container alignItems={"center"} gap={1}>
      <Icon
        icon="material-symbols:edit-outline"
        color="white"
        width="20"
        height="20"
      />
      <Typography>Edit</Typography>
    </Grid>
  );

  return (
    <>
      <Grid
        container
        direction="column"
        backgroundColor="white"
        padding="1.5rem"
        gap={2}
      >
        <Headline title={titleHeadline} content={editHeadline} />

        <Grid item xs={4}>
          <Grid item marginTop={"20px"}>
            <RequiredLabel label={"Lokasi"} />
          </Grid>

          <Grid
            item
            display="flex"
            flexDirection={"column"}
            border={1}
            borderColor="#828282"
            padding="1rem"
          >
            <Grid item>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.5485007339453!2d106.83615461488672!3d-6.191115162380591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f43ed58a394b%3A0x52eb0c276d813909!2sTaman%20Ismail%20Marzuki!5e0!3m2!1sid!2sid!4v1678857908855!5m2!1sid!2sid"
                style={{
                  border: "0",
                  width: "100%",
                }}
                display="flex"
                height={"600px"}
                allowfullscreen=""
                loading="lazy"
                title="Venue Map"
                referrerpolicy="no-referrer-when-downgrade"
              />
            </Grid>

            <Grid
              item
              display="flex"
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              <Grid
                item
                display="flex"
                flexDirection={"row"}
                alignItems={"center"}
                gap={2}
              >
                <Grid item>
                  <Icon icon="ph:map-pin" width="24" height="24" />
                </Grid>
                <Grid item display="flex" flexDirection={"column"}>
                  <Grid item fontWeight="500" fontSize="14px">
                    Jl. Cikini Raya No.73, RT.8/RW.2, Cikini, Kec. Menteng, Kota
                    Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10330
                  </Grid>
                  <Grid item color="#828282" fontWeight="500" fontSize="14px">
                    Gedung Taman Ismail Marzuki
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <a href="https://www.google.com/maps/dir//Taman+Ismail+Marzuki+Cikini+Kec.+Menteng+Kota+Jakarta+Pusat,+Daerah+Khusus+Ibukota+Jakarta/@-6.1911205,106.8383433,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x2e69f43ed58a394b:0x52eb0c276d813909!2m2!1d106.8383433!2d-6.1911205">
                  <Button
                    style={{
                      backgroundColor: "white",
                      color: "#D14F1E",
                      borderRadius: "0",
                      padding: "12px 24px",
                    }}
                  >
                    <Grid
                      item
                      display="flex"
                      flexDirection={"row"}
                      alignItems={"center"}
                      gap={2}
                    >
                      <Grid item>
                        <Icon
                          icon="teenyicons:direction-outline"
                          color="#d14f1e"
                          width="14"
                          height="14"
                          rotate={1}
                        />
                      </Grid>
                      <Grid item>Temukan Lokasi</Grid>
                    </Grid>
                  </Button>
                </a>
              </Grid>
            </Grid>
          </Grid>

          <Grid item marginTop={"20px"}>
            <RequiredLabel label="Detail Lokasi" />
          </Grid>
          <Grid item marginTop={"20px"}>
            {/* <TextField
              required
              name="phone"
              size="small"
              rows={2}
              fullWidth
              placeholder="https://www.google.com/maps/place/Unit+Pengelola+Pusat+Kesenian+Jakarta+Taman+Ismail+Mazuki/@-6.1891586,106.8375851,17z/data=!3m1!4b1!4m6!3m5!1s0x2e69f4392e3b5a1b:0xeb14a935dca46518!8m2!3d-6.1891586!4d106.8397738!16s%2Fm%2F0h3wm55"
            /> */}
            <AdminTextArea
              rows={2}
              multiline={true}
              placeholder={"Detail Lokasi"}
              sx={{ width: 450 }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PurchaseOrder;
