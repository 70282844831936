import React, { useState } from "react";
import { Button, Grid, IconButton, InputLabel, TextField } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { Icon } from "@iconify/react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { addDays } from "date-fns";

// hooks
import useModal from "../hooks/useModal";

// components
import FilterDate from "./Filterdate";
import dayjs from "dayjs";

const SearchBarBase = ({
  onSearch,
  onChangeLimit,
  filter,
  resetPage,
  limit,
  status,
  onChangeStatus,
  onDeleteSelected,
  refetch,
  selectedItems,
  isGranted,
  ticketEvent,
  setSelectedDates,
  selectedDates,
}) => {
  const { isShowing: isShowingDate, toggle: toggleDate } = useModal();
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  return (
    <>
      <FilterDate
        toggle={toggleDate}
        isShowing={isShowingDate}
        state={state}
        // setState={setState}
        setSelectedDates={setState}
        resetPage={resetPage}
      />
      <Grid container justifyContent={"space-between"} alignItems="center">
        <Grid item display={"flex"} alignItems="center" gap={1}>
          <Grid item>Show</Grid>
          <Grid item>
            <FormControl size="small">
              <Select
                name="limit"
                sx={{ border: "#0A4C8A" }}
                value={limit}
                onChange={onChangeLimit}
                fullWidth
              >
                {[10, 50, 100].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>Entries</Grid>
        </Grid>

        <Grid item display={"flex"} gap={1} xs={8}>
          <Grid item xs={3}>
            <Button
              sx={{
                border: 1,
                borderRadius: "0.5rem",
                borderColor: "neutral.400",
                cursor: "pointer",
                color: "black",
                fontWeight: "500",
                textTransform: "none",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={toggleDate}
            >
              {state?.startDate !== undefined
                ? ` ${dayjs(state?.startDate).format("DD/MM/YYYY")} - ${dayjs(
                    state?.endDate
                  ).format("DD/MM/YYYY")} `
                : "Periode Waktu"}
              <Icon icon="fe:drop-down" color="#828282" />
            </Button>
          </Grid>
          <Grid item xs={3}>
            <FormControl size="small" fullWidth>
              <InputLabel id="status">Status</InputLabel>
              <Select
                id="status"
                name="status"
                value={status}
                label="Status"
                onChange={onChangeStatus}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Menunggu Persetujuan</MenuItem>
                <MenuItem value={20}>Disetujui</MenuItem>
                <MenuItem value={30}>Ditolak</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={3}>
            <FormControl size="small" fullWidth>
              <InputLabel id="sort">Sort By</InputLabel>
              <Select name="limit" label="Sort By">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={0}>Nama Event (A-Z)</MenuItem>
                <MenuItem value={1}>Event Organizer (A-Z)</MenuItem>
                <MenuItem value={2}>Tanggal Event (Terbaru-Terlama)</MenuItem>
                <MenuItem value={3}>Lokasi (A-Z)</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item xs={5}>
            <TextField
              size="small"
              name="name"
              fullWidth
              sx={{}}
              value={filter?.name}
              onChange={onSearch}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  refetch();
                }
              }}
              placeholder="Cari..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={refetch} size="small" edge="start">
                      <Icon icon="material-symbols:search" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              disabled={selectedItems?.length < 1}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.688rem",
              }}
              // onClick={toggleDelete}
            >
              <Icon
                icon="ph:trash-simple"
                color={selectedItems?.length > 0 ? "#D12C24" : "#c0c0c0"}
                width="14"
                height="14"
              />
              Hapus
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SearchBarBase;
