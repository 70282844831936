import React from "react";
import { Box, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import MainButton from "./MainButton";
import { Icon } from "@iconify/react";

function UploadForm({ onDrop, fileName }) {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    maxSize: 20 * 1024 * 1024,
    onDrop,
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          borderColor: "primary.500",
          borderWidth: "2px",
          borderStyle: "dashed",
          width: "100%",
          minHeight: "12.188rem",
          bgcolor: "primary.100",
        }}
        {...getRootProps({ className: "dropzone" })}
      >
        <Box>
          <Icon
            icon="tabler:cloud-upload"
            height="2.625rem"
            width="3.5rem"
            color="#FF723D"
          />
        </Box>
        <Typography variant="body2">Drag file ke sini atau</Typography>
        <input {...getInputProps()} />
        <MainButton
          variant="contained"
          sx={{ width: "fit-content", marginTop: "1rem" }}
          otherProps={{ ...getRootProps({ className: "dropzone" }) }}
        >
          Upload File
        </MainButton>
      </Box>
    </>
  );
}

export default UploadForm;
