import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  Toolbar,
} from "@mui/material";

// asset
import Logo from "../../assets/LogoPutih.png";
import LogoTim from "../../assets/logotim.png";

// component
import ItemMenu from "./ItemMenu";
import ItemNavbar from "./ItemNavbar";

// utils
import { navItems } from "../../utils/constant";
import { userState } from "../../redux/feature/userSlice";
import useAuth from "../../hooks/useAuth";

function MainNavbar(props) {
  const { window } = props;
  const [itemActive, setItemActive] = useState();
  const [mobileOpen, setMobileOpen] = useState(false);
  const drawerWidth = 240;
  const navigate = useNavigate();
  // const { userData } = useSelector(userState);
  const { userData } = useAuth();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Box sx={{ my: 2 }}>
        <img
          src={LogoTim}
          alt="logo"
          style={{ widht: "3rem", height: "2rem" }}
        />
      </Box>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ItemMenu key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box sx={{ display: "flex", position: "relative", marginBottom: "5rem" }}>
        <CssBaseline />
        <AppBar
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "5rem",
            justifyContent: "space-around",
            top: "0",
            background: "#1F1F1F",
            zIndex: "10",
            boxShadow: "none",
          }}
        >
          <Toolbar
            sx={{ paddingLeft: "0 !important", paddingRight: "0 !important" }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ ml: 2, mr: 2, display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ mr: 2, display: { md: "none" } }}>
              <img src={Logo} alt="logo" />
            </Box>
            <Grid container sx={{ display: { xs: "none", md: "block" } }}>
              <Grid
                item
                display="flex"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                margin="0 auto"
                gap={2}
                sm={10}
                md={9}
              >
                <Box
                  onClick={() => navigate("/")}
                  sx={{ cursor: "pointer", bgColor: "blue" }}
                >
                  <img src={Logo} alt="" />
                </Box>
                <Box
                  display="flex"
                  direction="row"
                  alignItems="center"
                  gap="4rem"
                >
                  {navItems.map((item, i) => (
                    <ItemNavbar
                      key={item.title}
                      item={item}
                      index={i}
                      itemActive={itemActive}
                      setItemActive={() => setItemActive(i)}
                    />
                  ))}
                  {userData?.role && (
                    <IconButton
                      color="inherit"
                      edge="end"
                      onClick={() => {
                        userData?.role === 1
                          ? navigate("/profile")
                          : navigate("/profile/eo");
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: "primary.500",
                          border: "2px solid white",
                        }}
                      >
                        {Array.from(userData?.name)[0].toUpperCase()}
                      </Avatar>
                    </IconButton>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </>
  );
}

export default MainNavbar;
