import React from "react";
import { Grid, Box, Button, TextField } from "@mui/material";

import Event1 from "../assets/rename1.png";
import Event2 from "../assets/rename2.png";
import Event3 from "../assets/rename3.png";
import Filter from "../components/filterEx";
import { useNavigate } from "react-router-dom";


const Home = () => {
    const navigate = useNavigate();
    return (
        <>
            <Grid display={"flex"} direction="column" gap={3}>
                <Grid
                    display={"flex"}
                    direction="column"
                    item
                    padding="1rem 7rem"
                    gap={4}
                >
                    <Grid container
                        display={"flex"}
                        flexDirection="column"
                        alignItems={"flex-start"}
                        padding="16px"
                        gap="8px"
                        width={"640px"}
                        height="1600px"



                    >
                        <Grid item>
                            <Filter />

                        </Grid>
                        <Grid item>
                            <Grid container fontWeight="500" fontFamily={'Poppins'} lineHeight="150%" fontStyle={"normal"} fontSize="24px" color="#1F1F1F" width={"497px"} height={"36px"}>
                                <Grid item >
                                    Date: 12 Februari 2023 - 28 Februari 2023
                                </Grid>
                            </Grid>
                            <Grid container display={"flex"} flexDirection={"row"} alignItems="center" padding={"0px"} width="315" height={"48px"}>
                                <Grid item fontWeight="500" fontFamily={'Plus Jakarta Sans'} lineHeight="250%" fontStyle={"normal"} fontSize="20px" color="#1F1F1F" width={"150px"} height={"50px"}  >
                                    Exhibitions (24)
                                </Grid>
                                <Grid item >
                                    <Button
                                        style={{
                                            color: "#FF723D",
                                            fontWeight: "200",
                                            fontSize: "20px",
                                            cursor: "pointer",
                                            textTransform: "none",

                                            top: "30%"
                                        }}
                                        disableElevation
                                    >
                                        View Full Calender {">"}
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid item display={"flex"} direction="row"  gap={1}>
                                <Grid item width={"30%"}>
                                    <Box
                                        sx={{
                                            height: "30vh",
                                            backgroundImage: `url(${Event1})`,
                                            backgroundSize: "cover",
                                        }}
                                    ></Box>
                                    <Grid
                                        item
                                        width="100%"
                                        display={"flex"}
                                        direction="row"
                                        border="1"
                                        backgroundColor="white"
                                        zIndex={"1000"}
                                        position="relative"
                                        top="-80px"
                                        margin="auto"
                                        sx={{ boxShadow: 1 }}
                                        padding="15px"
                                        justifyContent={"space-between"}
                                    >
                                        <Grid
                                            item
                                            display={"flex"}
                                            direction="column"
                                            margin="auto 0"
                                            gap={1.5}
                                        >
                                            <Grid item fontWeight="700" fontSize="16px">
                                                Animakini X Ciffest 2018
                                            </Grid>
                                            <Grid item color="#D14F1E" fontWeight="400" fontSize="12px">
                                                17 Agustus 2024
                                            </Grid>
                                            <Grid item color="#1F1F1F" fontWeight="400" fontSize="10px">
                                                Experience Sir Isaac Julien’s video installation exploring
                                                the life and legacy of architect Lina Bo Bardi.
                                            </Grid>
                                            <Grid container>
                                                <Box
                                                    sx={{
                                                        backgroundColor: "#FFF1EB",
                                                        color: "#FF723D",
                                                        borderRadius: "50%",
                                                        padding: "17.5444px 17px",
                                                        textAlign: "center",
                                                        fontWeight: "700",
                                                        fontSize: "14px",
                                                        marginTop: "10px"
                                                    }}
                                                    onClick={() => navigate("/plan-booking")}
                                                >
                                                    Free
                                                </Box>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid item width={"30%"} >
                                    <Box
                                        sx={{
                                            height: "30vh",
                                            backgroundImage: `url(${Event2})`,
                                            backgroundSize: "cover",
                                        }}
                                    ></Box>
                                    <Grid
                                        item
                                        width="100%"
                                        display={"flex"}
                                        direction="row"
                                        border="1"
                                        backgroundColor="white"
                                        zIndex={"1000"}
                                        position="relative"
                                        top="-80px"
                                        margin="auto"
                                        sx={{ boxShadow: 1 }}
                                        padding="15px"
                                        justifyContent={"space-between"}
                                    >
                                        <Grid
                                            item
                                            display={"flex"}
                                            direction="column"
                                            margin="auto 0"
                                            gap={1.5}
                                        >
                                            <Grid item fontWeight="700" fontSize="16px">
                                                Urang Minang Baralek Gadang
                                            </Grid>
                                            <Grid item color="#D14F1E" fontWeight="400" fontSize="12px">
                                                17 Agustus 2024
                                            </Grid>
                                            <Grid item color="#828282" fontWeight="400" fontSize="10px">
                                                Experience Sir Isaac Julien’s video installation exploring
                                                the life and legacy of architect Lina Bo Bardi.
                                            </Grid>
                                            <Grid container>
                                                <Box
                                                    sx={{
                                                        backgroundColor: "#FFF1EB",
                                                        color: "#FF723D",
                                                        borderRadius: "50%",
                                                        padding: "17.5444px 17px",
                                                        textAlign: "center",
                                                        fontWeight: "700",
                                                        fontSize: "14px",
                                                        marginTop: "10px"
                                                    }}
                                                >
                                                    Free
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item width={"30%"}>
                                    <Box
                                        sx={{
                                            height: "30vh",
                                            backgroundImage: `url(${Event3})`,
                                            backgroundSize: "cover",
                                        }}
                                    ></Box>
                                    <Grid
                                        item
                                        width="100%"
                                        display={"flex"}
                                        direction="row"
                                        border="1"
                                        backgroundColor="white"
                                        zIndex={"1000"}
                                        position="relative"
                                        top="-80px"
                                        margin="auto"
                                        sx={{ boxShadow: 1 }}
                                        padding="15px"
                                        justifyContent={"space-between"}
                                    >
                                        <Grid
                                            item
                                            display={"flex"}
                                            direction="column"
                                            margin="auto 0"
                                            gap={1.5}
                                        >
                                            <Grid item fontWeight="700" fontSize="16px">
                                                Pameran Akhir Tahun
                                            </Grid>
                                            <Grid item color="#D14F1E" fontWeight="400" fontSize="12px" >
                                                17 Agustus 2024
                                            </Grid>
                                            <Grid item color="#828282" fontWeight="400" fontSize="10px">
                                                Experience Sir Isaac Julien’s video installation exploring
                                                the life and legacy of architect Lina Bo Bardi.
                                            </Grid>
                                            <Grid container>
                                                <Box
                                                    sx={{
                                                        backgroundColor: "#FFF1EB",
                                                        color: "#FF723D",
                                                        borderRadius: "50%",
                                                        padding: "17.5444px 17px",
                                                        textAlign: "center",
                                                        fontWeight: "700",
                                                        fontSize: "14px",
                                                        marginTop: "10px"
                                                    }}
                                                    onClick={() => navigate("/plan-booking")}
                                                >
                                                    Free
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item display={"flex"} direction="row"  gap={1}>
                                <Grid item width={"30%"}>
                                    <Box
                                        sx={{
                                            height: "30vh",
                                            backgroundImage: `url(${Event1})`,
                                            backgroundSize: "cover",
                                        }}
                                    ></Box>
                                    <Grid
                                        item
                                        width="100%"
                                        display={"flex"}
                                        direction="row"
                                        border="1"
                                        backgroundColor="white"
                                        zIndex={"1000"}
                                        position="relative"
                                        top="-80px"
                                        margin="auto"
                                        sx={{ boxShadow: 1 }}
                                        padding="15px"
                                        justifyContent={"space-between"}
                                    >
                                        <Grid
                                            item
                                            display={"flex"}
                                            direction="column"
                                            margin="auto 0"
                                            gap={1.5}
                                        >
                                            <Grid item fontWeight="700" fontSize="16px">
                                                Animakini X Ciffest 2018
                                            </Grid>
                                            <Grid item color="#D14F1E" fontWeight="400" fontSize="12px">
                                                17 Agustus 2024
                                            </Grid>
                                            <Grid item color="#1F1F1F" fontWeight="400" fontSize="10px">
                                                Experience Sir Isaac Julien’s video installation exploring
                                                the life and legacy of architect Lina Bo Bardi.
                                            </Grid>
                                            <Grid container>
                                                <Box
                                                    sx={{
                                                        backgroundColor: "#FFF1EB",
                                                        color: "#FF723D",
                                                        borderRadius: "50%",
                                                        padding: "17.5444px 17px",
                                                        textAlign: "center",
                                                        fontWeight: "700",
                                                        fontSize: "14px",
                                                        marginTop: "10px"
                                                    }}
                                                    onClick={() => navigate("/plan-booking")}
                                                >
                                                    Free
                                                </Box>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid item width={"30%"} >
                                    <Box
                                        sx={{
                                            height: "30vh",
                                            backgroundImage: `url(${Event2})`,
                                            backgroundSize: "cover",
                                        }}
                                    ></Box>
                                    <Grid
                                        item
                                        width="100%"
                                        display={"flex"}
                                        direction="row"
                                        border="1"
                                        backgroundColor="white"
                                        zIndex={"1000"}
                                        position="relative"
                                        top="-80px"
                                        margin="auto"
                                        sx={{ boxShadow: 1 }}
                                        padding="15px"
                                        justifyContent={"space-between"}
                                    >
                                        <Grid
                                            item
                                            display={"flex"}
                                            direction="column"
                                            margin="auto 0"
                                            gap={1.5}
                                        >
                                            <Grid item fontWeight="700" fontSize="16px">
                                                Urang Minang Baralek Gadang
                                            </Grid>
                                            <Grid item color="#D14F1E" fontWeight="400" fontSize="12px">
                                                17 Agustus 2024
                                            </Grid>
                                            <Grid item color="#828282" fontWeight="400" fontSize="10px">
                                                Experience Sir Isaac Julien’s video installation exploring
                                                the life and legacy of architect Lina Bo Bardi.
                                            </Grid>
                                            <Grid container>
                                                <Box
                                                    sx={{
                                                        backgroundColor: "#FFF1EB",
                                                        color: "#FF723D",
                                                        borderRadius: "50%",
                                                        padding: "17.5444px 17px",
                                                        textAlign: "center",
                                                        fontWeight: "700",
                                                        fontSize: "14px",
                                                        marginTop: "10px"
                                                    }}
                                                >
                                                    Free
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item width={"30%"}>
                                    <Box
                                        sx={{
                                            height: "30vh",
                                            backgroundImage: `url(${Event3})`,
                                            backgroundSize: "cover",
                                        }}
                                    ></Box>
                                    <Grid
                                        item
                                        width="100%"
                                        display={"flex"}
                                        direction="row"
                                        border="1"
                                        backgroundColor="white"
                                        zIndex={"1000"}
                                        position="relative"
                                        top="-80px"
                                        margin="auto"
                                        sx={{ boxShadow: 1 }}
                                        padding="15px"
                                        justifyContent={"space-between"}
                                    >
                                        <Grid
                                            item
                                            display={"flex"}
                                            direction="column"
                                            margin="auto 0"
                                            gap={1.5}
                                        >
                                            <Grid item fontWeight="700" fontSize="16px">
                                                Pameran Akhir Tahun
                                            </Grid>
                                            <Grid item color="#D14F1E" fontWeight="400" fontSize="12px" >
                                                17 Agustus 2024
                                            </Grid>
                                            <Grid item color="#828282" fontWeight="400" fontSize="10px">
                                                Experience Sir Isaac Julien’s video installation exploring
                                                the life and legacy of architect Lina Bo Bardi.
                                            </Grid>
                                            <Grid container>
                                                <Box
                                                    sx={{
                                                        backgroundColor: "#FFF1EB",
                                                        color: "#FF723D",
                                                        borderRadius: "50%",
                                                        padding: "17.5444px 17px",
                                                        textAlign: "center",
                                                        fontWeight: "700",
                                                        fontSize: "14px",
                                                        marginTop: "10px"
                                                    }}
                                                    onClick={() => navigate("/plan-booking")}
                                                >
                                                    Free
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item display={"flex"} direction="row"  gap={1}>
                                <Grid item width={"30%"}>
                                    <Box
                                        sx={{
                                            height: "30vh",
                                            backgroundImage: `url(${Event1})`,
                                            backgroundSize: "cover",
                                        }}
                                    ></Box>
                                    <Grid
                                        item
                                        width="100%"
                                        display={"flex"}
                                        direction="row"
                                        border="1"
                                        backgroundColor="white"
                                        zIndex={"1000"}
                                        position="relative"
                                        top="-80px"
                                        margin="auto"
                                        sx={{ boxShadow: 1 }}
                                        padding="15px"
                                        justifyContent={"space-between"}
                                    >
                                        <Grid
                                            item
                                            display={"flex"}
                                            direction="column"
                                            margin="auto 0"
                                            gap={1.5}
                                        >
                                            <Grid item fontWeight="700" fontSize="16px">
                                                Animakini X Ciffest 2018
                                            </Grid>
                                            <Grid item color="#D14F1E" fontWeight="400" fontSize="12px">
                                                17 Agustus 2024
                                            </Grid>
                                            <Grid item color="#1F1F1F" fontWeight="400" fontSize="10px">
                                                Experience Sir Isaac Julien’s video installation exploring
                                                the life and legacy of architect Lina Bo Bardi.
                                            </Grid>
                                            <Grid container>
                                                <Box
                                                    sx={{
                                                        backgroundColor: "#FFF1EB",
                                                        color: "#FF723D",
                                                        borderRadius: "50%",
                                                        padding: "17.5444px 17px",
                                                        textAlign: "center",
                                                        fontWeight: "700",
                                                        fontSize: "14px",
                                                        marginTop: "10px"
                                                    }}
                                                >
                                                    Free
                                                </Box>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid item width={"30%"} >
                                    <Box
                                        sx={{
                                            height: "30vh",
                                            backgroundImage: `url(${Event2})`,
                                            backgroundSize: "cover",
                                        }}
                                    ></Box>
                                    <Grid
                                        item
                                        width="100%"
                                        display={"flex"}
                                        direction="row"
                                        border="1"
                                        backgroundColor="white"
                                        zIndex={"1000"}
                                        position="relative"
                                        top="-80px"
                                        margin="auto"
                                        sx={{ boxShadow: 1 }}
                                        padding="15px"
                                        justifyContent={"space-between"}
                                    >
                                        <Grid
                                            item
                                            display={"flex"}
                                            direction="column"
                                            margin="auto 0"
                                            gap={1.5}
                                        >
                                            <Grid item fontWeight="700" fontSize="16px">
                                                Urang Minang Baralek Gadang
                                            </Grid>
                                            <Grid item color="#D14F1E" fontWeight="400" fontSize="12px">
                                                17 Agustus 2024
                                            </Grid>
                                            <Grid item color="#828282" fontWeight="400" fontSize="10px">
                                                Experience Sir Isaac Julien’s video installation exploring
                                                the life and legacy of architect Lina Bo Bardi.
                                            </Grid>
                                            <Grid container>
                                                <Box
                                                    sx={{
                                                        backgroundColor: "#FFF1EB",
                                                        color: "#FF723D",
                                                        borderRadius: "50%",
                                                        padding: "17.5444px 17px",
                                                        textAlign: "center",
                                                        fontWeight: "700",
                                                        fontSize: "14px",
                                                        marginTop: "10px"
                                                    }}
                                                    onClick={() => navigate("/plan-booking")}
                                                >
                                                    Free
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item width={"30%"}>
                                    <Box
                                        sx={{
                                            height: "30vh",
                                            backgroundImage: `url(${Event3})`,
                                            backgroundSize: "cover",
                                        }}
                                    ></Box>
                                    <Grid
                                        item
                                        width="100%"
                                        display={"flex"}
                                        direction="row"
                                        border="1"
                                        backgroundColor="white"
                                        zIndex={"1000"}
                                        position="relative"
                                        top="-80px"
                                        margin="auto"
                                        sx={{ boxShadow: 1 }}
                                        padding="15px"
                                        justifyContent={"space-between"}
                                    >
                                        <Grid
                                            item
                                            display={"flex"}
                                            direction="column"
                                            margin="auto 0"
                                            gap={1.5}
                                        >
                                            <Grid item fontWeight="700" fontSize="16px">
                                                Pameran Akhir Tahun
                                            </Grid>
                                            <Grid item color="#D14F1E" fontWeight="400" fontSize="12px" >
                                                17 Agustus 2024
                                            </Grid>
                                            <Grid item color="#828282" fontWeight="400" fontSize="10px">
                                                Experience Sir Isaac Julien’s video installation exploring
                                                the life and legacy of architect Lina Bo Bardi.
                                            </Grid>
                                            <Grid container>
                                                <Box
                                                    sx={{
                                                        backgroundColor: "#FFF1EB",
                                                        color: "#FF723D",
                                                        borderRadius: "50%",
                                                        padding: "17.5444px 17px",
                                                        textAlign: "center",
                                                        fontWeight: "700",
                                                        fontSize: "14px",
                                                        marginTop: "10px"
                                                    }}
                                                    onClick={() => navigate("/plan-booking")}
                                                >
                                                    Free
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>


                </Grid>
            </Grid>

        </>
    );
};

export default Home;
