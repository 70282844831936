import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// redux
import { setUserData } from "../../redux/feature/userSlice";

// component
import BackButton from "../../components/Elements/BackButton";
import BookingDetailCard from "../../components/MyBooking/BookingDetailCard";
import BookingHeadCard from "../../components/MyBooking/BookingHeadCard";
import CustomerHelp from "../../components/MyBooking/CustomerHelp";
import useModal from "../../hooks/useModal";
import QRCodeModal from "../../components/MyBooking/QRCodeModal";

function DetailMyBooking() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { isShowing, toggle } = useModal();

  const userData = {
    name: "Louise Thompson",
    type: "Public Visitor",
    phone: "083221664825",
    email: "louisethompson@gmail.com",
    initial: "L",
    role: "pv",
  };

  useEffect(() => {
    dispatch(setUserData(userData));
  }, []);

  const dummyBookingData = [
    {
      id: 1,
      title: "Animakini X Ciffest 2023",
      orderId: 12651257126,
      ticketAmount: 2,
      date: "Senin, 20 Februari 2023",
      status: "Menunggu Pembayaran",
      bookCode: "1256125137",
      name: "Luis Thomson",
      email: "luisthomson@gmail.com",
      ticketCategory: "VIP 1 Tiket",
      Pax: "1 Pax",
      category: "VIP",
    },
    {
      id: 2,
      title: "Animakini X Ciffest 2023",
      orderId: 12651257126,
      ticketAmount: 2,
      date: "Senin, 20 Februari 2023",
      status: "Sudah Dibayar",
      bookCode: "1256125137",
      name: "Luis Thomson",
      email: "luisthomson@gmail.com",
      ticketCategory: "VIP 1 Tiket",
      Pax: "1 Pax",
      category: "VIP",
    },
  ];

  return (
    <>
      <Grid container display={"flex"} direction="column" sx={{ gap: "1rem" }}>
        <BackButton onClick={() => navigate("/mybooking")} />
        <BookingHeadCard
          title={dummyBookingData[id - 1]?.title}
          orderId={dummyBookingData[id - 1]?.orderId}
          ticketAmount={dummyBookingData[id - 1]?.ticketAmount}
          date={dummyBookingData[id - 1]?.date}
          status={dummyBookingData[id - 1]?.status}
          onShowQr={toggle}
        />
        <BookingDetailCard
          bookCode={dummyBookingData[id - 1]?.bookCode}
          name={dummyBookingData[id - 1]?.name}
          email={dummyBookingData[id - 1]?.email}
          ticketCategory={dummyBookingData[id - 1]?.ticketCategory}
          Pax={dummyBookingData[id - 1]?.Pax}
          date={dummyBookingData[id - 1]?.date}
        />
        <CustomerHelp />
        <QRCodeModal
          value={dummyBookingData[id - 1]?.bookCode}
          category={dummyBookingData[id - 1]?.category}
          isShowing={isShowing}
          toggle={toggle}
        />
      </Grid>
    </>
  );
}

export default DetailMyBooking;
