import React from "react";
import { Pagination, PaginationItem, Button, useTheme } from "@mui/material";

export default function CustomPagination({ count, page, handleChangePage }) {
  const theme = useTheme();
  return (
    <Pagination
      count={count}
      page={page}
      color="primaryLight"
      onChange={handleChangePage}
      shape="rounded"
      size="large"
      renderItem={(item) => (
        <PaginationItem
          sx={{ margin: 0, border: "1px solid #E0E0E0", borderRadius: 0.2 }}
          components={{
            next: (props) => (
              <Button
                sx={{
                  color: "#1F1F1F !important",
                  fontSize: "15px !important",
                  fontWeight: "400",
                  textTransform: "none !important",
                }}
                {...props}
              >
                <p>Berikutnya</p>
              </Button>
            ),
            previous: (props) => (
              <Button
                sx={{
                  color: "#1F1F1F",
                  fontSize: "15px !important",
                  fontWeight: "400",
                  textTransform: "none !important",
                }}
                {...props}
              >
                Sebelumnya
              </Button>
            ),
          }}
          {...item}
        />
      )}
    />
  );
}
