import React from "react";
import { Box, Grid, Typography } from "@mui/material";

function UserNotice() {
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            bgcolor: "primary.600",
            minHeight: "4.25rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            padding: "1rem",
          }}
        >
          <Typography
            sx={{ fontSize: "1rem", fontWeight: 500, color: "neutral.100" }}
          >
            Selesaikan pemesanan dalam
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1rem",
              fontWeight: "semibold",
              marginLeft: "1rem",
            }}
          >
            <Box
              sx={{
                width: "2.25rem",
                height: "2.25rem",
                bgcolor: "tertiary.main",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              00
            </Box>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 500,
                color: "neutral.100",
                marginX: "0.25rem",
              }}
            >
              :
            </Typography>
            <Box
              sx={{
                width: "2.25rem",
                height: "2.25rem",
                bgcolor: "tertiary.main",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              00
            </Box>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 500,
                color: "neutral.100",
                marginX: "0.25rem",
              }}
            >
              :
            </Typography>
            <Box
              sx={{
                width: "2.25rem",
                height: "2.25rem",
                bgcolor: "tertiary.main",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              00
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default UserNotice;
