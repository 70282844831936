import { useRoutes } from "react-router-dom";
import React, { useState } from "react";

// components
import Home from "./pages/Home";
import News from "./pages/News";
import NewsClick from "./pages/newsClick";
import About from "./pages/About";
import Contact from "./pages/contact";
import SubmitEvent from "./pages/SubmitEvent";
import ExhibitionEvent from "./pages/Exhibition";
import ExhibitionAll from "./pages/exeventFull";
import ExhibitionView from "./pages/viewExhEvent";
import GettingHere from "./pages/GettingHere";
import PlanBooking from "./pages/planBooking";
import DetailBooking from "./pages/DetailBooking";
import DetailPemasanan from "./pages/detailPemasanan";
import DetailPembayaran from "./pages/detailPembayaran";
import MetodePembayaran from "./pages/metodePembayaran";
import Bank from "./pages/Bank";
import MyOrder from "./pages/MyOrder";
import Venue from "./pages/Venue";
import Login from "./pages/LoginUser";
import Daftar from "./pages/Daftar";
import Content from "./pages/Conten/about";
import ContentNews from "./pages/Conten/news";
import ContentTambahBerita from "./pages/Conten/Addnews";
import ContentGettingHere from "./pages/Conten/gettingHere";
import ContentVenueMap from "./pages/Conten/venueMaps";
import ContentAdmission from "./pages/Conten/admision";
import Admission from "./pages/Admission";

import ContentContact from "./pages/Conten/contact";
import ListAdmin from "./pages/membersip/Admin";
import Membership from "./pages/membersip/member";
import Event from "./pages/Event";
import AdminHomePropsal from "./pages/HomeAdmin/ProposalEvent";
import LoginAdmin from "./pages/Login-Admin";
import Forgotpasword from "./pages/Login-Admin/ForgotPass";
import Resetpasword from "./pages/Login-Admin/resetPassword";

import LayoutSidebar from "./components/layoutSidebar";
import LayoutAuth from "./components/LayoutAuth";
import LayoutAuthUser from "./components/LayoutAuth/User";

import Layout from "./components/Layout";
import UserLayout from "./components/UserDashboard/UserLayout";
import UserProfile from "./pages/UserProfile";
import UserEOProfile from "./pages/UserEOProfile";
import MyBooking from "./pages/MyBooking";
import DetailMyBooking from "./pages/MyBooking/DetailMyBooking";
import MyEvent from "./pages/MyEvent";
import DetailMyEvent from "./pages/MyEvent/DetailMyEvent";
import DetailCheckout from "./pages/DetailCheckout";
import DetailBill from "./pages/DetailBill";
import HomeAdmin from "./pages/HomeAdmin";
import ApprovalInfoEO from "./pages/Event/ApprovalInfoEO";
import ApprovalRentDetail from "./pages/Event/ApprovalRentDetail";
import ApprovalEventDetail from "./pages/Event/ApprovalEventDetail";
import ApprovalEventDescription from "./pages/Event/ApprovalEventDescription";
import ApprovalEventPackage from "./pages/Event/ApprovalEventPackage";
import ApprovalEventLocation from "./pages/Event/ApprovalEventLocation";
import AdminContent from "./pages/Conten";
import ForgetPass from "./pages/LoginUser/ForgetPass";

const Routes = () => {
  const [loginTitle, setLoginTitle] = useState(
    "Welcome Back Login to Access with your existing account"
  );

  const [loginTitleUser, setLoginTitleUser] = useState(true);

  return useRoutes([
    {
      element: <Layout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/news", element: <News /> },
        { path: "/news-onClick", element: <NewsClick /> },
        { path: "/about", element: <About /> },
        { path: "/contact", element: <Contact /> },
        { path: "/submit-event", element: <SubmitEvent /> },
        { path: "/edit-event/:id", element: <SubmitEvent /> },
        { path: "/exhibition-event", element: <ExhibitionEvent /> },
        { path: "/exhibition-view", element: <ExhibitionAll /> },
        { path: "/exhibition-all", element: <ExhibitionView /> },
        { path: "/getting-here", element: <GettingHere /> },
        { path: "/venue", element: <Venue /> },
        { path: "/admission", element: <Admission /> },
      ],
    },
    {
      element: <LayoutSidebar />,
      children: [
        { path: "/admin-content-about", element: <AdminContent /> },
        { path: "/admin-content-admission", element: <ContentAdmission /> },
        // { path: "/admin-content-news", element: <ContentNews /> },
        { path: "/admin", element: <HomeAdmin /> },
        {
          path: "/admin-content-tambah-berita",
          element: <ContentTambahBerita />,
        },
        { path: "/admin-content-contact", element: <AdminContent /> },
        { path: "/admin-content-GettingHere", element: <ContentGettingHere /> },
        { path: "/admin-content-venuemap", element: <ContentVenueMap /> },
        { path: "/listAdmin", element: <ListAdmin /> },
        { path: "/membership", element: <Membership /> },
        { path: "/admin-event", element: <Event /> },
        { path: "/admin-event/approval/info-eo", element: <ApprovalInfoEO /> },
        {
          path: "/admin-event/approval/rent-detail",
          element: <ApprovalRentDetail />,
        },
        {
          path: "/admin-event/approval/event-detail",
          element: <ApprovalEventDetail />,
        },
        {
          path: "/admin-event/approval/event-description",
          element: <ApprovalEventDescription />,
        },
        {
          path: "/admin-event/approval/event-package",
          element: <ApprovalEventPackage />,
        },
        {
          path: "/admin-event/approval/event-location",
          element: <ApprovalEventLocation />,
        },
      ],
    },
    {
      element: <UserLayout />,
      children: [
        { path: "/profile", element: <UserProfile /> },
        { path: "/profile/eo", element: <UserEOProfile /> },
        { path: "/mybooking", element: <MyBooking /> },
        { path: "/mybooking/:id", element: <DetailMyBooking /> },
        { path: "/myevent", element: <MyEvent /> },
        { path: "/myevent/:id", element: <DetailMyEvent /> },
      ],
    },

    { path: "/forgot-password-admin", element: <Forgotpasword /> },
    { path: "/reset-password-admin", element: <Resetpasword /> },
    { path: "/plan-booking", element: <PlanBooking /> },
    { path: "/detail-booking", element: <DetailBooking /> },
    { path: "/detail-pemasanan", element: <DetailPemasanan /> },
    { path: "/detail-pembayaran", element: <DetailPembayaran /> },
    { path: "/metode-pembayaran", element: <DetailCheckout /> },
    { path: "/pembayaran-bank", element: <DetailBill /> },
    { path: "/myOrder", element: <MyOrder /> },
    { path: "/daftar", element: <Daftar /> },
    { path: "/forget-pass", element: <ForgetPass /> },
    {
      element: <LayoutAuth loginTitle={loginTitle} />,
      children: [
        {
          path: "/login-admin",
          element: <LoginAdmin setLoginTitle={setLoginTitle} />,
        },
      ],
    },
    {
      element: <LayoutAuthUser loginTitle={loginTitleUser} />,
      children: [
        {
          path: "/login",
          element: <Login setLoginTitle={setLoginTitleUser} />,
        },
      ],
    },
  ]);
};

export default Routes;
