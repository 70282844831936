import { Icon } from "@iconify/react";
import { Box, FormLabel, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

// component
import ApprovalModal from "../../components/AdminEvent/ApprovalModal";
import ReasonRejectModal from "../../components/AdminEvent/ReasonRejectModal";
import MainButton from "../../components/Elements/MainButton";
import Headline from "../../components/headline";
import useModal from "../../hooks/useModal";
import MainStepper from "../../components/Elements/MainStepper";

const ApprovalEventLocation = () => {
  const navigate = useNavigate();
  const { toggle: toggleApproval, isShowing: isShowingApproval } = useModal();
  const { toggle: toggleRejected, isShowing: isShowingRejected } = useModal();

  const headline = (
    <>
      <Box display={"flex"} direction={"row"} alignItems={"center"} gap={2}>
        <Icon
          icon="mdi:clipboard-text-outline"
          color={"#1F1F1F"}
          width="2rem"
          height="2rem"
        />
        <Typography sx={{ fontSize: "2rem", fontWeight: "700" }}>
          Lokasi Event
        </Typography>
      </Box>
    </>
  );
  return (
    <>
      <Paper sx={{ padding: "1.5rem", borderRadius: 0 }}>
        <Box sx={{ width: "9.25rem", marginBottom: "2.25rem" }}>
          <MainStepper activeStep={2} />
        </Box>
        <Headline title={headline} />
        <form
          style={{
            marginTop: "2.25rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              minWidth: "19.688rem",
              display: "flex",
              flexDirection: "column",
              marginBottom: "2rem",
            }}
          >
            <FormLabel
              sx={{
                marginBottom: "0.5rem",
                color: "neutral.800",
                fontSize: "1.25rem",
                fontWeight: 700,
              }}
            >
              <span style={{ color: "#B43127", fontWeight: "bold" }}>*</span>
              Lokasi
            </FormLabel>
            <Grid container padding="1rem" bgcolor="neutral.300" maxWidth="90%">
              <Grid item xs={12}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.5485007339453!2d106.83615461488672!3d-6.191115162380591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f43ed58a394b%3A0x52eb0c276d813909!2sTaman%20Ismail%20Marzuki!5e0!3m2!1sid!2sid!4v1678857908855!5m2!1sid!2sid"
                  style={{
                    border: "0",
                    width: "100%",
                  }}
                  display="flex"
                  height="160px"
                  allowfullscreen=""
                  loading="lazy"
                  title="Venue Map"
                  referrerpolicy="no-referrer-when-downgrade"
                />
              </Grid>
              <Grid
                item
                display="flex"
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent="space-between"
              >
                <Grid
                  item
                  display="flex"
                  flexDirection={"row"}
                  alignItems={"center"}
                  bgcolor="neutral.300"
                  gap={2}
                >
                  <Grid item>
                    <Icon icon="ph:map-pin" width="24" height="24" />
                  </Grid>
                  <Grid item display="flex" flexDirection={"column"}>
                    <Grid item fontWeight="500" fontSize="14px">
                      Gedung Teater Besar Taman Ismail Marzuki - Jl. Cikini Raya
                      No.73, RT.8/RW.2, Cikini, Kec. Menteng, Kota Jakarta
                      Pusat, Daerah Khusus Ibukota Jakarta 10330
                    </Grid>
                    <Grid item color="#828282" fontWeight="500" fontSize="14px">
                      Gedung Taman Ismail Marzuki
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "2rem" }}>
            <MainButton
              variant="outlined"
              sx={{ border: "primary.500", color: "primary.500" }}
              onClick={() => navigate(-1)}
            >
              Kembali
            </MainButton>
            <MainButton
              variant="contained"
              sx={{ bgcolor: "primary.500", mr: "0.5rem" }}
              onClick={toggleApproval}
            >
              Konfirmasi
            </MainButton>
          </Box>
        </form>
      </Paper>
      <ApprovalModal
        isShowing={isShowingApproval}
        handleClose={toggleApproval}
        toggle={toggleApproval}
        onApprove={toggleApproval}
        onReject={() => {
          toggleApproval();
          toggleRejected();
        }}
      />
      <ReasonRejectModal
        isShowing={isShowingRejected}
        handleClose={toggleRejected}
        toggle={toggleRejected}
        onSubmit={toggleRejected}
        onBack={toggleRejected}
      />
    </>
  );
};

export default ApprovalEventLocation;
