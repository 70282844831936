import React from "react";
import { Icon } from "@iconify/react";
import { Box, Typography } from "@mui/material";

function EventMainTitle({ iconName, title, caption }) {
  return (
    <>
      <Box
        sx={{
          padding: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "0.625rem",
          bgcolor: "primary.100",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.625rem",
          }}
        >
          <Icon
            icon={iconName}
            height="1.25rem"
            width="1.25rem"
            color="#D14F1E"
          />
          <Typography color="primary.main" fontWeight="700" fontSize="1rem">
            {title}
          </Typography>
        </Box>
        <Typography variant="body2">{caption}</Typography>
      </Box>
    </>
  );
}

export default EventMainTitle;
