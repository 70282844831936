import React, { useEffect } from "react";
import FormInputs from "../../components/SubmitEvent/FormInputs";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
  formEventState,
  handleNextPage,
} from "../../redux/feature/formEventSlice";

import BulletPoints from "../../components/SubmitEvent/BulletPoints";
import ButtonForm from "../../components/SubmitEvent/Button";
import FormTitle from "../../components/SubmitEvent/FormTitle";
import useAuth from "../../hooks/useAuth";

// service
import EventServices from "../../services/EventServices";

const Index = () => {
  useAuth();
  const dispatch = useDispatch();

  const { formEventData, page } = useSelector(formEventState); // to get state data
  console.log(formEventData);
  const handleSubmit = (e) => {
    e.preventDefault();
    EventServices.postEvent(formEventData);
  };

  const handleNext = (e) => {
    e.preventDefault(); // it refreshes in submission form
    dispatch(handleNextPage());
  };

  useEffect(() => {
    window.scrollTo({ top: 200, behavior: "smooth" });
  }, [page]);

  return (
    <>
      {/* double grid? */}
      <Grid
        container
        display="flex"
        direction="column"
        alignItems={"center"}
        paddingTop="3rem"
      >
        <form onSubmit={page === 2 ? handleSubmit : handleNext}>
          <Grid item display="flex" direction="column" gap={2}>
            <BulletPoints />
            <FormTitle />
            <FormInputs />
            <ButtonForm />
          </Grid>
        </form>
      </Grid>
    </>
  );
};

export default Index;
