import { Icon } from "@iconify/react";
import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

// component
import AdminInput from "../../components/Elements/AdminInput";
import AdminTextArea from "../../components/Elements/AdminTextArea";
import MainButton from "../../components/Elements/MainButton";
import Headline from "../../components/headline";
import MainStepper from "../../components/Elements/MainStepper";

const ApprovalInfoEO = ({ setSection }) => {
  const navigate = useNavigate();

  const headline = (
    <>
      <Box display={"flex"} direction={"row"} alignItems={"center"} gap={2}>
        <Icon
          icon="mdi:clipboard-text-outline"
          color={"#1F1F1F"}
          width="2rem"
          height="2rem"
        />
        <Typography sx={{ fontSize: "2rem", fontWeight: "700" }}>
          Info Event Organizer
        </Typography>
      </Box>
    </>
  );

  return (
    <>
      <Paper sx={{ padding: "1.5rem", borderRadius: 0 }}>
        <Box sx={{ width: "9.25rem", marginBottom: "2.25rem" }}>
          <MainStepper activeStep={0} />
        </Box>
        <Headline title={headline} />
        <form
          style={{
            marginTop: "2.25rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <AdminInput
            label="Nama Event Organizer"
            placeholder="Nama Event Organizer"
            required
            disabled
            otherProps={{ value: "Kentang Production" }}
            sx={{ maxWidth: "19.688rem" }}
          />
          <AdminInput
            label="Email"
            placeholder="Email"
            required
            disabled
            otherProps={{ value: "Kentangproduction@gmail.com" }}
            sx={{ maxWidth: "19.688rem" }}
          />
          <AdminInput
            label="No.HP"
            placeholder="No.HP"
            required
            disabled
            otherProps={{ value: "08123456789" }}
            sx={{ maxWidth: "19.688rem" }}
          />
          <AdminInput
            label="Negara"
            placeholder="Negara"
            required
            disabled
            otherProps={{ value: "Indonesia" }}
            sx={{ maxWidth: "19.688rem" }}
          />
          <AdminInput
            label="Provinsi"
            placeholder="Provinsi"
            required
            disabled
            otherProps={{ value: "DKI Jakarta" }}
            sx={{ maxWidth: "19.688rem" }}
          />
          <AdminInput
            label="Kabupaten/Kota"
            placeholder="Kabupaten/Kota"
            required
            disabled
            otherProps={{ value: "Jakarta Utara" }}
            sx={{ maxWidth: "19.688rem" }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "2rem",
            }}
          >
            <Box sx={{ minWidth: "19.688rem" }}>
              <AdminInput
                label="Kecamatan"
                placeholder="Kecamatan"
                required
                disabled
                otherProps={{ value: "Kecamatan" }}
              />
            </Box>
            <Box sx={{ minWidth: "19.688rem" }}>
              <AdminInput
                label="Kode Pos"
                placeholder="Kode Pos"
                required
                disabled
                otherProps={{ value: "14240" }}
              />
            </Box>
          </Box>
          <AdminTextArea
            label="Detail Alamat"
            placeholder="Detail Alamat"
            required
            disabled
            otherProps={{ value: "Jl. Pegangsaan Raya No. 28" }}
            sx={{ maxWidth: "19.688rem" }}
            multiline
            rows={4}
            maxRows={4}
          />
          <Box sx={{ display: "flex", alignItems: "center", gap: "2rem" }}>
            <MainButton
              variant="outlined"
              sx={{ border: "primary.500", color: "primary.500" }}
              onClick={() => setSection("")}
            >
              Kembali
            </MainButton>
            <MainButton
              variant="contained"
              sx={{ bgcolor: "primary.500", mr: "0.5rem" }}
              onClick={() => navigate("/admin-event/approval/rent-detail")}
            >
              Selanjutnya
            </MainButton>
          </Box>
        </form>
      </Paper>
    </>
  );
};

export default ApprovalInfoEO;
