import { Button } from "@mui/material";
import React from "react";

function MainButton({
  variant,
  size,
  children,
  onClick,
  type,
  style,
  sx,
  startIcon,
  disabled,
  otherProps,
}) {
  return (
    <Button
      variant={variant}
      size={size}
      type={type}
      style={style}
      sx={{
        borderRadius: 0,
        height: "3rem",
        boxShadow: "none",
        "&:hover": { boxShadow: "none" },
        ...sx,
      }}
      startIcon={startIcon}
      disabled={disabled}
      onClick={onClick}
      {...otherProps}
    >
      {children}
    </Button>
  );
}

export default MainButton;
