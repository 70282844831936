import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";

import { Grid } from "@mui/material";

const Layout = ({ children }) => {
  const [title, setTitle] = useState("");
  const [value, setValue] = useState("");
  useEffect(() => {
    // The reason for this, as explained in the MDN Docs is that an arrow function wrapped by () will return the value it wraps, so if I wanted to use curly braces I had to add the return
    setValue("");
    return window.location.pathname === "/"
      ? setTitle("Home")
      : window.location.pathname === "/News"
      ? setTitle("News")
      : window.location.pathname === "/about"
      ? setTitle("About TIM")
      : window.location.pathname === "/contact"
      ? setTitle("Contact")
      : window.location.pathname === "/submit-event"
      ? setTitle("Submit Your Event")
      : window.location.pathname === "/getting-here"
      ? setTitle("Getting Here")
      : window.location.pathname === "/venue"
      ? setTitle("Venue Map")
      : window.location.pathname === "/exhibition-event"
      ? setTitle("Exhibitions & Events")
      : window.location.pathname === "/exhibition-all"
      ? setTitle("Exhibitions & Events")
      : window.location.pathname === "/exhibition-view"
      ? setTitle("Exhibitions & Events")
      : window.location.pathname === "/news"
      ? setTitle("News")
      : window.location.pathname === "/news-onClick"
      ? setTitle("News")
      : window.location.pathname === "/admission"
      ? setTitle("Admission")
      : setTitle("No Page");
  }, [children]);

  return (
    <>
      <Grid
        container
        backgroundColor="white"
        display={"flex"}
        direction="column"
      >
        <Header title={title} value={value} setValue={setValue} />
        {children}
        <Footer />
      </Grid>
    </>
  );
};

export default Layout;
