import React from "react";
import { Grid, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import MainButton from "../Elements/MainButton";
import AdminTextArea from "../Elements/AdminTextArea";
import RequiredLabel from "../Elements/RequiredLabel";
import AdminInput from "../Elements/AdminInput";
import AdminInputX from "../Elements/AdminInputX";

const SupportFile = () => {
  return (
    <>
      <Grid
        container
        sx={{ width: "675px", marginTop: "20px" }}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Grid item display={"flex"} direction="row" xs={5.5}>
          <Typography fontSize={"20px"} fontWeight={"700"}>
            Judul Berkas Pendukung
          </Typography>
        </Grid>
        <Grid item display={"flex"} direction="row" xs={5.5}>
          <Typography fontSize={"20px"} fontWeight={"700"}>
            Media Berkas Pendukung
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ width: "765px" }}
        gap={2}
        display={"flex"}
        // justifyContent={"space-between"}
      >
        <Grid item xs={5.5}>
          <Grid item marginTop={"20px"}>
            <AdminInput placeholder={"Judul Berkas Pendukung"} />
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid item marginTop={"20px"}>
            <AdminInputX placeholder={"Upload Media"} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SupportFile;
