import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

// redux
import { setUserData } from "../../redux/feature/userSlice";

// component
import UserHeader from "../../components/UserDashboard/UserHeader";
import BookingCard from "../../components/MyBooking/BookingCard";

function MyBooking() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = {
    name: "Louise Thompson",
    type: "Event Organizer",
    phone: "083221664825",
    email: "louisethompson@gmail.com",
    initial: "L",
    role: "e",
  };

  useEffect(() => {
    dispatch(setUserData(userData));
  }, []);

  const dummyBookingData = [
    {
      id: 1,
      title: "Animakini X Ciffest 2023",
      orderId: 12651257126,
      ticketAmount: 2,
      date: "Senin, 20 Februari 2023",
      status: "Menunggu Pembayaran",
    },
    {
      id: 2,
      title: "Animakini X Ciffest 2023",
      orderId: 12651257126,
      ticketAmount: 2,
      date: "Senin, 20 Februari 2023",
      status: "Sudah Dibayar",
    },
  ];

  return (
    <>
      <Grid container display="flex" direction="column" sx={{ gap: "1rem" }}>
        <UserHeader title="My Booking" isFilter />
        {dummyBookingData.map((item) => (
          <BookingCard
            key={item.id}
            title={item.title}
            orderId={item.orderId}
            ticketAmount={item.ticketAmount}
            date={item.date}
            status={item.status}
            onShowDetail={() => navigate(`/mybooking/${item.id}`)}
          />
        ))}
      </Grid>
    </>
  );
}

export default MyBooking;
