import React from "react";
import { Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import CustomModal from "./CustomModal";
// import SuccessIcon from "../../assets/Sukses.png";
import { modalState, setSuccessed } from "../../redux/feature/modalSlice";

const Success = ({ toggle }) => {
  const { isSuccessed } = useSelector(modalState);
  const dispatch = useDispatch();

  setTimeout(() => {
    dispatch(setSuccessed(false));
  }, 3000);

  return (
    <>
      <CustomModal isShowing={isSuccessed} toggle={toggle} width="421px">
        <Grid
          container
          sx={{
            backgroundColor: "white",
            borderRadius: "4px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "59px 79px",
          }}
        >
          <Grid item>
            {/* <img src={SuccessIcon} alt="" /> */}
          </Grid>
          <Grid item fontWeight={"700"} fontSize="20px">
            BERHASIL
          </Grid>
        </Grid>
      </CustomModal>
    </>
  );
};

export default Success;
