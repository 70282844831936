import React from "react";
import { Button, Grid } from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

// components
import MainButton from "../components/Elements/MainButton";

const Admission = () => {
  const navigate = useNavigate();
  return (
    <>
      <Grid display={"flex"} direction="column" padding="1rem 7rem" gap={2}>
        <Grid
          item
          display={"flex"}
          direction="row"
          alignItems={"center"}
          gap={2}
        >
          <Grid item paddingTop="0.1rem">
            <Icon
              icon="material-symbols:home-outline"
              color="#1f1f1f"
              width="16"
              height="16"
            />
          </Grid>
          <Grid item fontWeight="500" fontSize="16px">
            Home
          </Grid>
          <Grid item fontWeight="500" fontSize="16px">
            {">"}
          </Grid>
          <Grid item fontWeight="500" fontSize="16px">
            Plan Your Visit
          </Grid>
          <Grid item fontWeight="500" fontSize="16px">
            {">"}
          </Grid>
          <Grid item color={"#828282"} fontWeight="500" fontSize="16px">
            Admission
          </Grid>
        </Grid>
        <Grid
          item
          display={"flex"}
          direction="row"
          justifyContent="space-between"
          gap={3}
        >
          {/* column 1 */}
          <Grid item display={"flex"} direction="column" gap={2} xs={4}>
            <Grid item fontWeight="700" fontSize="48px">
              Admission
            </Grid>
            <Grid item color="#828282" fontWeight="600" fontSize="16px">
              Jl. Cikini Raya No.73, RT.8/RW.2, Cikini, Kec. Menteng, Kota
              Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10330
            </Grid>
            <Grid item>
              <Button
                style={{
                  backgroundColor: "#D14F1E",
                  color: "white",
                  borderRadius: "0",
                  padding: "12px 24px",
                }}
                onClick={() => navigate("/contact")}
              >
                Contact Us
              </Button>
            </Grid>
          </Grid>
          {/* column 2 */}
          <Grid item display={"flex"} direction="column" gap={2} xs={7}>
            <Grid item>
              <Grid
                item
                sx={{
                  display: "inline-block",
                  backgroundColor: "#FFF1EB",
                  color: "#D14F1E",
                  padding: "12px 24px",
                  fontWeight: "700",
                  fontSize: "24px",
                }}
              >
                Informasi Submit Your Event
              </Grid>
            </Grid>
            <Grid item paddingLeft={"1rem"} fontWeight="500" fontSize="16px">
              <p>Selamat datang, para Event Organizer!</p>
              <p>
                Kami senang memberitahu bahwa pendaftaran event di platform kami
                sekarang sudah dibuka! Dengan mendaftarkan event Anda, Anda akan
                memiliki akses ke ribuan pengunjung potensial yang tertarik
                dengan acara Anda.
              </p>
              <p>
                Proses pendaftaran sangat mudah dan cepat. Cukup kunjungi
                halaman Submit Your Event di situs web kami, lengkapi formulir
                yang disediakan dengan informasi tentang acara Anda, dan
                pastikan untuk memilih tempat yang sesuai dengan kebutuhan Anda.
              </p>
              <p>
                Kami menawarkan berbagai paket pendaftaran yang dapat
                disesuaikan dengan anggaran dan kebutuhan acara Anda. Dengan
                mendaftar, Anda akan mendapatkan manfaat seperti promosi di
                media sosial, publikasi di situs web kami, dan bantuan dalam
                pemasaran acara.
              </p>
              <p>
                Jangan lewatkan kesempatan untuk mempromosikan acara Anda kepada
                audiens yang lebih luas. Segera daftarkan acara Anda di platform
                kami dan mulai menikmati manfaatnya!
              </p>
              <p>Salam hangat,</p>
              <p>Tim Taman Ismail Marzuki.</p>
            </Grid>
            <Grid item>
              <Grid
                item
                sx={{
                  display: "inline-block",
                  backgroundColor: "#FFF1EB",
                  color: "#D14F1E",
                  padding: "12px 24px",
                  fontWeight: "700",
                  fontSize: "24px",
                }}
              >
                Berkas Pendukung
              </Grid>
            </Grid>
            <Grid container display={"flex"} direction={"row"} gap={2}>
              {/* <Grid item>
                <Button
                  style={{
                    backgroundColor: "#D14F1E",
                    color: "white",
                    borderRadius: "0",
                    padding: "12px 24px",
                  }}
                >
                  Format Proposal
                </Button>
              </Grid> */}
              <MainButton variant={"contained"}>Format Proposal</MainButton>
              <MainButton variant={"contained"}>Gambar Venue TIM</MainButton>
              <MainButton variant={"contained"}>
                Syarat & Ketentuan Event
              </MainButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Admission;
