import { Box, FormLabel, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import Headline from "../../components/headline";
import { Icon } from "@iconify/react";
import AdminInput from "../../components/Elements/AdminInput";
import AdminTextArea from "../../components/Elements/AdminTextArea";
import MainButton from "../../components/Elements/MainButton";
import { useNavigate } from "react-router-dom";
import LargeImagePreview from "../../components/LargeImagePreview";
import DatePicker from "../../components/Elements/DatePicker";
import MainStepper from "../../components/Elements/MainStepper";

const ApprovalEventPackage = () => {
  const navigate = useNavigate();

  const headline = (
    <>
      <Box display={"flex"} direction={"row"} alignItems={"center"} gap={2}>
        <Icon
          icon="mdi:clipboard-text-outline"
          color={"#1F1F1F"}
          width="2rem"
          height="2rem"
        />
        <Typography sx={{ fontSize: "2rem", fontWeight: "700" }}>
          Paket Event
        </Typography>
      </Box>
    </>
  );

  return (
    <>
      <Paper sx={{ padding: "1.5rem", borderRadius: 0 }}>
        <Box sx={{ width: "9.25rem", marginBottom: "2.25rem" }}>
          <MainStepper activeStep={1} />
        </Box>
        <Headline title={headline} />
        <form
          style={{
            marginTop: "2.25rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "2rem",
              marginBottom: "2rem",
            }}
          >
            <Box
              sx={{
                minWidth: "19.688rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormLabel
                sx={{
                  marginBottom: "0.5rem",
                  color: "neutral.800",
                  fontSize: "1.25rem",
                  fontWeight: 700,
                }}
              >
                <span style={{ color: "#B43127", fontWeight: "bold" }}>*</span>
                Tanggal Mulai
              </FormLabel>
              <DatePicker
                disabled
                required
                otherProps={{ value: "11/03/2023" }}
              />
            </Box>
            <Box
              sx={{
                minWidth: "19.688rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormLabel
                sx={{
                  marginBottom: "0.5rem",
                  color: "neutral.800",
                  fontSize: "1.25rem",
                  fontWeight: 700,
                }}
              >
                <span style={{ color: "#B43127", fontWeight: "bold" }}>*</span>
                Tanggal Selesai
              </FormLabel>
              <DatePicker
                disabled
                required
                otherProps={{ value: "12/03/2023" }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              minWidth: "19.688rem",
              display: "flex",
              flexDirection: "column",
              marginBottom: "2.25rem",
            }}
          >
            <FormLabel
              sx={{
                marginBottom: "0.5rem",
                color: "neutral.800",
                fontSize: "1.25rem",
                fontWeight: 700,
              }}
            >
              <span style={{ color: "#B43127", fontWeight: "bold" }}>*</span>
              Poster
            </FormLabel>
            <LargeImagePreview
              file={{
                url: "/assets/about3.jpg",
                name: "Image_001.JPEG",
              }}
              width="20rem"
              height="20rem"
            />
          </Box>
          <Grid container spacing={4} sx={{ alignItems: "end" }}>
            <Grid item xs={12} md={3}>
              <AdminInput
                label="Paket"
                placeholder="Paket"
                required
                disabled
                otherProps={{ value: "Standard" }}
              />
              <AdminInput
                placeholder="Paket"
                required
                disabled
                otherProps={{ value: "VIP" }}
              />
              <AdminInput
                placeholder="Paket"
                required
                disabled
                otherProps={{ value: "VVIP" }}
              />
              <Typography
                sx={{
                  textAlign: "end",
                  color: "white",
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                Total
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <AdminInput
                label="Harga Paket"
                placeholder="Harga Paket"
                required
                disabled
                otherProps={{ value: "Rp 100.000" }}
              />
              <AdminInput
                placeholder="Harga Paket"
                required
                disabled
                otherProps={{ value: "Rp 125.000" }}
              />
              <AdminInput
                placeholder="Harga Paket"
                required
                disabled
                otherProps={{ value: "Rp 150.000" }}
              />
              <Typography
                sx={{ textAlign: "end", fontWeight: "700", fontSize: "20px" }}
              >
                Total
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <AdminInput
                label="Total Tiket"
                placeholder="Total Tiket"
                required
                disabled
                otherProps={{ value: "100" }}
              />
              <AdminInput
                placeholder="Total Tiket"
                required
                disabled
                otherProps={{ value: "100" }}
              />
              <AdminInput
                placeholder="Total Tiket"
                required
                disabled
                otherProps={{ value: "100" }}
              />
              <Typography
                sx={{ textAlign: "start", fontWeight: "700", fontSize: "20px" }}
              >
                300
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography
                sx={{
                  fontSize: "1.25rem",
                  fontWeight: 700,
                  marginBottom: { xs: "1rem", md: "3rem" },
                }}
              >
                10.000.000
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.25rem",
                  fontWeight: 700,
                  marginBottom: { xs: "1rem", md: "3.5rem" },
                }}
              >
                12.500.000
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.25rem",
                  fontWeight: 700,
                  marginBottom: { xs: "1rem", md: "2rem" },
                }}
              >
                15.000.000
              </Typography>
              <Typography
                sx={{ textAlign: "start", fontWeight: "700", fontSize: "20px" }}
              >
                37.500.000
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", alignItems: "center", gap: "2rem" }}>
            <MainButton
              variant="outlined"
              sx={{ border: "primary.500", color: "primary.500" }}
              onClick={() => navigate(-1)}
            >
              Kembali
            </MainButton>
            <MainButton
              variant="contained"
              sx={{ bgcolor: "primary.500", mr: "0.5rem" }}
              onClick={() => navigate("/admin-event/approval/event-location")}
            >
              Selanjutnya
            </MainButton>
          </Box>
        </form>
      </Paper>
    </>
  );
};

export default ApprovalEventPackage;
