import React from "react";
import { Box } from "@mui/material";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const TabsMasterData = ({ handleChange, value, item }) => {
  return (
    <>
      <Box sx={{ marginBottom: "1rem" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#D14F1E",
            },
          }}
        >
          <Tab
            label="Past"
            sx={{
              textTransform: "none",
              color: "#BDBDBD",
            }}
          />
          <Tab
            label="Now Showing"
            sx={{
              textTransform: "none",
              color: "#BDBDBD",
            }}
          />
          <Tab
            label="Incoming"
            sx={{
              textTransform: "none",
              color: "#BDBDBD",
            }}
          />
        </Tabs>
      </Box>
    </>
  );
};

export default TabsMasterData;
