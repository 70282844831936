import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Divider } from "@mui/material";
import { Icon } from "@iconify/react";
// import { useFormik } from "formik";

// component
import EventMainTitle from "./EventMainTitle";
import MainButton from "../Elements/MainButton";
import MainInput from "../Elements/MainInput";
import TextArea from "../Elements/TextArea";
import DatePicker from "../Elements/DatePicker";
import UploadForm from "../Elements/UploadForm";
import ImagePreview from "../ImagePreview";
import SetAddressMapModal from "../SetAddressMapModal";
import useModal from "../../hooks/useModal";

function PostEventForm({ status }) {
  const [file, setFile] = useState();
  const [initialValues, setInitialValues] = useState({});
  const [packages, setPackages] = useState([1]);
  const [detailAddress, setDetailAddress] = useState();

  const { isShowing, toggle } = useModal();

  const handleUploadImage = (files) => {
    const fileLocal = files[0];
    setFile(fileLocal);
  };

  useEffect(() => {
    const nullValues = {
      eventName: "",
      startDate: "",
      endDate: "",
      description: "",
      importantInfo: "",
      packageCategory1: "",
      ticketPrice1: "",
      totalTicket1: "",
      totalTicket: "",
      totalTicketPrice: "",
    };
    const filledValues = {
      eventName: "Animakini X Ciffest 2023",
      startDate: "20 Februari 2023",
      endDate: "22 Februari 2023",
      description:
        "Across nine screens, Isaac Julien’s Lina Bo Bardi—A Marvellous Entanglement (2019) explores the life, work, and legacy of the Italian-Brazilian modernist architect Lina Bo Bardi (1914–1992). Born in Rome, Italy, and relocating to São Paulo, Brazil in the 1940s, Bo Bardi was a multifaceted artist and t..",
      importantInfo:
        "Waktu dan Tempat :20-22 Februari 2023 Redemption : 12.00 PM (20-22 Februari 2023) ..",
      packageCategory1: "VIP",
      ticketPrice1: "500.000",
      totalTicket1: "100",
      totalTicket: "100",
      totalTicketPrice: "107.500.000",
    };
    if (status === "Ditolak" || status === "Disetujui") {
      setInitialValues(filledValues);
      setDetailAddress({
        address:
          "Gedung Teater Besar Taman Ismail Marzuki - Jl. Cikini Raya No.73, RT.8/RW.2, Cikini, Kec. Menteng, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10330",
        addressName: "Gedung Taman Ismail Marzuki",
      });
      setFile({
        url: "https://picsum.photos/200/200",
        name: "setFile",
      });
    } else {
      setInitialValues(nullValues);
    }
  }, []);

  // const {
  //   handleChange,
  //   handleSubmit,
  //   errors,
  //   touched,
  //   resetForm,
  //   values,
  //   setFieldValue,
  // } = useFormik({
  //   initialValues: {
  //     eventName: "",
  //     startDate: "",
  //     endDate: "",
  //     description: "",
  //     importantInfo: "",
  //     packageCategory1: "",
  //     ticketPrice1: "",
  //     totalTicket1: "",
  //     totalTicket: "",
  //     totalTicketPrice: "",
  //   },

  //   onSubmit: () => {
  //     // test
  //   },
  // });

  return (
    <>
      <form>
        <Box
          sx={{
            paddingX: "1rem",
            paddingTop: "1.188rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "1rem",
            border: "1px solid",
            borderColor: "neutral.400",
            marginTop: "1rem",
          }}
        >
          <EventMainTitle
            iconName="mdi:clipboard-text-outline"
            title="Deskripsi"
          />
          <Box sx={{ width: "100%" }}>
            <MainInput
              label="Nama Event"
              placeholder="Masukkan Nama Event"
              name="eventName"
              // onChange={handleChange}
              otherProps={{ defaultValue: initialValues.eventName }}
            />
            <Box
              sx={{
                display: "flex",
                gap: { xs: 0, sm: "1rem" },
                alignItems: "center",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <DatePicker
                label="Tanggal Mulai"
                placeholder="Tanggal Mulai"
                name="startDate"
                // onChange={handleChange}
                otherProps={{ defaultValue: initialValues.startDate }}
              />
              <Divider
                sx={{
                  width: "0.5rem",
                  height: "2px",
                  bgcolor: "neutral.800",
                  marginTop: "0.625rem",
                  display: {
                    xs: "none",
                    sm: "block",
                  },
                }}
                orientation="horizontal"
              />
              <DatePicker
                label="Tanggal Selesai"
                placeholder="Tanggal Selesai"
                name="endDate"
                // onChange={handleChange}
                otherProps={{ defaultValue: initialValues.endDate }}
              />
            </Box>
            <TextArea
              label="Deskripsi Event"
              placeholder="Masukkan Deskripsi Event"
              multiline
              rows={2}
              maxRows={4}
              name="description"
              // onChange={handleChange}
              otherProps={{ defaultValue: initialValues.description }}
            />
            <TextArea
              label="Info Penting"
              placeholder="Masukkan Info Penting"
              multiline
              rows={2}
              maxRows={4}
              name="importantInfo"
              // onChange={handleChange}
              otherProps={{ defaultValue: initialValues.importantInfo }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            paddingX: "1rem",
            paddingY: "1.188rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "1rem",
            border: "1px solid",
            borderColor: "neutral.400",
            marginTop: "1rem",
          }}
        >
          <EventMainTitle
            iconName="material-symbols:list-alt-outline-rounded"
            title="Paket"
            caption="300 Tiket"
          />
          {packages.map((item) => (
            <Grid
              container
              spacing={2}
              sx={{ paddingRight: "1rem", alignItems: "center" }}
            >
              <Grid item xs={12} md={4}>
                <MainInput
                  label={`Kategori Paket ${item}`}
                  placeholder={`Masukkan Kategori Paket ${item}`}
                  name={`packageCategory${item}`}
                  // onChange={handleChange}
                  otherProps={{
                    defaultValue: initialValues[`packageCategory${item}`],
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <MainInput
                  label="Harga Tiket"
                  placeholder="Nominal"
                  InputProps={{
                    startAdornment: (
                      <Typography variant="body2" sx={{ mr: "0.4rem" }}>
                        Rp
                      </Typography>
                    ),
                  }}
                  name={`ticketPrice${item}`}
                  // onChange={handleChange}
                  otherProps={{
                    defaultValue: initialValues[`ticketPrice${item}`],
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <MainInput
                  label="Total Tiket"
                  placeholder="Total"
                  InputProps={{
                    endAdornment: (
                      <Typography variant="caption" sx={{ mr: "0.4rem" }}>
                        Tiket
                      </Typography>
                    ),
                  }}
                  name={`totalTicket${item}`}
                  // onChange={handleChange}
                  otherProps={{
                    defaultValue: initialValues[`totalTicket${item}`],
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography>Rp 50.000.000</Typography>
              </Grid>
              <Grid item xs={12} md={1}>
                <MainButton
                  disabled={item === 1}
                  variant="outlined"
                  sx={{ width: "fit-content" }}
                  onClick={() =>
                    setPackages(
                      packages.filter((packageItem) => packageItem !== item)
                    )
                  }
                >
                  <Icon
                    icon="ph:trash-simple-bold"
                    height="1.25rem"
                    width="1.25rem"
                  />
                </MainButton>
              </Grid>
            </Grid>
          ))}
          <MainButton
            variant="contained"
            sx={{ width: "fit-content" }}
            startIcon={
              <Icon
                icon="material-symbols:add"
                height="1.25rem"
                width="1.25rem"
              />
            }
            onClick={() =>
              setPackages([
                ...packages,
                packages.length > 0 ? packages[packages.length - 1] + 1 : 1,
              ])
            }
          >
            Tambah Kategori
          </MainButton>
          <Divider
            sx={{
              borderBottom: "2px",
              borderBottomColor: "primary.500",
              borderStyle: "dashed",
              width: "100%",
            }}
          />
          <Grid container sx={{ paddingRight: "1rem" }}>
            <Grid item xs={12} md={4}>
              <Typography variant="body2">Total</Typography>
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <Typography variant="body2">
                {initialValues.totalTicket}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="body2">
                Rp {initialValues.totalTicketPrice}
              </Typography>
            </Grid>
          </Grid>
          <Divider
            sx={{ width: "100%", bgcolor: "#D9D9D9", marginTop: "1rem" }}
            orientation="horizontal"
          />
          <Box sx={{ margin: 0 }}>
            <Typography variant="body2">Gambar Venue</Typography>
            {file && <ImagePreview file={file} onClick={() => setFile(null)} />}
          </Box>
          <UploadForm onDrop={handleUploadImage} />
          <Typography variant="body2">
            <strong style={{ color: "#FF723D" }}>*</strong>Format file: .jpg,
            .jpeg, .png | Ukuran file max 10Mb
          </Typography>
        </Box>
        <Box
          sx={{
            paddingX: "1rem",
            paddingY: "1.188rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "1rem",
            border: "1px solid",
            borderColor: "neutral.400",
            marginTop: "1rem",
          }}
        >
          <EventMainTitle
            iconName="material-symbols:my-location-outline"
            title="Lokasi"
          />
          {detailAddress ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderColor: "primary.600",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  padding: "1rem",
                  width: "100%",
                  bgcolor: "primary.100",
                }}
              >
                <Typography variant="body1" fontWeight="700">
                  {detailAddress.address}
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight="600"
                  color="neutral.600"
                  marginTop="0.5rem"
                >
                  {detailAddress.addressName}
                </Typography>
                <Box
                  sx={{
                    marginTop: "1.5rem",
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <Icon
                    icon="material-symbols:location-on"
                    height="1.5rem"
                    width="1.25rem"
                    color="#D14F1E"
                  />
                  <Typography
                    variant="body1"
                    fontWeight="600"
                    color="primary.600"
                  >
                    Sudah Pinpoint
                  </Typography>
                </Box>
              </Box>
              <MainButton
                variant="contained"
                sx={{ width: "fit-content" }}
                startIcon={
                  <Icon
                    icon="material-symbols:edit-outline-rounded"
                    height="1.25rem"
                    width="1.25rem"
                  />
                }
                onClick={toggle}
              >
                Ubah Alamat
              </MainButton>
            </>
          ) : (
            <>
              {/* <GoogleMaps /> */}
              <MainButton
                variant="contained"
                sx={{ width: "fit-content" }}
                startIcon={
                  <Icon
                    icon="material-symbols:add"
                    height="1.25rem"
                    width="1.25rem"
                  />
                }
                onClick={toggle}
              >
                Tambah Alamat Baru
              </MainButton>
            </>
          )}
        </Box>
        <Divider
          sx={{
            height: "1rem",
            width: "auto",
            marginTop: "1rem",
            marginX: "-1rem",
            bgcolor: "#F5F5F5",
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            marginTop: "1rem",
          }}
        >
          <MainButton variant="contained" sx={{ width: "fit-content" }}>
            Submit
          </MainButton>
        </Box>
      </form>
      <SetAddressMapModal
        isShowing={isShowing}
        toggle={toggle}
        setDetailAddress={setDetailAddress}
      />
    </>
  );
}

export default PostEventForm;
