import React from "react";
import { Grid, Box } from "@mui/material";

import { formEventState } from "../../redux/feature/formEventSlice";
import { useSelector } from "react-redux";

const BulletPoints = () => {
  const { page } = useSelector(formEventState); // to get state data
  return (
    <>
      {/* bullet points */}
      <Grid display="flex" direction="row" gap={2}>
        <Grid item>
          <Box
            border={1}
            borderColor={page === 0 ? "#D14F1E" : "#BDBDBD"}
            backgroundColor={page === 0 ? "#D14F1E" : null}
            borderRadius={"50%"}
            width="12px"
            height="12px"
          ></Box>
        </Grid>
        <Grid
          item
          height="1px"
          backgroundColor="#BDBDBD"
          width={"3rem"}
          marginTop="0.4rem"
        ></Grid>
        <Grid item>
          <Box
            border={1}
            borderColor={page === 1 ? "#D14F1E" : "#BDBDBD"}
            backgroundColor={page === 1 ? "#D14F1E" : null}
            borderRadius={"50%"}
            width="12px"
            height="12px"
          ></Box>
        </Grid>
        <Grid
          item
          height="1px"
          backgroundColor="#BDBDBD"
          width={"3rem"}
          marginTop="0.4rem"
        ></Grid>
        <Grid item>
          <Box
            border={1}
            borderColor={page === 2 ? "#D14F1E" : "#BDBDBD"}
            backgroundColor={page === 2 ? "#D14F1E" : null}
            borderRadius={"50%"}
            width="12px"
            height="12px"
          ></Box>
        </Grid>
      </Grid>
    </>
  );
};

export default BulletPoints;
