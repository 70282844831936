export const homeContent =
  "Art exhibitions, music concerts, theater performances and interesting cultural events at Taman Ismail Marzuki. Explore inspiring works and enjoy a unique art experience.";

export const submitYourEvent =
  "Promote your event! Submit and register your art event, concert or theater at Taman Ismail Marzuki. Reach a wider audience and make your event the highlight.";

export const admission =
  "Get your tickets now! Get information on ticket prices, packages and ticket booking policies for art events, concerts and theater performances at Taman Ismail Marzuki. Enjoy an unforgettable art experience with us.";

export const gettingHere =
  "Find the right route! Get directions, transportation options, and parking information to reach Taman Ismail Marzuki easily. Explore the best way to get to our art destinations.";

export const venueMap =
  "Navigate easily! Explore the Taman Ismail Marzuki location map to find the location of art venues, theatres, galleries and other facilities. Find the right place and enjoy a comfortable art experience.";

export const exhibitions =
  "Providing up-to-date information on art exhibitions, theatrical performances, music concerts and other special events held at Taman Ismail Marzuki.";

export const about =
  "Our history and mission! Find information about Taman Ismail Marzuki, a famous art venue in Jakarta. Learn about our vision of supporting and promoting arts and culture through various programs and events.";

export const news = `Latest and hottest news! Get the latest updates on art events, exhibitions, concerts and news related to Taman Ismail Marzuki. Don't miss the latest information and follow the latest developments in the world of art and culture.`;

export const contact =
  "Call us now! Find complete contact information for Taman Ismail Marzuki and get assistance or send us your questions. We are happy to help you.";
