import React from "react";
import HeaderFooter from "./HeaderFooter";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <HeaderFooter>
        <Outlet />
      </HeaderFooter>
    </>
  );
};

export default Layout;
