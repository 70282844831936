import { Icon } from "@iconify/react";
import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

// component
import AdminInput from "../../components/Elements/AdminInput";
import AdminTextArea from "../../components/Elements/AdminTextArea";
import DownloadInput from "../../components/Elements/DownloadInput";
import MainButton from "../../components/Elements/MainButton";
import Headline from "../../components/headline";
import useModal from "../../hooks/useModal";
import ApprovalModal from "../../components/AdminEvent/ApprovalModal";
import ReasonRejectModal from "../../components/AdminEvent/ReasonRejectModal";
import MainStepper from "../../components/Elements/MainStepper";

const ApprovalEventDetail = () => {
  const navigate = useNavigate();
  const { toggle: toggleApproval, isShowing: isShowingApproval } = useModal();
  const { toggle: toggleRejected, isShowing: isShowingRejected } = useModal();

  const headline = (
    <>
      <Box display={"flex"} direction={"row"} alignItems={"center"} gap={2}>
        <Icon
          icon="mdi:clipboard-text-outline"
          color={"#1F1F1F"}
          width="2rem"
          height="2rem"
        />
        <Typography sx={{ fontSize: "2rem", fontWeight: "700" }}>
          Detail Event
        </Typography>
      </Box>
    </>
  );
  return (
    <>
      <Paper sx={{ padding: "1.5rem", borderRadius: 0 }}>
        <Box sx={{ width: "9.25rem", marginBottom: "2.25rem" }}>
          <MainStepper activeStep={2} />
        </Box>
        <Headline title={headline} />
        <Typography
          sx={{ fontSize: "1.5rem", fontWeight: 700, marginTop: "2.25rem" }}
        >
          Data Event
        </Typography>
        <form
          style={{
            marginTop: "2.25rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <AdminInput
            label="Nama Event"
            placeholder="Nama Event"
            required
            disabled
            otherProps={{ value: "Animakini X Ciffest 2023" }}
            sx={{ maxWidth: "19.688rem" }}
          />
          <AdminInput
            label="Jenis Event"
            placeholder="Jenis Event"
            required
            disabled
            otherProps={{ value: "Pementasan" }}
            sx={{ maxWidth: "19.688rem" }}
          />
          <AdminTextArea
            label="Detail Event"
            placeholder="Detail Alamat"
            required
            disabled
            otherProps={{
              value:
                "Event pementasan dengan menampilkan animasi dan elektronika terkini",
            }}
            sx={{ maxWidth: "19.688rem" }}
            multiline
            rows={3}
            maxRows={4}
          />
          <AdminInput
            label="Jumlah Pengunjung/Tiket"
            placeholder="Jumlah Pengunjung/Tiket"
            required
            disabled
            otherProps={{ value: "300" }}
            sx={{ maxWidth: "19.688rem" }}
          />
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: 700,
              marginBottom: "2.25rem",
            }}
          >
            Dokumen Event
          </Typography>
          <DownloadInput
            label="Proposal Event"
            placeholder="Proposal Event"
            required
            disabled
            otherProps={{ value: "Proposal Animakini.Pdf" }}
            sx={{ width: "19.688rem" }}
          />
          <Box sx={{ display: "flex", alignItems: "center", gap: "2rem" }}>
            <MainButton
              variant="outlined"
              sx={{ border: "primary.500", color: "primary.500" }}
              onClick={() => navigate(-1)}
            >
              Kembali
            </MainButton>
            <MainButton
              variant="contained"
              sx={{ bgcolor: "primary.500", mr: "0.5rem" }}
              onClick={toggleApproval}
            >
              Konfirmasi
            </MainButton>
          </Box>
        </form>
      </Paper>
      <ApprovalModal
        isShowing={isShowingApproval}
        handleClose={toggleApproval}
        toggle={toggleApproval}
        onApprove={toggleApproval}
        onReject={() => {
          toggleApproval();
          toggleRejected();
        }}
      />
      <ReasonRejectModal
        isShowing={isShowingRejected}
        handleClose={toggleRejected}
        toggle={toggleRejected}
        onSubmit={toggleRejected}
        onBack={toggleRejected}
      />
    </>
  );
};

export default ApprovalEventDetail;
