import { Box, Typography } from "@mui/material";
import React from "react";

function FieldData({ title, content, secondContent, sx }) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "1.5rem",
          gap: "0.3rem",
          ...sx,
        }}
      >
        <Typography fontSize="0.875rem" color="neutral.600">
          {title} {secondContent}
        </Typography>
        <Typography fontSize="0.875rem" color="neutral.800" fontWeight="700">
          {content}
        </Typography>
      </Box>
    </>
  );
}

export default FieldData;
