import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Icon } from "@iconify/react";

// component
import EventMainTitle from "./EventMainTitle";
import FieldData from "../Elements/FieldData";
import MainButton from "../Elements/MainButton";

function ProposalEventDetail({ data }) {
  console.log(data);
  return (
    <>
      <Box
        sx={{
          paddingX: "1rem",
          paddingTop: "1.188rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "1rem",
          border: "1px solid",
          borderColor: "neutral.400",
          marginTop: "1rem",
        }}
      >
        <EventMainTitle
          iconName="material-symbols:event-list-outline-sharp"
          title="Info Event Organizer"
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                paddingX: "1rem",
                paddingY: "0.5rem",
                bgcolor: "neutral.200",
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: 700,
                  color: "neutral.800",
                }}
              >
                Data Event Organizer
              </Typography>
            </Box>
            <Box sx={{ padding: "1rem", paddingBottom: 0 }}>
              <FieldData
                title="Nama Event Organizer"
                content={data?.event_organizer_name}
              />
              <FieldData title="Email" content={data?.email} />
              <FieldData title="No HP" content={data?.phone} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                paddingX: "1rem",
                paddingY: "0.5rem",
                bgcolor: "neutral.200",
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: 700,
                  color: "neutral.800",
                }}
              >
                Alamat Event Organizer
              </Typography>
            </Box>
            <Box sx={{ padding: "1rem", paddingBottom: 0 }}>
              <FieldData title="Negara" content={data?.negara} />
              <FieldData title="Provinsi" content={data?.provinsi} />
              <FieldData title="Kabupaten/Kota" content={data?.kota} />
              <FieldData title="Kecamatan" content={data?.kec} />
              <FieldData title="Kode Pos" content={data?.kode_pos_id} />
              <FieldData title="Detail Alamat" content={data?.address} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          paddingX: "1rem",
          paddingTop: "1.188rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "1rem",
          border: "1px solid",
          borderColor: "neutral.400",
          marginTop: "1rem",
        }}
      >
        <EventMainTitle iconName="mdi:calendar-range" title="Detail Sewa" />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                paddingX: "1rem",
                paddingY: "0.5rem",
                bgcolor: "neutral.200",
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: 700,
                  color: "neutral.800",
                }}
              >
                Durasi Sewa
              </Typography>
            </Box>
            <Box sx={{ padding: "1rem", paddingBottom: 0 }}>
              <FieldData title="Tanggal Mulai" content={data?.start_date} />
              <FieldData title="Tanggal Selesai" content={data?.start_date} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                paddingX: "1rem",
                paddingY: "0.5rem",
                bgcolor: "neutral.200",
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: 700,
                  color: "neutral.800",
                }}
              >
                Gedung/Tempat
              </Typography>
            </Box>
            <Box sx={{ padding: "1rem", paddingBottom: 0 }}>
              <FieldData title="Tempat/Lokasi" content={data?.location} />
              <FieldData title="Fasilitas 1" content="Sound System" />
              <FieldData title="Fasilitas 2" content="300 Kursi" />
              <FieldData title="Fasilitas 3" content="Proyektor" />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          paddingX: "1rem",
          paddingTop: "1.188rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "1rem",
          border: "1px solid",
          borderColor: "neutral.400",
          marginTop: "1rem",
        }}
      >
        <EventMainTitle
          iconName="material-symbols:list-alt-outline-rounded"
          title="Detail Event"
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                paddingX: "1rem",
                paddingY: "0.5rem",
                bgcolor: "neutral.200",
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: 700,
                  color: "neutral.800",
                }}
              >
                Data Event
              </Typography>
            </Box>
            <Box sx={{ padding: "1rem", paddingBottom: 0 }}>
              <FieldData title="Nama Event" content={data?.event_name} />
              <FieldData title="Jenis Event" content={data?.event_type} />
              <FieldData title="Detail Event" content={data?.event_detail} />
              <FieldData
                title="Jumlah Pengunjung/Tiket"
                content={data?.total_visitor}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                paddingX: "1rem",
                paddingY: "0.5rem",
                bgcolor: "neutral.200",
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: 700,
                  color: "neutral.800",
                }}
              >
                Dokumen Event
              </Typography>
            </Box>
            <Box sx={{ padding: "1rem", paddingBottom: 0 }}>
              <FieldData
                title="Proposal Event"
                content="Proposal Animakini.Pdf"
                sx={{ marginBottom: "0.5rem !important" }}
              />
              <MainButton
                variant="outlined"
                sx={{ width: "fit-content" }}
                startIcon={
                  <Icon
                    icon="material-symbols:download"
                    height="1.25rem"
                    width="1.25rem"
                  />
                }
              >
                Unduh Proposal
              </MainButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ProposalEventDetail;
