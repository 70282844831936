import { Icon } from "@iconify/react";
import { Box, Card, Divider, Typography } from "@mui/material";
import React from "react";
import MainButton from "../Elements/MainButton";

function CustomerHelp() {
  return (
    <>
      <Card sx={{ padding: "1rem", borderRadius: 0 }}>
        <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <Icon
            icon="streamline:interface-help-customer-support-1-customer-headset-help-microphone-phone-support"
            height="1.25rem"
            width="1.25rem"
          />
          <Typography fontSize="1rem" fontWeight="700">
            Butuh Bantuan
          </Typography>
        </Box>
        <Divider
          sx={{ bgcolor: "neutral.200", marginY: "1rem" }}
          orientation="horizontal"
        />
        <MainButton variant="outlined" sx={{ width: "fit-content" }}>
          Hubungi Customer Care
        </MainButton>
      </Card>
    </>
  );
}

export default CustomerHelp;
