import React, { useState } from "react";
import { Grid, Box, Button } from "@mui/material";

import News1 from "../assets/joko.png";
import News2 from "../assets/tingkir.png";
import News3 from "../assets/gatot.png";

import Event1 from "../assets/tari.png";
import Event2 from "../assets/paduan.png";
import Event3 from "../assets/festival.png";
import Event4 from "../assets/gigi.png";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Grid
        item
        display={"flex"}
        direction="row"
        justifyContent={"flex-end"}
        gap={2}
      >
        <Grid
          container
          display="flex"
          marginLeft={"10%"}
          color="black"
          fontFamily={"Plus Jakarta Sans"}
          fontStyle="normal"
          marginTop={"20px"}
        >
          <Grid item display={"flex"} direction="row" gap={2}>
            <Grid
              item
              fontWeight="500"
              fontSize="16px"
              onClick={() => navigate("/")}
            >
              Home
            </Grid>
            <Grid item fontWeight="500" fontSize="16px">
              {">"}
            </Grid>
            <Grid
              item
              fontWeight="500"
              fontSize="16px"
              width={"44px"}
              height="26px"
              color={"#828282"}
            >
              News
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        display="flex"
        width={"324px"}
        height="62px"
        marginLeft={"10%"}
        color="black"
        fontFamily={"Plus Jakarta Sans"}
        fontStyle="normal"
        fontWeight={"700"}
        fontSize="48px"
        lineHeight={"130%"}
        marginBottom="20px"
      >
        <Grid item display="flex" direction={"row"} gap={"50px"}>
          <Grid item>Popular News</Grid>
        </Grid>
      </Grid>

      <Grid
        item
        display={"flex"}
        direction="row"
        gap={1}
        justifyContent="center"
      >
        <Grid container marginLeft={"180px"}>
          <Grid item></Grid>
          <Grid item>
            <Box
              sx={{
                width: "660px",
                height: "407px",
                backgroundImage: `url(${Event1})`,
                backgroundSize: "cover",
              }}
            ></Box>
            <Grid
              item
              fontWeight="700"
              fontFamily={"Plus Jakarta Sans"}
              fontSize="32px"
              color="#1F1F1F"
            >
              Museum Art
            </Grid>
            <Grid item color="#D14F1E" fontWeight="500" fontSize="14px">
              Senin, 16 Jan 2023 11:45
            </Grid>
            <Grid
              item
              fontWeight="500"
              fontSize="19px"
              fontFamily={"Plus Jakarta Sans"}
            >
              Description
            </Grid>
          </Grid>

          <Grid item>
            <Box
              sx={{
                width: "350px",
                height: "407px",
                backgroundColor: "gray",
                backgroundImage: `url(${Event2})`,
                backgroundSize: "cover",
                marginLeft: "20px",
              }}
            ></Box>
            <Grid
              item
              fontWeight="700"
              marginLeft="20px"
              fontFamily={"Plus Jakarta Sans"}
              fontSize="32px"
              color="#1F1F1F"
            >
              Museum Art
            </Grid>
            <Grid
              item
              color="#D14F1E"
              marginLeft="20px"
              fontWeight="500"
              fontSize="14px"
            >
              Jumat, 02 Des 2022 19:30
            </Grid>
            <Grid
              item
              fontWeight="500"
              marginLeft="20px"
              fontSize="19px"
              fontFamily={"Plus Jakarta Sans"}
            >
              Description
            </Grid>
          </Grid>
          <Grid item>
            <Box
              sx={{
                width: "363px",
                height: "363px",
                backgroundImage: `url(${Event3})`,
                backgroundSize: "cover",
              }}
            ></Box>
            <Grid
              item
              fontWeight="700"
              fontFamily={"Plus Jakarta Sans"}
              fontSize="32px"
              color="#1F1F1F"
            >
              Museum Art
            </Grid>
            <Grid item color="#D14F1E" fontWeight="500" fontSize="14px">
              Senin, 16 Jan 2023 11:45
            </Grid>
            <Grid
              item
              fontWeight="500"
              fontSize="19px"
              fontFamily={"Plus Jakarta Sans"}
            >
              Description
            </Grid>
          </Grid>
          <Grid item>
            <Grid item>
              <Box
                sx={{
                  width: "660px",
                  height: "363px",
                  backgroundImage: `url(${Event4})`,
                  backgroundSize: "cover",
                  marginLeft: "20px",
                }}
              ></Box>
              <Grid
                item
                fontWeight="700"
                marginLeft="20px"
                fontFamily={"Plus Jakarta Sans"}
                fontSize="32px"
                color="#1F1F1F"
              >
                Museum Art
              </Grid>
              <Grid
                item
                color="#D14F1E"
                marginLeft="20px"
                fontWeight="500"
                fontSize="14px"
              >
                Senin, 16 Jan 2023 11:45
              </Grid>
              <Grid
                item
                fontWeight="500"
                marginLeft="20px"
                fontSize="19px"
                fontFamily={"Plus Jakarta Sans"}
              >
                Description
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        display="flex"
        width={"324px"}
        height="62px"
        marginLeft={"10%"}
        color="black"
        fontFamily={"Plus Jakarta Sans"}
        fontStyle="normal"
        fontWeight={"700"}
        fontSize="48px"
        lineHeight={"130%"}
        marginBottom="20px"
      ></Grid>
      <Grid
        item
        display={"flex"}
        direction="column"
        borderTop={1}
        padding="64px 10rem"
        sx={{ borderColor: "#E0E0E0" }}
        gap={2}
      >
        <Grid item>
          <Grid
            item
            display={"flex"}
            direction="row"
            justifyContent={"flex-start"}
            gap={1}
          >
            <Grid item color="#D14F1E" fontWeight="700" fontSize="20px">
              Order News
            </Grid>
            <Grid
              item
              height="1px"
              backgroundColor="#FF723D"
              width={"3rem"}
              marginTop="0.9rem"
            ></Grid>
            <Grid
              item
              display={"flex"}
              direction="row"
              justifyContent="center"
              gap={2}
              marginLeft={"65%"}
            >
              <Grid item>
                <Button
                  style={{
                    backgroundColor: "#EFEFEF",
                    border: "1px solid #E0E0E0",
                    borderRadius: "50px",
                    color: "black",
                  }}
                >
                  {"<"}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  style={{
                    backgroundColor: "#FF723D",
                    width: "40px",
                    borderRadius: "50px ",
                    color: "white",
                  }}
                >
                  {">"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item display={"flex"} direction="column" gap={2}>
          <Grid item display={"flex"} direction="row" gap={2}>
            <Grid item>
              <Box
                sx={{
                  width: "250px",
                  height: "215px",
                  backgroundImage: `url(${News1})`,
                  backgroundSize: "cover",
                }}
                onClick={() => navigate("/news-onClick")}
              ></Box>
            </Grid>
            <Grid item display={"flex"} direction="column">
              <Grid item fontWeight="700" fontSize="20px">
                Potret Kompaknya Panglima Saat Main Wayang Bareng Kapolri di TIM
              </Grid>
              <Grid item color="#D14F1E" fontWeight="500" fontSize="14px">
                Senin, 16 Jan 2023 11:45
              </Grid>
              <Grid item fontWeight="500" fontSize="16px">
                Jakarta - Panglima TNI Laksamana Yudo Margono dan Kapolri
                Jenderal Listyo Sigit melakoni tokoh wayang orang dalam
                pergelaran di Teater Jakarta, TIM. Mereka tampak
                kompak.PreviousNextMereka terlihat kompak dengan berdandan tokoh
                wayang orang bertajuk, " Pandawa Boyong" saat tampil di atas
                panggung teater. Acara tersebut digelar di gedung teater Taman
                Ismail Marzuki, Jakarta Pusat, Minggu (15/01).
              </Grid>
            </Grid>
          </Grid>
          <Grid item display={"flex"} direction="row" gap={2}>
            <Grid item>
              <Box
                sx={{
                  width: "250px",
                  height: "215px",
                  backgroundImage: `url(${News2})`,
                  backgroundSize: "cover",
                }}
                onClick={() => navigate("/news-onClick")}
              ></Box>
            </Grid>
            <Grid item display={"flex"} direction="column">
              <Grid item fontWeight="700" fontSize="20px">
                Potret Kompaknya Panglima Saat Main Wayang Bareng Kapolri di TIM
              </Grid>
              <Grid item color="#D14F1E" fontWeight="500" fontSize="14px">
                Jumat, 02 Des 2022 19:30
              </Grid>
              <Grid item fontWeight="500" fontSize="16px">
                Jakarta - Panglima TNI Laksamana Yudo Margono dan Kapolri
                Jenderal Listyo Sigit melakoni tokoh wayang orang dalam
                pergelaran di Teater Jakarta, TIM. Mereka tampak
                kompak.PreviousNextMereka terlihat kompak dengan berdandan tokoh
                wayang orang bertajuk, " Pandawa Boyong" saat tampil di atas
                panggung teater. Acara tersebut digelar di gedung teater Taman
                Ismail Marzuki, Jakarta Pusat, Minggu (15/01).
              </Grid>
            </Grid>
          </Grid>
          <Grid item display={"flex"} direction="row" gap={2}>
            <Grid item>
              <Box
                sx={{
                  width: "250px",
                  height: "215px",
                  backgroundImage: `url(${News3})`,
                  backgroundSize: "cover",
                }}
                onClick={() => navigate("/news-onClick")}
              ></Box>
            </Grid>
            <Grid item display={"flex"} direction="column">
              <Grid item fontWeight="700" fontSize="20px">
                Potret Kompaknya Panglima Saat Main Wayang Bareng Kapolri di TIM
              </Grid>
              <Grid item color="#D14F1E" fontWeight="500" fontSize="14px">
                Minggu, 20 Nov 2022 15:41
              </Grid>
              <Grid item fontWeight="500" fontSize="16px">
                Jakarta - Panglima TNI Laksamana Yudo Margono dan Kapolri
                Jenderal Listyo Sigit melakoni tokoh wayang orang dalam
                pergelaran di Teater Jakarta, TIM. Mereka tampak
                kompak.PreviousNextMereka terlihat kompak dengan berdandan tokoh
                wayang orang bertajuk, " Pandawa Boyong" saat tampil di atas
                panggung teater. Acara tersebut digelar di gedung teater Taman
                Ismail Marzuki, Jakarta Pusat, Minggu (15/01).
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item textAlign={"center"}>
        <Button
          style={{
            color: "#FF723D",
            fontWeight: "500",
            fontSize: "20px",
            cursor: "pointer",
            textTransform: "none",
          }}
          disableElevation
        >
          View all {">"}
        </Button>
      </Grid>
    </>
  );
};

export default Home;
