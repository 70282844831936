import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import Cover from "../assets/headercover.png";

import NavbarSticky from "./NavbarSticky";
import {
  homeContent,
  submitYourEvent,
  admission,
  gettingHere,
  venueMap,
  exhibitions,
  about,
  news,
  contact,
} from "../utils/headerContent";

const Header = ({
  children,
  title,
  menuList,
  menuTab,
  handleChangeTab,
  setMenuTab,
  isRedirect,
  handleRedirect,
  value,
  setValue,
}) => {
  const navigate = useNavigate();

  const handleChangeTabHeader = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <NavbarSticky
        navigate={navigate}
        value={value}
        setValue={setValue}
        handleChangeTabHeader={handleChangeTabHeader}
      />
      <Grid
        item
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "50vh",
          backgroundImage: `url(${Cover})`,
          // zIndex: "2000",
          backgroundPosition: "center",
          backgroundSize: "cover",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          display="flex"
          color="#FFFFFF"
          fontWeight={"700"}
          fontSize="48px"
          margin={"42px auto"}
        >
          {title}
        </Grid>

        <Grid
          item
          color="white"
          fontWeight={"600"}
          fontSize="16px"
          width="800px"
          textAlign={"center"}
          margin={"0 auto"}
        >
          {title === "Home"
            ? homeContent
            : title === "Admission"
            ? admission
            : title === "Getting Here"
            ? gettingHere
            : title === "Venue Map"
            ? venueMap
            : title === "Submit Your Event"
            ? submitYourEvent
            : title === "Exhibitions & Events"
            ? exhibitions
            : title === "About TIM"
            ? about
            : title === "news"
            ? news
            : contact}
        </Grid>
      </Grid>
    </>
  );
};

export default Header;
