import React, { useState } from "react";
import { Grid } from "@mui/material";

import ContentTable from "./contentTable";
import AddNews from "./Addnews";

const Index = () => {
  const [section, setSection] = useState("");

  return (
    <>
      {section === "addData" ? (
        <AddNews setSection={setSection} />
      ) : (
        <ContentTable setSection={setSection} />
      )}
    </>
  );
};

export default Index;
