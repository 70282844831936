import React, { useState } from "react";
import { Grid, Button, TextField, Typography, Box } from "@mui/material";
import UploadForm from "../../components/Elements/UploadForm";
import RequiredLabel from "../../components/Elements/RequiredLabel";
import AdminTextArea from "../../components/Elements/AdminTextArea";
import MainButton from "../../components/Elements/MainButton";
import Headline from "../../components/headline";
import Phone from "../../assets/phone-solid.svg";
import TabsMasterData from "../../components/content/TabsContent";
import Rute from "../../components/content/Rute";

import { Icon } from "@iconify/react";
import AdminInput from "../../components/Elements/AdminInput";

const PurchaseOrder = ({ title, setSection }) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid
        container
        direction={"column"}
        backgroundColor="white"
        padding="1.5rem"
        gap={2}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          gap={2}
        >
          <Icon icon="mdi:train-car" width="40" height="40" />
          <Typography fontSize={"32px"} fontWeight={"700"}>
            Getting Here
          </Typography>
        </Box>

        <Rute />
        <MainButton variant="contained" sx={{ width: "10.1875rem" }}>
          + Tambah Rute
        </MainButton>
      </Grid>
    </>
  );
};

export default PurchaseOrder;
