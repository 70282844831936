import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import Approval from "../CustomModal/Approval";
import MainButton from "../Elements/MainButton";

import useModal from "../../hooks/useModal";

// service
import EventServices from "../../services/EventServices";

import {
  setLoading,
  setError,
  setSuccessed,
} from "../../redux/feature/modalSlice";

const TableEventBase = ({
  onSelectItem,
  selectedItems,
  onSelectAll,
  data,
  header,
  numbering,
  isAction,
  refetch,
}) => {
  const { isShowing, toggle } = useModal();
  const [selectedId, setSelectedId] = useState();
  const [showReject, setReject] = useState(false);
  const [type, setType] = useState("");

  const dispatch = useDispatch();
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#FFECEC",
      color: "#FF723D",
      //   border: "1px solid #FF723D",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: "#FF723D",
    },
  }));

  const renderCell = (row, item) => {
    if (item?.render) {
      return item.render(row[item.key]);
    }
    return row[item.key];
  };

  const handleToggle = (id) => {
    setSelectedId(id);
    toggle();
  };

  const handleConfirmation = async (e, id, type) => {
    e.preventDefault();
    dispatch(setLoading(true));
    try {
      await EventServices.confirmationEvent(id, type);
      setReject(false);
      refetch();
      toggle();
      dispatch(setLoading(false));
      dispatch(setSuccessed(true));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setError(true));
      setReject(false);
      toggle();
      console.log(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Approval
        toggle={toggle}
        isShowing={isShowing}
        approve={(e) => {
          handleConfirmation(e, selectedId, "approve");
        }}
        reject={(e) => handleConfirmation(e, selectedId, type)}
        setType={setType}
        setReject={setReject}
        showReject={showReject}
      />
      <TableContainer component={Paper}>
        <Table aria-label="simple table" sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ position: "sticky", left: "0" }}>
                <input
                  style={{ width: "18px", height: "18px" }}
                  type={"checkbox"}
                  name="select-all"
                  id="all"
                  checked={selectedItems?.length === data?.length}
                  onChange={onSelectAll}
                />
              </StyledTableCell>
              {numbering && <StyledTableCell>No</StyledTableCell>}
              {header?.map((item) => (
                <StyledTableCell>{item.title}</StyledTableCell>
              ))}
              {isAction && (
                <StyledTableCell sx={{ width: "200px" }}>Aksi</StyledTableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.map((row, index) => (
              <TableRow key={index}>
                <TableCell
                  style={{
                    position: "sticky",
                    left: "0",
                    background: "white",
                  }}
                >
                  <input
                    style={{ width: "18px", height: "18px" }}
                    type={"checkbox"}
                    name="foo"
                    checked={selectedItems?.includes(row.id)}
                    id={row.id}
                    onChange={() => onSelectItem(row.id)}
                  />
                </TableCell>
                {numbering && <TableCell align="left">{index + 1}</TableCell>}

                {header?.map((item) => (
                  <TableCell align="left">{renderCell(row, item)}</TableCell>
                ))}
                {isAction && (
                  <TableCell align="left">
                    <MainButton
                      variant="contained"
                      sx={{ bgcolor: "primary.500", mr: "0.5rem" }}
                      onClick={() => handleToggle(row?.id)}
                    >
                      Setujui
                    </MainButton>
                    <MainButton
                      variant="outlined"
                      sx={{ border: "primary.500", color: "primary.500" }}
                      onClick={() => handleToggle(row?.id)}
                    >
                      Tolak
                    </MainButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableEventBase;
