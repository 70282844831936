import React, { useState } from "react";
import { Icon } from "@iconify/react";
import {
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from "@mui/material";

// component
import CustomModal from "../CustomModal/CustomModal";
import MainInput from "../Elements/MainInput";
import MainButton from "../Elements/MainButton";

function ChangePasswordModal({ isShowing, toggle }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <>
      <CustomModal
        isShowing={isShowing}
        toggle={toggle}
        style={{ padding: "4rem", width: { xs: "90%", sm: "36rem" } }}
      >
        <IconButton
          onClick={toggle}
          sx={{
            position: "absolute",
            color: "inherit",
            top: 10,
            right: 10,
          }}
        >
          <Icon
            icon="material-symbols:close-rounded"
            width="1.5rem"
            height="1.5rem"
          />
        </IconButton>
        <Typography
          color="netural.800"
          sx={{ fontSize: "1.5rem", fontWeight: 700 }}
        >
          Ubah Kata Sandi
        </Typography>
        <Typography fontSize="0.75rem" color="neutral.600">
          Kelola informasi profil Anda untuk mengontrol, melindungi dan <br />
          mengamankan akun
        </Typography>
        <Divider sx={{ marginY: "2rem" }} />
        <form>
          <MainInput
            label="Kata Sandi Saat Ini"
            placeholder="Masukkan Kata Sandi Saat Ini"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword((show) => !show)}>
                    {showPassword ? (
                      <Icon
                        icon="mdi:visibility-off-outline"
                        height="24"
                        width="24"
                        color="neutral.600"
                      />
                    ) : (
                      <Icon
                        icon="mdi:visibility-outline"
                        height="24"
                        width="24"
                        color="neutral.600"
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "1rem",
            }}
          >
            <Link
              href="#"
              underline="none"
              color="primary.600"
              fontSize="0.875rem"
            >
              Lupa Kata Sandi?
            </Link>
          </div>
          <MainInput
            label="Kata Sandi Yang Baru"
            placeholder="Masukkan Kata Sandi Yang Baru"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowNewPassword((show) => !show)}
                  >
                    {showNewPassword ? (
                      <Icon
                        icon="mdi:visibility-off-outline"
                        height="24"
                        width="24"
                        color="neutral.600"
                      />
                    ) : (
                      <Icon
                        icon="mdi:visibility-outline"
                        height="24"
                        width="24"
                        color="neutral.600"
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <MainInput
            label="Konfirmasi Kata Sandi"
            placeholder="Masukkan Konfirmasi Kata Sandi"
            type="password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowConfirmPassword((show) => !show)}
                  >
                    {showConfirmPassword ? (
                      <Icon
                        icon="mdi:visibility-off-outline"
                        height="24"
                        width="24"
                        color="neutral.600"
                      />
                    ) : (
                      <Icon
                        icon="mdi:visibility-outline"
                        height="24"
                        width="24"
                        color="neutral.600"
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "1rem",
            }}
          >
            <MainButton variant="contained" size="large">
              Simpan
            </MainButton>
          </div>
        </form>
      </CustomModal>
    </>
  );
}

export default ChangePasswordModal;
