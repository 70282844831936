import { Icon } from "@iconify/react";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import React from "react";

function ImagePreview({ file, onClick }) {
  return (
    <>
      <Box sx={{ position: "relative" }}>
        <IconButton
          sx={{
            position: "absolute",
            top: 5,
            right: 5,
            zIndex: 10,
            bgcolor: "primary.100",
            border: "1px solid",
            borderColor: "primary.600",
            width: "1.5rem",
            height: "1.5rem",
            padding: "0.2rem",
          }}
          onClick={onClick}
        >
          <Icon
            icon="material-symbols:close"
            height="1.25rem"
            width="1.25rem"
            color="#D14F1E"
          />
        </IconButton>
        <Avatar
          alt="profile-picture"
          src={file.url || URL.createObjectURL(file)}
          sx={{
            width: "12.5rem",
            height: "12.5rem",
            fontSize: "9.375rem",
            borderRadius: "0.25rem",
          }}
          variant="square"
        />
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            zIndex: 10,
            width: "100%",
            bgcolor: "rgba(245, 245, 245, 0.5);",
            paddingX: "1rem",
            paddingY: "0.5rem",
          }}
        >
          <Typography noWrap variant="body2">
            {file.name}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default ImagePreview;
