import React from "react";
import { Icon } from "@iconify/react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box, IconButton, Typography } from "@mui/material";

// component
import MainButton from "../Elements/MainButton";
import StatusTag from "../Elements/StatusTag";
import Feedback from "./Feedback";

function EventHeader({
  open,
  setOpen,
  title,
  status,
  isEdit,
  feedback,
  onEdit,
}) {
  const statusTag = () => {
    switch (status) {
      case "reject-plan-visit":
        return "danger";
      case "Menunggu Approval Proposal Event":
        return "danger";
      case "approve-plan-visit":
        return "success";
      case "submit-plan-visit":
        return "basic";
      default:
        return "basic";
    }
  };
  return (
    <>
      <Box
        sx={{
          paddingX: "1rem",
          paddingY: "1.188rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "1rem",
          border: "1px solid",
          borderColor: "neutral.400",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "1rem",
          }}
        >
          <Typography fontSize="1.25rem" fontWeight="700">
            {title}
          </Typography>
          <StatusTag variant={statusTag()}>
            {status === "reject-plan-visit"
              ? "Ditolak"
              : status === "approve-plan-visit"
              ? "Disetujui"
              : "Menunggu Persetujuan"}
          </StatusTag>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          {isEdit && (
            <MainButton
              variant="outlined"
              sx={{ width: "fit-content" }}
              startIcon={
                <Icon
                  icon="material-symbols:edit-outline-rounded"
                  height="1.25rem"
                  width="1.25rem"
                />
              }
              onClick={onEdit}
            >
              Ubah Data
            </MainButton>
          )}

          {open ? (
            <IconButton onClick={() => setOpen(false)}>
              <ExpandLess />
            </IconButton>
          ) : (
            <IconButton onClick={() => setOpen(true)}>
              <ExpandMore />
            </IconButton>
          )}
        </Box>
        {status === "reject-plan-visit" && <Feedback message={feedback} />}
      </Box>
    </>
  );
}

export default EventHeader;
