import React, { useState } from "react";
import Headline from "../../components/headline";
import SearchBar from "../../components/Searchbar";
import Table from "../../components/table";
import Footer from "../../components/foter";
import MainButton from "../../components/Elements/MainButton";

import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PurchaseOrder = ({ title, setSection }) => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const handleAddNews = () => {
    setSection("addData");
  };

  return (
    <>
      <Grid container backgroundColor="white" padding="1.5rem" gap={2}>
        <Grid
          item
          container
          display="flex"
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Grid
            item
            sx={{
              fontWeight: "700",
              fontSize: "20px",
            }}
          >
            List News
          </Grid>
          <Grid item display="flex" gap={2}>
            <MainButton
              children={"+ Tambah Berita"}
              variant="contained"
              onClick={handleAddNews}
            />
            {/* <Grid item>
              <Button
                style={{
                  borderColor: "#D12C24",
                  color: "white",
                  backgroundColor: "#D12C24",
                  borderRadius: "8px",
                  textTransform: "none",
                }}
                disableElevation
                variant="contained"
                onClick={() => navigate("/admin-content-tambah-berita")}
              >
                + Tambah Berita
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
        <SearchBar />

        <Table title={title} />
        <Footer />
      </Grid>
    </>
  );
};

export default PurchaseOrder;
