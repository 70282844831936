import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import MainSelect from "../Elements/MainSelect";
import SearchInput from "../Elements/SearchInput";

function UserHeader({ title, caption, isFilter }) {
  const options = [
    { title: "Menunggu Pembayaran", value: "waiting-payment" },
    { title: "Sudah Dibayar", value: "paid" },
  ];
  return (
    <>
      <Grid
        container
        display={"flex"}
        direction="row"
        sx={{ minHeight: "5.813rem", bgcolor: "background.paper" }}
      >
        <Grid
          item
          xs={12}
          sx={{
            padding: "1rem",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: "1rem",
          }}
        >
          <Box>
            <Typography fontSize={"1.5rem"} fontWeight={700}>
              {title}
            </Typography>
            {caption && (
              <Typography variant="caption" color={"neutral.600"}>
                {caption}
              </Typography>
            )}
          </Box>
          {isFilter && (
            <Box
              sx={{
                display: "flex",
                gap: { xs: "1rem", md: "1.5rem" },
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                width: {
                  xs: "100%",
                  md: "auto",
                },
              }}
            >
              <Box sx={{ width: { xs: "100%", md: "15rem" } }}>
                <MainSelect options={options} />
              </Box>
              <Box sx={{ width: { xs: "100%", md: "15rem" } }}>
                <SearchInput />
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default UserHeader;
