import React from "react";
import { Box } from "@mui/material";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { orange, pink, green } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";

const TabsMasterData = ({ handleChange, value, item }) => {
  const navigate = useNavigate();

  return (
    <>
      <Box sx={{ backgroundColor: "white" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="tertiary"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#FF723D",
              height: "0.25rem",
            },
          }}
        >
          <Tab
            label="Admin"
            sx={{
              textTransform: "none",
              color: "#BDBDBD",
            }}
          />
          <Tab
            label="Membership"
            sx={{
              textTransform: "none",
              color: "#BDBDBD",
            }}
          />
        </Tabs>
      </Box>
    </>
  );
};

export default TabsMasterData;
