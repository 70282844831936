import { Grid, Button } from "@mui/material";
import React from "react";

const Navbar = ({
  setMenuTab,
  isRedirect,
  handleRedirect,
  collapseSidebar,
  collapsed,
}) => {
  // const handleChange = (event, newValue) => {
  //   setMenuTab(newValue);
  //   isRedirect && handleRedirect(newValue);
  // };

  return (
    <>
      <Grid
        item
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "48px",
          flexWrap: "nowrap",
          alignItems: "center",
          padding: "0 0.5rem 0 0.5rem",
          overflow: "hidden",
          position: "fixed",
          backgroundColor: "#FFFFFF",
          zIndex: "1000",
          boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Grid
          item
          container
          display="flex"
          flexDirection="row"
          flexWrap={"nowrap"}
          // backgroundColor="green"
          alignItems={"center"}
          justifyContent="space-between"
          marginRight={"0.5rem"}
          gap={2}
        >
          <Grid item sx={{ marginLeft: collapsed ? "5.1rem" : "16rem" }}>
            {collapsed ? (
              <Button
                style={{ backgroundColor: "#F5F5F5" }}
                onClick={() => collapseSidebar()}
              >
                {">>"}
              </Button>
            ) : (
              <Button
                style={{ backgroundColor: "#F5F5F5" }}
                onClick={() => collapseSidebar()}
              >
                {"<<"}
              </Button>
            )}
          </Grid>
          <Grid item>
            {/* <Icon icon="ph:bell-bold" height={24} width={24} color="#666666" /> */}
            Welcome, Louise Thompson
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Navbar;
