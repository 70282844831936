import "./App.css";
import Routes from "./routes";
import ScrollToTop from "./components/ScrollToTop";
import ThemeConfig from "./theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import LoadingModal from "./components/CustomModal/LoadingModal";
import Error from "./components/CustomModal/Error";
import Success from "./components/CustomModal/Success";

// ----------------------------------------------------------------------

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: 1,
      staleTime: 5 * 1000,
    },
  },
});

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeConfig>
          <ScrollToTop />
          <Routes />
          <LoadingModal />
          <Error />
          <Success />
        </ThemeConfig>
      </QueryClientProvider>
    </>
  );
}

export default App;
