import { Icon } from "@iconify/react";
import { Box, IconButton, TextField } from "@mui/material";
import React, { useState } from "react";
import { Calendar, DateRangePicker } from "react-date-range";
import dayjs from "dayjs";

// component
import useModal from "../../hooks/useModal";
import CustomModal from "../CustomModal/CustomModal";
import MainButton from "./MainButton";

function DatePicker({
  onClick,
  placeholder,
  label,
  name,
  onChange,
  required,
  disabled,
  date,
  setDate,
  value,
  formatDate,
  setDateLocal,
}) {
  const [localValue, setLocalValue] = useState("");
  const { isShowing, toggle } = useModal();

  const handleSelect = (date) => {
    toggle();
    setLocalValue(date);
  };

  const handleSelectDateRange = () => {
    toggle();
    setLocalValue(date);
    onChange();
  };

  const handleClear = (date) => {
    toggle();
    setLocalValue(null);
  };

  const onChangeRange = (item) => {
    setDate([item.selection]);
    setDateLocal([item.selection]);
  };

  return (
    <>
      <TextField
        label={label}
        name={name}
        // onChange={onChange}
        placeholder={placeholder}
        required={required}
        value={
          value
            ? formatDate === "free"
              ? value
              : formatDate === "timestamp"
              ? dayjs(value).format("D MMMM YYYY")
              : dayjs(value).format("D MMMM YYYY")
            : localValue
            ? dayjs(localValue).format("D MMMM YYYY")
            : ""
        }
        onClick={toggle}
        InputProps={{
          endAdornment: (
            <IconButton onClick={onClick} edge="start">
              <Icon icon="mdi:calendar-range" color="#BDBDBD" />
            </IconButton>
          ),
        }}
        sx={{
          bgcolor: disabled ? "neutral.300" : "#FFFFFF",
          "& fieldset": {
            borderRadius: 0,
          },
        }}
      />

      {date ? (
        <CustomModal
          isShowing={isShowing}
          toggle={toggle}
          style={{ padding: "1rem", width: { xs: "90%", sm: "fit-content" } }}
        >
          <DateRangePicker
            editableDateInputs={true}
            onChange={(item) => onChangeRange(item)}
            ranges={date}
            months={2}
            direction="horizontal"
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            rangeColors={["#D14F1E", "#D14F1E", "#D14F1E"]}
          />
          <Box sx={{ textAlign: "right", marginRight: "1rem" }}>
            <MainButton variant="contained" onClick={handleSelectDateRange}>
              Submit
            </MainButton>
          </Box>
        </CustomModal>
      ) : (
        <CustomModal
          isShowing={isShowing}
          toggle={toggle}
          style={{ padding: "1rem", width: { xs: "90%", sm: "fit-content" } }}
        >
          <IconButton
            onClick={toggle}
            sx={{
              position: "absolute",
              color: "inherit",
              top: 10,
              right: 10,
            }}
          >
            <Icon
              icon="material-symbols:close-rounded"
              width="1.5rem"
              height="1.5rem"
            />
          </IconButton>
          <Calendar
            color={"#D12C24"}
            date={value && value}
            onChange={handleSelect}
          />
          <Box sx={{ paddingX: "1rem" }}>
            <MainButton variant="outlined" onClick={handleClear}>
              Clear
            </MainButton>
          </Box>
        </CustomModal>
      )}
    </>
  );
}

export default DatePicker;
