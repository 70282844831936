import React, { useState } from "react";
import Chart from "./Chart";

import TabsHome from "../../components/tabsHome";
import TabPanel from "../../components/Tabs";

import ProposalEvent from "./ProposalEvent";
import TicketEvent from "./TicketEvents";
import PostEvent from "./PostEvent";
import { adminHomeLabel } from "../../utils/tabsLabel";

const Index = () => {
  const [value, setValue] = useState("Proposal Event");

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const data = [
    {
      id: "1",
      nama_event: "Animakini X Ciffest 2023",
      event_organizer: " Kentang Production",
      date: "11 Sep 2023",
      location: "Aula Utama Gedung Ismail Marzuki",
      status: "pending",
    },
    {
      id: "2",
      nama_event: "Urang Minang Baralek Gadang",
      event_organizer: " Cimol Production",
      date: "8 Sep 2023",
      location: "Aula Utama Gedung Ismail Marzuki",
      status: "pending",
    },
    {
      id: "3",
      nama_event: "Urang Minang Baralek Gadang",
      event_organizer: " Otak-Otak Production",
      date: "1 Sep 2023",
      location: "Aula Utama Gedung Ismail Marzuki",
      status: "pending",
    },
  ];
  return (
    <>
      {/* dont use Grid in index? table scrollable */}
      <Chart />
      {/* dont wrap this with grid container */}
      <TabsHome
        value={value}
        handleChange={handleChangeTabs}
        label={adminHomeLabel}
      />
      {value === "Proposal Event" ? (
        <ProposalEvent data={data} />
      ) : value === "Post Event" ? (
        <PostEvent data={data} />
      ) : (
        <TicketEvent />
      )}
    </>
  );
};

export default Index;
