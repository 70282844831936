import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Breadcrumbs,
  ButtonBase,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

// asset
import Back from "../assets/Back.png";
import Mandiri from "../assets/Mandiri.png";

// component
import { Icon } from "@iconify/react";
import DetailTicket from "../components/Checkout/DetailTicket";
import MainButton from "../components/Elements/MainButton";
import MainInput from "../components/Elements/MainInput";
import MainNavbar from "../components/MainNavbar";
import UserNotice from "../components/UserNotice";

const DetailCheckout = () => {
  const navigate = useNavigate();

  const breadcrumbs = [
    <Typography
      key="1"
      color="text.primary"
      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
    >
      1. Booking
    </Typography>,
    <Typography
      key="2"
      color="text.primary"
      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
    >
      2. Pesan
    </Typography>,
    <Typography
      key="3"
      color="text.primary"
      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
    >
      3. Bayar
    </Typography>,
    <Typography
      key="4"
      color="inherit"
      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
    >
      4. Selesai
    </Typography>,
  ];

  return (
    <Box bgcolor="background.paper" minHeight="100vh" paddingBottom="4rem">
      <MainNavbar />
      <UserNotice />
      <Grid container>
        <Grid
          item
          xs={11}
          sm={10}
          md={9}
          sx={{
            margin: "auto",
            marginTop: "4rem",
          }}
        >
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "5rem",
            }}
          >
            {breadcrumbs}
          </Breadcrumbs>
          <ButtonBase
            sx={{ display: "flex", gap: "0.875rem " }}
            onClick={() => navigate(-1)}
          >
            <div>
              <img src={Back} alt="" />
            </div>
            <Typography fontSize="2rem" fontWeight="700">
              Metode Pembayaran
            </Typography>
          </ButtonBase>
          <Divider
            sx={{
              width: "100%",
              bgcolor: "neutral.400",
              marginTop: "1rem",
              marginBottom: "2rem",
            }}
            orientation="horizontal"
          />
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <Box
                padding="1rem"
                boxSizing="border-box"
                width="100%"
                border="1px solid"
                borderColor="neutral.400"
                marginTop="1rem"
              >
                <Box
                  sx={{
                    bgcolor: "neutral.200",
                    border: "1px solid",
                    borderColor: "neutral.400",
                    padding: "1rem",
                    width: "100%",
                    marginBottom: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "0.5rem",
                    }}
                  >
                    <Typography variant="body1">Harga</Typography>
                    <Typography variant="body1">IDR 500.000</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">Kode Unik</Typography>
                    <Typography variant="body1">- IDR 437</Typography>
                  </Box>
                  <Divider
                    sx={{
                      width: "100%",
                      bgcolor: "neutral.400",
                      marginTop: "1rem",
                      marginBottom: "0.5rem",
                    }}
                    orientation="horizontal"
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography fontSize="1.25rem">Total Pembayaran</Typography>
                    <Typography fontSize="1.25rem" color="primary.600">
                      IDR 499.563
                    </Typography>
                  </Box>
                </Box>
                <MainInput
                  label="Kode Promo"
                  placeholder="Masukan Kode Promo"
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "1rem",
                    bgcolor: "neutral.200",
                  }}
                >
                  <Typography variant="body2" fontWeight="700">
                    Informasi Penting
                  </Typography>
                  <img src={Mandiri} alt="" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingX: "1rem",
                    paddingY: "0.5rem",
                    bgcolor: "neutral.200",
                    gap: "0.625rem",
                    bgcolor: "primary.100",
                    minHeight: "3.5rem",
                  }}
                >
                  <Icon
                    icon="material-symbols:info-rounded"
                    height="1.25rem"
                    width="1.25rem"
                    color="#D14F1E"
                  />
                  <Typography variant="caption">
                    Metode pembayaran ini tidak mendukung SKN/LLG/Kliring/SKBBNI
                  </Typography>
                </Box>
                <Typography fontSize="1rem" marginTop="1rem">
                  Dengan mengklik <strong>"Bayar"</strong>, Anda menyetujui
                  aturan, batasan, dan{" "}
                  <Link
                    color="primary.600"
                    fontWeight="700"
                    sx={{
                      textDecoration: "none",
                      "&:hover": { cursor: "pointer" },
                    }}
                  >
                    Syarat & Ketentuan
                  </Link>{" "}
                  dan{" "}
                  <Link
                    color="primary.600"
                    fontWeight="700"
                    sx={{
                      textDecoration: "none",
                      "&:hover": { cursor: "pointer" },
                    }}
                  >
                    Kebijakan Privasi
                  </Link>{" "}
                  Taman Ismail Marzuki.
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <MainButton
                    variant="contained"
                    sx={{ marginTop: "1.5rem" }}
                    onClick={() => navigate("/pembayaran-bank")}
                  >
                    Bayar
                  </MainButton>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <DetailTicket total="499.563" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailCheckout;
