import React, { useState } from "react";
import MainButton from "../../components/Elements/MainButton";
import { Grid, Typography, InputAdornment } from "@mui/material";
import { Icon } from "@iconify/react";
import MainInput from "../../components/Elements/MainInput";
import IconButton from "@mui/material/IconButton";

const NewPassword = () => {
  const [values, setValues] = useState({
    showPassword: false,
    showPasswordVerification: false,
  });
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleClickShowVerificationPassword = () => {
    setValues({
      ...values,
      showPasswordVerification: !values.showPasswordVerification,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <Grid container item direction="column" width="27.5rem" margin={"auto"}>
        <Typography fontWeight="700" fontSize="32px">
          Buat Password Baru
        </Typography>
        <Typography fontWeight="400" fontSize="16px" marginBottom={"2rem"}>
          Kata sandi baru Anda harus berbeda dari kata sandi sebelumnya
        </Typography>

        <MainInput
          type={values.showPassword ? "text" : "password"}
          label="Kata Sandi Yang Baru"
          placeholder={"Masukkan Kata Sandi Yang Baru"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? (
                    <Icon
                      icon="material-symbols:visibility-outline"
                      color="#828282"
                      width="20"
                      height="20"
                    />
                  ) : (
                    <Icon
                      icon="ic:outline-visibility-off"
                      color="#828282"
                      width="20"
                      height="20"
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <MainInput
          type={values.showPasswordVerification ? "text" : "password"}
          label="Konfirmasi Kata Sandi"
          placeholder={"Masukkan Konfirmasi Kata Sandi"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowVerificationPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPasswordVerification ? (
                    <Icon
                      icon="material-symbols:visibility-outline"
                      color="#828282"
                      width="20"
                      height="20"
                    />
                  ) : (
                    <Icon
                      icon="ic:outline-visibility-off"
                      color="#828282"
                      width="20"
                      height="20"
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <MainButton variant={"contained"}>Ubah Password</MainButton>
      </Grid>
    </>
  );
};

export default NewPassword;
