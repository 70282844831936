import React, { useState } from "react";
import { Grid, Button, TextField, Typography } from "@mui/material";
import Headline from "../../components/headline";
import RequiredLabel from "../../components/Elements/RequiredLabel";
import AdminInput from "../../components/Elements/AdminInput";
import AdminTextArea from "../../components/Elements/AdminTextArea";
import MainButton from "../../components/Elements/MainButton";
import Phone from "../../assets/phone-solid.svg";
import TabsMasterData from "../../components/content/TabsContent";

import { Icon } from "@iconify/react";

const PurchaseOrder = ({ title, setSection }) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const titleHeadline = (
    <Grid container alignItems={"center"} gap={2}>
      <Icon icon="fluent:call-16-filled" rotate={1} width="40" height="40" />
      <Typography fontSize={"32px"} fontWeight={"700"}>
        Contact
      </Typography>
    </Grid>
  );

  const editHeadline = (
    <Grid container alignItems={"center"} gap={1}>
      <Icon
        icon="material-symbols:edit-outline"
        color="white"
        width="20"
        height="20"
      />
      <Typography>Edit</Typography>
    </Grid>
  );
  return (
    <>
      <Grid container backgroundColor="white" padding="1.5rem" gap={2}>
        <Headline title={titleHeadline} content={editHeadline} />

        <Grid item xs={4}>
          <Grid item marginTop={"20px"}>
            <RequiredLabel>Phone</RequiredLabel>
          </Grid>
          <Grid item marginTop={"20px"}>
            <AdminInput
              required
              name="phone"
              size="small"
              rows={2}
              fullWidth
              placeholder="Nomer Telepon"
            />
          </Grid>
          <Grid item marginTop={"20px"}>
            <RequiredLabel>Address</RequiredLabel>
          </Grid>
          <Grid item marginTop={"20px"}>
            <AdminTextArea
              required
              name="phone"
              size="small"
              rows={3}
              multiline={true}
              fullWidth
              placeholder="Alamat Lokasi"
            />
          </Grid>
          <Grid item marginTop={"20px"}>
            <RequiredLabel>Email</RequiredLabel>
          </Grid>
          <Grid item marginTop={"20px"}>
            <AdminInput
              required
              name="phone"
              size="small"
              rows={2}
              fullWidth
              placeholder="Email"
            />
          </Grid>
        </Grid>
        <Grid container alignItems={"center"} gap={2}>
          <Icon icon="ph:globe" width="40" height="40" />
          <Typography fontSize={"32px"} fontWeight={"700"}>
            Sosmed
          </Typography>
        </Grid>
        {/* <Grid item marginLeft="0px" width={"36px"} height={"22px"} fontFamily={"Plus Jakarta Sans"} fontSize={"14px"} fontStyle={"normal"} fontWeight={"500"} lineHeight={"160%"} >Instagram</Grid> */}
        <Grid item xs={4}>
          <Grid item marginTop={"20px"}>
            <RequiredLabel>Instagram</RequiredLabel>
          </Grid>
          <Grid item marginTop={"20px"}>
            <AdminInput
              required
              name="phone"
              size="small"
              rows={2}
              fullWidth
              placeholder="Link Instagram"
            />
          </Grid>
          <Grid item marginTop={"20px"}>
            <RequiredLabel>Facebook</RequiredLabel>
          </Grid>
          <Grid item marginTop={"20px"}>
            <AdminInput
              required
              name="phone"
              size="small"
              rows={2}
              fullWidth
              placeholder="Link Facebook"
            />
          </Grid>
          <Grid item marginTop={"20px"}>
            <RequiredLabel>Tiktok</RequiredLabel>
          </Grid>
          <Grid item marginTop={"20px"}>
            <AdminInput
              required
              name="phone"
              size="small"
              rows={2}
              fullWidth
              placeholder="Link Tiktok"
            />
          </Grid>
          <Grid item marginTop={"20px"}>
            <RequiredLabel>Twiter</RequiredLabel>
          </Grid>
          <Grid item marginTop={"20px"}>
            <AdminInput
              required
              name="phone"
              size="small"
              rows={2}
              fullWidth
              placeholder="Link Twiter"
            />
          </Grid>
          <Grid item marginTop={"20px"}>
            <MainButton variant="contained">Submit</MainButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PurchaseOrder;
