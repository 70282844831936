import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";

// component
import UserHeader from "../../components/UserDashboard/UserHeader";
import UserProfileForm from "../../components/UserProfile/UserProfileForm";
import ChangePasswordModal from "../../components/UserProfile/ChangePasswordModal";
import useModal from "../../hooks/useModal";

// redux
import { setUserData } from "../../redux/feature/userSlice";

function UserEOProfile() {
  const { isShowing, toggle } = useModal();
  const dispatch = useDispatch();

  const userData = {
    name: "Kreasi Event Nusantara",
    type: "Event Oganizer",
    phone: "083221664825",
    email: "kreasien@gmail.com",
    initial: "K",
    role: "eo",
  };

  useEffect(() => {
    dispatch(setUserData(userData));
  }, []);

  return (
    <>
      <Grid container display={"flex"} direction="column" sx={{ gap: "1rem" }}>
        <UserHeader
          title="Profil Akun"
          caption="Kelola informasi profil Anda untuk mengontrol, melindungi dan
            mengamankan akun"
        />
        <UserProfileForm onResetPassword={toggle} />
        <ChangePasswordModal isShowing={isShowing} toggle={toggle} />
      </Grid>
    </>
  );
}

export default UserEOProfile;
