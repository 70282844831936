import React from "react";
import { Grid, Box, Button, TextField } from "@mui/material";

import News1 from "../assets/News1.JPG";
import News2 from "../assets/News2.JPG";
import News3 from "../assets/News3.JPG";
import Iklan from "../assets/Iklan.png";
import Event2 from "../assets/rename1.png";

import Event1 from "../assets/pemainTim.png";
import Instagram from "../assets/instagram.png";
import Fb from "../assets/fb.png";
import Tk from "../assets/tk.png";
import Twitter from "../assets/twitter.png";
import { useNavigate } from "react-router-dom";



const Home = () => {
    const navigate = useNavigate();

    return (
        <>

            <Grid
                item
                display={"flex"}
                direction="row"
                justifyContent={"flex-end"}
                gap={2}
            >
                <Grid
                    container
                    display="flex"
                    marginLeft={"18%"}
                    color="black"
                    fontFamily={'Plus Jakarta Sans'}
                    fontStyle="normal"
                    marginTop={"20px"}

                >
                    <Grid item display={"flex"} direction="row" gap={2}>
                        <Grid item fontWeight="500" fontSize="16px">
                            Home
                        </Grid>
                        <Grid item fontWeight="500" fontSize="16px">
                            {">"}
                        </Grid>
                        <Grid item fontWeight="500" fontSize="16px" width={"44px"} height="26px" color={"black"} 
                                onClick={() => navigate("/news")}
                        
                        >
                            News
                        </Grid>
                        <Grid item fontWeight="500" fontSize="16px">
                            {">"}
                        </Grid>
                        <Grid item fontWeight="500" fontSize="16px" width={"448px"} height="26px" color={"#828282"}>
                            "Lewat Djam Malam" hadir dengan adaptasi pentas teater
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                display="flex"
                width={"805px"}
                height="124px"
                marginLeft={"18%"}
                marginTop="20px"
                color="black"
                fontFamily={'Plus Jakarta Sans'}
                fontStyle="normal"
                fontWeight={"700"}
                fontSize="48px"
                lineHeight={"130%"}
                marginBottom="20px"
            >
                "Lewat Djam Malam" hadir dengan
                adaptasi pentas teater
            </Grid>
            <Grid item fontWeight="700" fontSize="20px" width={"293px"} height="30px" color={"#828282"} marginLeft={"18%"}>
                TMI FOTO/Admin Admin - TMI
            </Grid>
            <Grid item fontWeight="600" fontSize="16px" width={"293px"} height="30px" color={"#828282"} marginLeft={"18%"} marginTop="20px">
                Selasa, 29 November 2022 13:08 WIB
            </Grid>
            <Grid item marginLeft={"18%"} marginTop="20px">
                <Box
                    sx={{
                        width: "660px",
                        height: "407px",
                        backgroundImage: `url(${Event1})`,
                        backgroundSize: "cover",
                    }}
                ></Box>

                <Grid item fontWeight="600" fontSize="12px" width={"690px"} height="19px" color={"#828282"} >
                    Para pemain dan kru produksi pertunjukan teater silang media “ Setelah Lewat Djam Malam”. (TMI/HO)
                </Grid>
            </Grid>
            {/* <Grid item > */}
            <Grid container display={"flex"}
                flexDirection="column"
                alignItems={"flex-start"}
                padding="0px"
                gap={"16px"}
                width="1040px"
                height={"1584px"}>
                <Grid item>
                    <Grid item fontWeight="600" fontSize="16px" width={"650px"} height="81px" color={"#000000"} marginLeft={"18%"} marginTop="20px" >
                        Jakarta (ANTARA) - Salah satu film legendaris Indonesia, “Lewat Djam Malam” (1954), akan kembali
                        hadir dengan format baru berupa pentas teater silang-media film dan seni pertunjukan dalam satu
                        panggung, dengan judul “Setelah Lewat Djam Malam”.

                    </Grid>
                    <Grid item fontWeight="600" fontSize="16px" width={"650px"} height="81px" color={"#000000"} marginLeft={"18%"} marginTop="20px" >
                        “Menyilangkan film dengan teater relevan dengan fenomena media yg semakin mempengaruhi
                        kehidupan dunia hari ini dan film klasik ‘Lewat Djam Malam’ yang dipilih untuk direspon ulang tidak
                        hanya menghasilkan karya yang baru lagi tapi ada pelajaran-pelajaran tentang sejarah dan
                        kemanusiaan serta kebangsaan yang dapat dipetik,” kata Direktur Perfilman, Musik dan Media,
                        Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi Ahmad Mahendra, dalam keterangan
                        resmi, Selasa.

                    </Grid>
                    <Grid item fontWeight="600" fontSize="16px" width={"650px"} height="81px" color={"#000000"} marginLeft={"18%"} marginTop="100px" >
                        Di bawah arahan sutradara teater Yudi Ahmad Tajudin, serta tim kreatif yang terdiri dari aktor dan
                        seniman-seniman pertunjukan ternama di Indonesia ini dipersembahkan produser Yulia Evina Bhara
                        dari KawanKawan Media.

                    </Grid>
                    <Grid item fontWeight="600" fontSize="16px" width={"650px"} height="81px" color={"#000000"} marginLeft={"18%"} marginTop="10px" >
                        “Kerangka estetika pertunjukan ini bukan sekedar ‘alih wahana’, yang memindah ekspresi seni dari
                        satu wahana ke wahana lain, tetapi ‘silang-media’, yang mempertemukan dan mendialogkan
                        wahana, atau media, yang berbeda-beda sebagai satu kesatuan ekspresi,” kata Yudi.

                    </Grid>
                    <Grid item fontWeight="600" fontSize="16px" width={"650px"} height="81px" color={"#000000"} marginLeft={"18%"} marginTop="20px" >
                        Pertunjukan “Setelah Lewat Djam Malam”, yang lakon panggungnya ditulis oleh Permata Adinda dan
                        Shohifur Ridho’i, akan dimainkan oleh aktor-aktor ternama Indonesia seperti Reza Rahadian, Dira
                        Sugandi, Kelly Tandiono, Sal Priadi, Lukman Sardi, dan Josh Marcy, serta para penari Dansity.
                    </Grid>
                    <Grid item fontWeight="600" fontSize="16px" width={"650px"} height="81px" color={"#000000"} marginLeft={"18%"} marginTop="20px" >
                        “Setelah Lewat Djam Malam” diproduksi oleh Garasi Performance Institute dan KawanKawan Media,
                        didukung oleh Direktorat Jenderal Kebudayaan Kemendikbudristek, serta IM3 Collabonation.

                    </Grid>
                    <Grid item fontWeight="600" fontSize="16px" width={"650px"} height="81px" color={"#000000"} marginLeft={"18%"}  >
                        “Sejalan dengan semangat kolaborasi yang selalu dibawa IM3 melalui Collabonation sebagai wadah
                        bagi anak muda Indonesia menghasilkan karya-karya kolaboratif, IM3 sangat senang dan antusias
                        mendukung karya seni pertunjukan ‘Setelah Lewat Djam Malam’,” kata SVP-Head of Brand
                        Management & Strategy Indosat Ooredoo Hutchison Fahroni Arifin.
                    </Grid>
                    <Grid container marginTop={"60px"} >
                        <Grid item fontWeight="700" fontSize="20px" width={"210px"} marginLeft={"18%"} height="30px" color={"#828282"} >
                            Tinggalkan Komentar
                        </Grid>
                        <Grid item marginLeft={"300px"}>
                            <img src={Instagram} alt="" />
                        </Grid>
                        <Grid item marginLeft={"30px"}>
                            <img src={Fb} alt="" />
                        </Grid>
                        <Grid item marginLeft={"32px"}>
                            <img src={Tk} alt="" />
                        </Grid>
                        <Grid item marginLeft={"35px"}>
                            <img src={Twitter} alt="" />
                        </Grid>
                        <Grid item
                            width={"752.07px"}
                            height="0px"
                            marginLeft={"18%"} marginTop="20px"
                            border={"2px solid #D14F1E"} />
                    </Grid>

                    <Grid item xs={8.8} marginLeft="18%" marginTop={"10px"}>
                        <TextField
                            required
                            name="phone" size="small"
                            rows={4}
                            multiline
                            fullWidth
                            placeholder="Add a comment..."
                        />
                    </Grid>

                    <Grid item
                        boxSizing={"border-box"}
                        position="absolute"
                        width={"766px"}
                        height="48px"
                        marginLeft={"14%"}
                        backgroundColor="#F5F5F5"
                        border={"1px solid rgba(45, 57, 76, 0.1)"}

                    >
                        <Button

                            variant="outlined"
                            style={{
                                borderRadius: "0",
                                backgroundColor: '#D14F1E',
                                padding: "14px 18px",
                                gap: "8px",
                                width: "69px",
                                height: "29px",
                                borderRadius: "5px",
                                marginLeft: "580px",
                                marginTop: "5px"
                            }}

                        >
                            <Grid item color='white'>Submit</Grid>
                        </Button>
                    </Grid>

                    <Grid item fontWeight="600" fontSize="12px" width={"77px"} height="19px" color={"#D14F1E"} marginLeft={"18%"} marginTop="80px">
                        Budi Sucipto
                    </Grid>
                    <Grid item fontWeight="400" fontSize="12px" width={"497px"} height="38px" color={"black"} marginLeft={"18%"} marginTop="10px">
                        Donec sed sed feugiat sit. Enim, urna euismod magna enim. Sit cras eget id sagittis consequat at.
                    </Grid>
                    <Grid container>
                        <Grid item fontWeight="400" fontSize="12px" width={"24px"} height="19px" color={"grey"} marginLeft={"18%"} marginTop="10px">
                            Like
                        </Grid>
                        <Grid item fontWeight="400" fontSize="12px" width={"24px"} height="19px" color={"grey"} marginLeft={"1%"} marginTop="10px">
                            Reply
                        </Grid>
                        <Grid item fontWeight="400" fontSize="12px" width={"24px"} height="19px" color={"grey"} marginLeft={"2%"} marginTop="10px">
                            23h
                        </Grid>
                    </Grid>
                    <Grid item fontWeight="600" fontSize="12px" width={"94px"} height="19px" color={"#D14F1E"} marginLeft={"18%"} marginTop="0px">
                        Andra Setiawan
                    </Grid>
                    <Grid item fontWeight="400" fontSize="12px" width={"515px"} height="38px" color={"black"} marginLeft={"18%"} marginTop="10px">
                        Ultricies ultricies interdum dolor sodales. Vitae feugiat vitae vitae quis id consectetur. Aenean urna, lectus enim suscipit eget. Tristique bibendum nibh enim dui.
                    </Grid>
                    <Grid container>
                        <Grid item fontWeight="400" fontSize="12px" width={"24px"} height="19px" color={"grey"} marginLeft={"18%"} marginTop="10px">
                            Like
                        </Grid>
                        <Grid item fontWeight="400" fontSize="12px" width={"24px"} height="19px" color={"grey"} marginLeft={"1%"} marginTop="10px">
                            Reply
                        </Grid>
                        <Grid item fontWeight="400" fontSize="12px" width={"24px"} height="19px" color={"grey"} marginLeft={"2%"} marginTop="10px">
                            23h
                        </Grid>

                    </Grid>
                    <Grid item fontWeight="600" fontSize="12px" width={"94px"} height="19px" color={"#D14F1E"} marginLeft={"20%"} marginTop="10px">
                        Ahmad Jaelani
                    </Grid>
                    <Grid item fontWeight="400" fontSize="12px" width={"515px"} height="38px" color={"black"} marginLeft={"20%"} marginTop="10px">
                        Lorem fringilla pretium magna purus orci faucibus morbi.
                    </Grid>
                    <Grid container>
                        <Grid item fontWeight="400" fontSize="12px" width={"24px"} height="19px" color={"grey"} marginLeft={"20%"} marginTop="10px">
                            Like
                        </Grid>
                        <Grid item fontWeight="400" fontSize="12px" width={"24px"} height="19px" color={"grey"} marginLeft={"1%"} marginTop="10px">
                            Reply
                        </Grid>
                        <Grid item fontWeight="400" fontSize="12px" width={"24px"} height="19px" color={"grey"} marginLeft={"2%"} marginTop="10px">
                            23h
                        </Grid>

                    </Grid>
                    <Grid item
                        width={"752.07px"}
                        height="0px"
                        marginLeft={"18%"} marginTop="20px"
                        border={"2px solid #D14F1E"} />

                    <Grid item>
                        <Button

                            variant="outlined"
                            style={{
                                borderRadius: "0",
                                backgroundColor: '#D14F1E',
                                width: "434px",
                                height: "48px",
                                marginLeft: "18%",
                                marginTop: "30px"
                            }}

                        >
                            <Grid item color='white'>Load 13 more comments</Grid>
                        </Button>
                    </Grid>
                </Grid>

                <Grid item>
                    <img src={Iklan} alt="" />
                </Grid>

                <Grid item
                    width={"238px"}
                    height="0px"
                    marginLeft={"1%"} marginTop="20px"
                    border={"2px solid #D14F1E"} />
                <Grid item fontWeight="700" fontSize="24px" width={"203px"} height="36px" color={"#D14F1E"} marginTop="10px">
                    Tag Terpopuler #
                </Grid>
                <Grid item fontWeight="700" fontSize="16px" width={"90px"} height="130px" color={"black"} >

                    #Iko uais

                    #dewa 19

                </Grid>
                <Grid item
                    width={"238px"}
                    height="0px"
                    marginLeft={"1%"}
                    border={"2px solid #D14F1E"} />
                <Grid item fontWeight="700" fontSize="24px" width={"203px"} height="36px" color={"#D14F1E"} marginTop="10px">
                    Event #
                </Grid>
                <Grid item>
                    <img src={Event2} alt="" />
                </Grid>
                <Grid item fontWeight="700" fontSize="16px" width={"195px"} height="26px" color={"black"} >

                    Animakini X Ciffest 2023

                </Grid>
                <Grid item fontWeight="400" fontSize="10px" width={"118px"} height="14px" color={"#D14F1E"} >

                    20-22 September 2018

                </Grid>
                <Grid
                    item
                    display={"flex"}
                    direction="row"
                    justifyContent="center"
                    gap={2}
                    marginLeft={"3%"}
                >
                    <Grid item>
                        <Button
                            style={{
                                backgroundColor: "#EFEFEF",
                                border: "1px solid #E0E0E0",
                                borderRadius: "50px",
                                color: "black",
                            }}
                        >
                            {"<"}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            style={{
                                backgroundColor: "#FF723D",
                                width: "10px",
                                borderRadius: "50px ",
                                color: "white",
                            }}
                        >
                            {">"}
                        </Button>
                    </Grid>
                 
                </Grid>
                <Grid item
                        width={"238px"}
                        height="0px"
                        marginLeft={"1%"}
                        border={"2px solid #D14F1E"} />

            </Grid>



            {/* </Grid> */}


        </>
    );
};

export default Home;
