export const filterLabel = [
  { key: "Filter by time", children: ["Now Showing", "Incoming", "Past"] },
  {
    key: "Filter by type",
    children: [
      "Exhibitions",
      "Tours",
      "Talks",
      "Social",
      "Hands-On Activities",
      "Perfomances",
    ],
  },
  {
    key: "Filter by audience",
    children: [
      "Adults",
      "Kids & Families",
      "Members",
      "Educations",
      "Scholars",
    ],
  },
  {
    key: "Filter by location",
    children: [
      "Main Building",
      "Online",
      "Rodin Museum",
      "Sculpture Garden",
      "OffSite",
    ],
  },
];
