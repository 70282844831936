import React from "react";
import { Grid, Typography } from "@mui/material";
import RequiredLabel from "../Elements/RequiredLabel";
import UploadForm from "../Elements/UploadForm";
import AdminTextArea from "../Elements/AdminTextArea";
import MainButton from "../Elements/MainButton";
import AdminInput from "../Elements/AdminInput";
import { Icon } from "@iconify/react";

const Rute = () => {
  return (
    <>
      <Grid
        container
        display={"flex"}
        flexDirection={"row"}
        border={"1px solid #FF723D"}
        sx={{ width: "43.6875rem", padding: "20px" }}
        gap={2}
      >
        <Grid item xs={5.7}>
          <RequiredLabel>Image</RequiredLabel>
          <UploadForm />
        </Grid>
        <Grid item xs={6}>
          <RequiredLabel>Judul</RequiredLabel>
          <AdminInput placeholder={"Cara TIM dengan KRL"} />
          <RequiredLabel>Deskripsi</RequiredLabel>
          <AdminTextArea
            multiline={true}
            rows={4}
            placeholder={"Cara TIM dengan KRL"}
          />

          <MainButton variant={"contained"} sx={{ marginRight: "2rem" }}>
            <Grid container alignItems={"center"} gap={1}>
              <Icon
                icon="material-symbols:edit-outline"
                color="white"
                width="20"
                height="20"
              />
              <Typography>Edit</Typography>
            </Grid>
          </MainButton>
          <MainButton variant="outlined">
            <Grid container alignItems={"center"} gap={1}>
              <Icon
                icon="ic:outline-delete"
                color="#ff723d"
                width="20"
                height="20"
              />
              <Typography>Hapus</Typography>
            </Grid>
          </MainButton>
        </Grid>
      </Grid>
    </>
  );
};

export default Rute;
